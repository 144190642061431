import * as types from './types'

export const getCalendars = (payload: any) => ({
  type: types.GET_CALENDARS,
  payload,
})

export const getCalendar = (payload: any) => ({
  type: types.GET_CALENDAR,
  payload,
})

export const addCalendar = (payload: any) => ({
  type: types.ADD_CALENDARS,
  payload,
})

export const editCalendar = (payload: any) => ({
  type: types.EDIT_CALENDARS,
  payload,
})

export const deleteCalendars = (payload: any) => ({
  type: types.DELETE_CALENDARS,
  payload,
})

export const activeCalendar = (payload: any) => ({
  type: types.ACTIVE_CALENDAR,
  payload,
})

export const inactiveCalendar = (payload: any) => ({
  type: types.INACTIVE_CALENDAR,
  payload,
})
