import React from 'react'
import MyWorkHeader from './mywork-header'
import { PageLayoutUpdated } from 'app/views/components/layout/page-layout-updated'
import { MyWorkDataPage } from './mywork_page'


export function MyWorkLandingPage(tab: { tab: string }) {
  return (
    <PageLayoutUpdated>
      <MyWorkHeader />
      <MyWorkDataPage tab={tab.tab} />
    </PageLayoutUpdated>
  )
}
