import VerticalStepper from 'app/views/components/stepper-component'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import UpdatedDealsModal, {
  ChildDealRef,
} from 'app/views/pages/deals-management/updated-deal-form'
import { useRef, useState } from 'react'
import DealManageFees, {
  ChildDealManageFeesRef,
} from 'app/views/components/Fees/dealFees'
import DealPricingGridComponent, { ChildDealManagePricingGridRef } from 'app/views/components/deal-pricing-grid/deal-pricing-grid'

type Props = {
  onClose: () => void
  deal?: any
  dealAmendment?: any
  step?: number
  amendment?: string | null
}

interface CustomStepContentProps {
  onNext: () => void
  onPrevious: () => void
}

const DealsFormWithoutStepper: React.FC<Props> = ({
  deal,
  amendment,
  dealAmendment,
  onClose,
  step,
}) => {
  const [disabledSteps, setDisabledSteps] = useState<any>()
  const [upd_deal, setDeal] = useState(deal)
  const [upd_amendment, setAmendment] = useState(dealAmendment)

  const DealContent: React.FC<CustomStepContentProps> = ({ onNext }) => {
    const childRef = useRef<ChildDealRef>(null)
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

    const handleSaveDealAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveDealAndNext = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onNext()
      }
    }

    return (
      <div className="create-new">
        <UpdatedDealsModal
          deal={upd_deal}
          amendment={amendment}
          dealAmendment={upd_amendment}
          ref={childRef}
          setSaveButtonDisabled={setIsSaveButtonDisabled}
          setDeal={setDeal}
          setAmendment={setAmendment}
          setDisabledSteps={setDisabledSteps}
        />{' '}
        <div
          className="btn-group"
          style={{
            marginTop: 50,
            textAlign: 'right',
            display: 'flex',
            gap: '10px',
          }}
        >
          <PrimaryButton
            onClick={() => handleSaveDealAndExit()}
            className="form-button"
            w="33%"
            disabled={isSaveButtonDisabled}
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveDealAndNext()}
            className="form-button"
            w="33%"
            disabled={isSaveButtonDisabled}
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="33%"
          >
            Exit
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const FeesContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildDealManageFeesRef>(null)

    const handleSaveDealAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveDealAndNext = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onNext()
      }
    }


    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <DealManageFees
          deal={upd_deal}
          amendment={amendment}
          dealAmendment={upd_amendment}
          setAmendment={setAmendment}
          setDeal={setDeal}
          ref={childRef}
        />{' '}
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveDealAndExit()}
            className="form-button"
            w="33.3%"
          >
            Save and Exit
          </PrimaryButton>
          {(upd_deal?.isPricingGrid || upd_amendment?.isPricingGrid) && (
            <PrimaryButton
              onClick={handleSaveDealAndNext}
              className="form-button"
              w="33%"
            >
              Save and Proceed
            </PrimaryButton>
          )}
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="33.3%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="33.3%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const PricingGridContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildDealManagePricingGridRef>(null)

    const handleSaveDealAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <DealPricingGridComponent
          deal={upd_deal}
          amendment={amendment}
          dealAmendment={upd_amendment}
          setAmendment={setAmendment}
          setDeal={setDeal}
          ref={childRef}
          setDisabledSteps={disabledSteps}
        />{' '}
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveDealAndExit()}
            className="form-button"
            w="33.3%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="33.3%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="33.3%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const steps = [
    {
      label: '1. Deal',
      content: (
        <DealContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'i. Fees',
      content: (
        <FeesContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'ii. Pricing Grid',
      content: (
        <PricingGridContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
  ]

  return (
    <>
      {!upd_deal || Object.keys(upd_deal).length === 0 ? (
        //create
        < VerticalStepper
          steps={steps}
          disabledSteps={[1, 2, 3, 4, 5]}
          step={step}
        />
      ) : (
        // edit
        <VerticalStepper
          steps={steps}
          disabledSteps={disabledSteps ? disabledSteps : (upd_deal.isPricingGrid || upd_amendment?.isPricingGrid) ? [] : [2]}
          step={step}
        />
      )}
    </>
  )
}

export default DealsFormWithoutStepper