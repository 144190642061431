/* eslint-disable  */
import AmortizationSchedule, {
    ChildAmortizationScheduleRef,
} from 'app/views/components/amortization-scheduler/amortization-schedule'
import VerticalStepper from 'app/views/components/stepper-component'
import UpdatedFacilitiesModal, {
    ChildFacilityRef,
} from 'app/views/pages/deals-management/updated-facilities-form'
import { useEffect, useRef, useState } from 'react'
import ManageFees, { ChildManageFeesRef } from 'app/views/components/Fees/fees'
import ManageLenders, {
    ChildManageLendersRef,
} from 'app/views/components/lenders/manage-lenders'
import ManageIro, {
    ChildManageIroRef,
} from 'app/views/components/interest-rate-options/manage-iro'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import Sublimits, {
    ChildSublimitsRef,
} from 'app/views/components/sublimits/sublimits'
import { useDispatch } from 'react-redux'
import { FacilityParams } from 'app/models/facility-params'
import { resetFacilityLendersPositions } from 'app/state/ducks/lenders-positions/actions'
import { resetFacilityFees } from 'app/state/ducks/fees/actions'
import { resetFacilitySchedulerAmortization } from 'app/state/ducks/amortization/actions'
import ManagePikScheduler, { ChildPikSchedulerRef } from 'app/views/components/pik-scheduler/manage-pik-scheduler'
import ManageFacilityPricingGrid from 'app/views/components/facility-pricing-grid/manage-facility-pricing-grid'

type Props = {
    deal: any
    facility: any
    amendment?: string | null
    onClose: any
    step?: number
}

interface CustomStepContentProps {
    onNext: () => void
    onPrevious: () => void
}

export const showAmortizationSchedule = (facility: FacilityParams) => {
    if (
        facility?.facilityType === 'TL' ||
        facility?.facilityType === 'TLA' ||
        facility?.facilityType === 'TLB' ||
        facility?.facilityType === 'TLC' ||
        facility?.facilityType === 'DDTL'
    ) {
        return true
    } else {
        return false
    }
}

export const UpdatedFacilitiesScreen: React.FC<Props> = ({
    deal,
    facility,
    onClose,
    step,
    amendment,
}) => {
    const [updated_facility, setFacility] = useState(facility)

    const dispatch = useDispatch()


    const showPikScheduler = (f: any) => {
        return f?.pikOption === 'PIK_Scheduled'
    }

    const stepsToDisable = (facility: FacilityParams) => {
        const initialDisabledSteps =
            facility?.isSublimit === false ||
                facility?.facilityType === 'TL' ||
                facility?.facilityType === 'TLA' ||
                facility?.facilityType === 'TLB' ||
                facility?.facilityType === 'TLC'
                ? [2]
                : []
        const amotizationEnabled = showAmortizationSchedule(facility) ? [] : [6]
        const pikOption = facility?.pikOption === 'PIK_Scheduled' ? [] : [7]
        const facilityPricingGrid = deal?.isPricingGrid ? [] : [5]
        const stepsToDisable = initialDisabledSteps.concat(amotizationEnabled, pikOption, facilityPricingGrid)
        return stepsToDisable
    }

    const [disabledSteps, setDisabledSteps] = useState<any>(
        stepsToDisable(updated_facility)
    )

    const FacilityContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildFacilityRef>(null)
        const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndNext = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onNext()
            }
        }

        useEffect(() => {
            if (!facility) {
                dispatch(resetFacilityLendersPositions())
                dispatch(resetFacilityFees())
                dispatch(resetFacilitySchedulerAmortization())

            }
        }, [facility])

        return (
            <div className="create-new">
                <UpdatedFacilitiesModal
                    deal={deal}
                    facility={updated_facility}
                    amendment={amendment}
                    ref={childRef}
                    setSaveButtonDisabled={setIsSaveButtonDisabled}
                    setDisabledSteps={setDisabledSteps}
                    setFacility={setFacility}
                    dealStepper={false}
                />
                <div
                    className="btn-group"
                    style={{
                        marginTop: 50,
                        textAlign: 'right',
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="33%"
                        disabled={isSaveButtonDisabled}
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndNext()}
                        className="form-button"
                        w="33%"
                        disabled={isSaveButtonDisabled}
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="33%"
                    >
                        Exit
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const LenderAllocationContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildManageLendersRef>(null)

        const handleNext = () => {
            if (typeof onNext === 'function') {
                onNext()
                if (updated_facility?.isSublimit === false) {
                    onNext()
                }
            }
        }

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndProceed = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                handleNext()
            }
        }

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
            }
        }

        return (
            <div className="create-new">
                <ManageLenders
                    facility={updated_facility}
                    setFacility={setFacility}
                    ref={childRef}
                    amendment={amendment}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndProceed()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w="25%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="25%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const SublimitsContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildSublimitsRef>(null)

        const handleNext = () => {
            if (typeof onNext === 'function') {
                onNext()
            }
        }

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndProceed = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                handleNext()
            }
        }

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
            }
        }

        return (
            <div className="create-new">
                <Sublimits
                    facility={updated_facility}
                    amendment={amendment}
                    setFacility={setFacility}
                    ref={childRef}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndProceed()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w="25%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="25%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const IroContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildManageIroRef>(null)

        const handleNext = () => {
            if (typeof onNext === 'function') {
                onNext()
            }
        }

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndProceed = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                handleNext()
            }
        }

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
                if (updated_facility?.isSublimit === false) {
                    onPrevious()
                }
            }
        }

        return (
            <div className="create-new">
                <ManageIro
                    facility={updated_facility}
                    amendment={amendment}
                    setFacility={setFacility}
                    ref={childRef}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndProceed()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w="25%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="25%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const FeesContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildManageFeesRef>(null)

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndNext = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onNext()
                if (!deal?.isPricingGrid) {
                    onNext()
                    if (!showAmortizationSchedule(updated_facility)) {
                        onNext()
                    }
                }
            }
        }

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
            }

        }

        return (
            <div className="create-new">
                <ManageFees
                    facility={updated_facility}
                    amendment={amendment}
                    setFacility={setFacility}
                    ref={childRef}
                />

                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w={showAmortizationSchedule(updated_facility) || showPikScheduler(updated_facility) || deal?.isPricingGrid ? '25%' : '33%'}
                    >
                        Save and Exit
                    </PrimaryButton>
                    {showAmortizationSchedule(updated_facility) || showPikScheduler(updated_facility) || deal?.isPricingGrid ? (
                        <PrimaryButton
                            onClick={() => handleSaveFacilityAndNext()}
                            className="form-button"
                            w="25%"
                        >
                            Save and Proceed
                        </PrimaryButton>
                    ) : null}
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w={showAmortizationSchedule(updated_facility) || showPikScheduler(updated_facility) || deal?.isPricingGrid ? '25%' : '33%'}
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w={showAmortizationSchedule(updated_facility) || showPikScheduler(updated_facility) || deal?.isPricingGrid ? '25%' : '33%'}
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const FacilityPricingGrid: React.FC<CustomStepContentProps> = ({
        onPrevious,
        onNext,
    }) => {
        const childRef = useRef<ChildPikSchedulerRef>(null)

        const handleNext = () => {
            if (typeof onNext === 'function') {
                onNext()
                if (!showAmortizationSchedule(updated_facility)) {
                    onNext()
                }
            }
        }


        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndProceed = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                handleNext()
            }
        }


        const handlePrevious = () => {
            if (typeof onPrevious === 'function') {
                onPrevious()
            }
        }

        return (
            <div className="create-new">
                <ManageFacilityPricingGrid
                    facility={updated_facility}
                    setFacility={setFacility}
                    ref={childRef}
                    amendment={amendment}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="33%"
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndProceed()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w="33%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="33%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const AmortizationScheduleContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildAmortizationScheduleRef>(null)

        const handleNext = () => {
            if (typeof onNext === 'function') {
                onNext()
            }
        }

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }
        const handleSaveFacilityAndProceed = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                handleNext()
            }
        }

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
                if (!deal?.isPricingGrid) {
                    onPrevious()
                }
            }
        }

        return (
            <div className="create-new">
                <AmortizationSchedule
                    deal={deal}
                    amendment={amendment}
                    facility={updated_facility}
                    setFacility={setFacility}
                    ref={childRef}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w={showPikScheduler(updated_facility) ? "25%" : "33%"}
                    >
                        Save and Exit
                    </PrimaryButton>
                    {showPikScheduler(updated_facility) ? (
                        <PrimaryButton
                            onClick={() => handleSaveFacilityAndProceed()}
                            className="form-button"
                            w="25%"
                        >
                            Save and Proceed
                        </PrimaryButton>
                    ) : null}
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w={showPikScheduler(updated_facility) ? "25%" : "33%"}
                    >
                        Previous
                    </PrimaryButton>

                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w={showPikScheduler(updated_facility) ? "25%" : "33%"}
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const PikContent: React.FC<CustomStepContentProps> = ({
        onPrevious,
    }) => {
        const childRef = useRef<ChildPikSchedulerRef>(null)


        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handlePrevious = () => {
            if (typeof onPrevious === 'function') {
                onPrevious()
                if (!showAmortizationSchedule(updated_facility)) {
                    onPrevious()
                    if (!deal?.isPricingGrid) {
                        onPrevious()
                    }
                }
            }
        }

        return (
            <div className="create-new">
                <ManagePikScheduler
                    facility={updated_facility}
                    setFacility={setFacility}
                    ref={childRef}
                    amendment={amendment}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="33%"
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w="33%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="33%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const steps = [
        {
            label: '1. Facility',
            content: (
                <FacilityContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'i. Lender Allocation',
            content: (
                <LenderAllocationContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'ii. Sublimits',
            content: (
                <SublimitsContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'iii. Interest Rate Options',
            content: (
                <IroContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'iv. Fees',
            content: (
                <FeesContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'v. Pricing Grid',
            content: (
                <FacilityPricingGrid
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'vi. Amortization Schedule',
            content: (
                <AmortizationScheduleContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'vii. PIK Scheduler',
            content: (
                <PikContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
    ]

    return (
        <>
            {!updated_facility || Object.keys(updated_facility).length === 0 ? (
                //create
                <VerticalStepper
                    steps={steps}
                    disabledSteps={[1, 2, 3, 4, 5, 6, 7]}
                    step={step}
                />
            ) : (
                // edit
                <VerticalStepper
                    steps={steps}
                    disabledSteps={disabledSteps}
                    step={step}
                />
            )}
        </>
    )
}
