import { DealParams } from 'app/models/deal-params'

export const getDealAmendments = (state: {
  dealAmendments: { dealAmendments: DealParams[] }
}) => state.dealAmendments.dealAmendments

export const getDealAmendment = (state: {
  dealAmendments: { dealAmendment: DealParams }
}) => {
  return state.dealAmendments.dealAmendment
}
