import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Group, Stack } from '@mantine/core';
import { useListState } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { ServicingCols } from './servicing-columnDefs'
import { ServicingParams } from 'app/models/servicing-params'
import { loadAllLoanRollovers } from 'app/state/ducks/loan-rollovers/thunks'
import { loadAllLoanIncreases } from 'app/state/ducks/loan-increases/thunks'
import { getLoanRollovers } from 'app/state/ducks/loan-rollovers/selectors'
import { getLoanIncreases } from 'app/state/ducks/loan-increases/selectors'
import { getLoanRateSettings } from 'app/state/ducks/loan-rate-settings/selectors'
import { loadAllLoans } from 'app/state/ducks/loans/thunks'
import { getAllLoans } from 'app/state/ducks/loans/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadAllLoanRateSettings } from 'app/state/ducks/loan-rate-settings/thunks'
import { loadAllPrincipalPayments } from 'app/state/ducks/principal-payment/thunks'
import { getPrincipalPayments } from 'app/state/ducks/principal-payment/selectors'
import {
  checkLoanRateSettings,
  fetchAmortizationScheduleFacility,
  fetchFeePayments,
  fetchFees,
  fetchMaturingLoan,
  fetchNewBorrowings,
  fetchPiks,
  fetchPrincipals,
  fetchRateSettings,
  fetchRollovers,
  fetchIncreases,
  handleServicingRowClicked,
  fetchConversions,
  fetchMaturingFacilities,
  fetchMaturingDeals,
} from './servicing-utils'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './servicing-kpi-data'
import { getPiks } from 'app/state/ducks/payment-in-kind/selectors'
import { loadPaymentInKinds } from 'app/state/ducks/payment-in-kind/thunks'
import { getFeePayments } from 'app/state/ducks/fee-payment/selectors'
import { loadFeePayments } from 'app/state/ducks/fee-payment/thunks'
import { loadAllFees } from 'app/state/ducks/fees/thunks'
import { getAllFees } from 'app/state/ducks/fees/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import SegmentControl from 'app/views/components/segments/segment-control'
import { lockedTabs, tabs } from './servicing-segment-data'
import { getLoanConversions } from 'app/state/ducks/loan-conversions/selectors';
import { loadAllLoanConversions } from 'app/state/ducks/loan-conversions/thunks';
import { getDeals } from 'app/state/ducks/deals/selectors';
import { loadAllDeals } from 'app/state/ducks/deals/thunks';

interface props {
  tab?: string | null | undefined
}

export function ServicingPageData({ tab }: props) {
  const [td, TdHandler] = useListState<ServicingParams>()
  const [servicingData, servicingDataHandler] =
    useListState<ServicingParams>(td)
  const [rolloverServicing, rolloverServicingHandler] =
    useListState<ServicingParams>()
  const [increaseServicing, increaseServicingHandler] =
    useListState<ServicingParams>()
  const [conversionServicing, conversionServicingHandler] =
    useListState<ServicingParams>()
  const [maturingLoanServicing, maturingLoanServicingHandler] =
    useListState<ServicingParams>()
  const [maturingDealServicing, maturingDealServicingHandler] =
    useListState<ServicingParams>()
  const [maturingFacilityServicing, maturingFacilityServicingHandler] =
    useListState<ServicingParams>()
  const [newBorrowingServicing, newBorrowingServicingHandler] =
    useListState<ServicingParams>()
  const [rateSettingServicing, rateSettingServicingHandler] =
    useListState<ServicingParams>()
  const [principalServicing, principalServicingHandler] =
    useListState<ServicingParams>()
  const [pikServicing, pikServicingHandler] =
    useListState<ServicingParams>()
  const [loanWithoutRatesServicing, loanWithoutRatesServicingHandler] =
    useListState<ServicingParams>()
  const [amortizationFacilitiesServicing, amortizationFacilitiesServicingHandler] =
    useListState<ServicingParams>()
  const [feePaymentServicing, feePaymentHandler] =
    useListState<ServicingParams>()
  const [feeFacilityServicing, setFeeFacilityHandler] = useListState<ServicingParams>()
  const loanRollovers = useSelector(getLoanRollovers)
  const loanIncreases = useSelector(getLoanIncreases)
  const loanRateSettings = useSelector(getLoanRateSettings)
  const allLoans = useSelector(getAllLoans)
  const allFacilities = useSelector(getFacilities)
  const allDeals = useSelector(getDeals)
  const allEntities = useSelector(getEntities)
  const allPrincipals = useSelector(getPrincipalPayments)
  const allPiks = useSelector(getPiks)
  const allFeePayments = useSelector(getFeePayments)
  const allFees = useSelector(getAllFees)
  const allConversion = useSelector(getLoanConversions)
  const businessDate = useSelector(getBusinessDate)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [filteredServicingData, setFilteredServicingData] = useState<ServicingParams[]>(servicingData)
  const kpiData = getKPIData(servicingData, setFilteredServicingData)

  useEffect(() => {
    dispatch(loadAllLoanRollovers())
    dispatch(loadAllLoanIncreases())
    dispatch(loadAllLoanRateSettings())
    dispatch(loadAllLoans())
    dispatch(loadAllFacilities())
    dispatch(loadAllDeals())
    dispatch(loadEntities())
    dispatch(loadAllPrincipalPayments())
    dispatch(loadIndexRateOptions())
    dispatch(loadPaymentInKinds())
    dispatch(loadFeePayments())
    dispatch(loadAllFees())
    dispatch(loadAllLoanConversions())
  }, [])

  // Set principal servicing data
  useEffect(() => {
    if (!allPrincipals || !allFacilities || !allLoans || !allEntities) {
      return
    }
    const principals = fetchPrincipals(
      allPrincipals,
      allLoans,
      allEntities,
      allFacilities
    )
    if (principals !== principalServicing) {
      principalServicingHandler.setState(principals)
    }
  }, [allPrincipals, allFacilities, allLoans, allEntities])

  // Set pik servicing data
  useEffect(() => {
    if (!allPiks || !allFacilities || !allLoans || !allEntities) {
      return
    }
    const piks = fetchPiks(
      allPiks,
      allLoans,
      allEntities,
      allFacilities
    )
    if (piks !== pikServicing) {
      pikServicingHandler.setState(piks)
    }
  }, [allPiks, allFacilities, allLoans, allEntities])

  // Set new Borrowing servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities) {
      return
    }
    const newBorrowings = fetchNewBorrowings(
      allLoans,
      allEntities,
      allFacilities
    )
    if (newBorrowings !== newBorrowingServicing) {
      newBorrowingServicingHandler.setState(newBorrowings)
    }
  }, [allFacilities, allLoans, allEntities])

  // Set maturing Loans and rollovers servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !loanRollovers) {
      return
    }
    const rollovers = fetchRollovers(
      loanRollovers,
      allLoans,
      allEntities,
      allFacilities
    )
    if (rollovers !== rolloverServicing) {
      rolloverServicingHandler.setState(rollovers)
    }
    const maturingLoans = fetchMaturingLoan(
      loanRollovers,
      allLoans,
      allEntities,
      allFacilities,
      businessDate,
    )
    if (maturingLoans !== maturingLoanServicing) {
      maturingLoanServicingHandler.setState(maturingLoans)
    }
  }, [allFacilities, allLoans, allEntities, loanRollovers])


  // Set maturing Facilities
  useEffect(() => {
    if (!allFacilities || !allEntities) {
      return
    }
    const maturingFacilities = fetchMaturingFacilities(
      allEntities,
      allFacilities,
      businessDate,
    )
    if (maturingFacilities !== maturingFacilityServicing) {
      maturingFacilityServicingHandler.setState(maturingFacilities)
    }

  }, [allFacilities, allEntities])


  // Set maturing Deals
  useEffect(() => {
    if (!allDeals || !allEntities) {
      return
    }
    const maturingDeals = fetchMaturingDeals(
      allDeals,
      allEntities,
      businessDate,
    )
    if (maturingDeals !== maturingDealServicing) {
      maturingDealServicingHandler.setState(maturingDeals)
    }

  }, [allDeals, allEntities])

  // Set loan increases servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !loanIncreases) {
      return
    }
    const increases = fetchIncreases(
      loanIncreases,
      allLoans,
      allEntities,
      allFacilities
    )

    if (increases !== increaseServicing) {
      increaseServicingHandler.setState(increases)
    }
  }, [allFacilities, allLoans, allEntities, loanIncreases])


  // Set loan conversion servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !allConversion) {
      return
    }
    const conversions = fetchConversions(
      allConversion,
      allLoans,
      allEntities,
      allFacilities
    )

    if (conversions !== conversionServicing) {
      conversionServicingHandler.setState(conversions)
    }

  }, [allFacilities, allLoans, allEntities, allConversion])

  // Set rate settings servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !loanRateSettings) {
      return
    }
    const rateSettings = fetchRateSettings(
      loanRateSettings,
      allLoans,
      allEntities,
      allFacilities
    )
    if (rateSettings !== rateSettingServicing) {
      rateSettingServicingHandler.setState(rateSettings)
    }

    const loanWithoutRates = checkLoanRateSettings(
      loanRateSettings,
      allLoans,
      allEntities,
      allFacilities
    )
    if (loanWithoutRates !== loanWithoutRatesServicing) {
      loanWithoutRatesServicingHandler.setState(loanWithoutRates)
    }
  }, [allFacilities, allLoans, allEntities, loanRateSettings])

  // Set amortization facilities
  useEffect(() => {
    if (!allFacilities || !allEntities) {
      return
    }
    amortizationFacilitiesServicingHandler.setState(fetchAmortizationScheduleFacility(allFacilities, allEntities, businessDate))
  }, [allFacilities, allEntities])

  // Set fee payment servicing data
  useEffect(() => {
    if (!allFeePayments || !allFacilities || !allEntities) {
      return
    }
    const feePayments = fetchFeePayments(
      allFeePayments,
      allEntities,
      allFacilities
    )
    if (feePayments !== feePaymentServicing) {
      feePaymentHandler.setState(feePayments)
    }
  }, [allFeePayments, allFacilities, allEntities])

  // Set fees servicing data
  useEffect(() => {
    if (!allFees || !allFacilities || !allEntities) {
      return
    }
    fetchFees(allFees, allEntities, allFacilities, businessDate)
      .then((fees: React.SetStateAction<ServicingParams[]>) => {
        if (fees !== feeFacilityServicing) {
          setFeeFacilityHandler.setState(fees)
        }
      })
  }, [allFees, allFacilities, allEntities])

  // Set servicing data
  useEffect(() => {
    const result = [
      ...rolloverServicing,
      ...increaseServicing,
      ...maturingLoanServicing,
      ...newBorrowingServicing,
      ...rateSettingServicing,
      ...principalServicing,
      ...loanWithoutRatesServicing,
      ...amortizationFacilitiesServicing,
      ...pikServicing,
      ...feePaymentServicing,
      ...feeFacilityServicing,
      ...conversionServicing,
      ...maturingDealServicing,
      ...maturingFacilityServicing
    ].sort(
      (a, b) =>
        new Date(a.serviceTime).getTime() - new Date(b.serviceTime).getTime()
    )
    TdHandler.setState(tab ? result.filter(data => data.scheduled === tab) : result)
    setFilteredServicingData(tab ? result.filter(data => data.scheduled === tab) : result)
    servicingDataHandler.setState(tab ? result.filter(data => data.scheduled === tab) : result)
  }, [
    rolloverServicing,
    increaseServicing,
    maturingLoanServicing,
    newBorrowingServicing,
    rateSettingServicing,
    principalServicing,
    loanWithoutRatesServicing,
    amortizationFacilitiesServicing,
    pikServicing,
    feePaymentServicing,
    feeFacilityServicing,
    conversionServicing,
    maturingDealServicing,
    maturingFacilityServicing
  ])

  useEffect(() => {
    setFilteredServicingData(tab ? servicingData.filter((data: { scheduled: string; }) => data.scheduled === tab) : servicingData)
  }, [servicingData])

  const currentTab = () => {
    if (tab === 'unscheduled') {
      return tabs.unscheduled
    } else if (tab === 'scheduled') {
      return tabs.scheduled
    } else {
      return tabs.all
    }
  }

  const activeTabs = {
    tab: currentTab,
    locked: '',
  }

  return (
    <>
      <Stack>
        <KPI_Section
          kpiData={kpiData}
        />
      </Stack>

      <Group noWrap position="right">
        <SegmentControl tabs={tabs} lockedTabs={lockedTabs} activeTabs={activeTabs} />
      </Group>
      <TableColumnClickExclusion
        tableName='Servicing Table'
        csvExportEnabled={true}
        columnDefs={ServicingCols}
        data={filteredServicingData.sort((a, b) => {
          const dateA = new Date(a.effectiveDate).getTime();
          const dateB = new Date(b.effectiveDate).getTime();
          return dateA - dateB;
        })}
        setRow={(e: ServicingParams, cellName: string) => {
          if (cellName != 'id') {
            handleServicingRowClicked(e, navigate, allLoans, allFacilities)
          }
        }}
      />
    </>
  )
}
