import React, { useState } from 'react'
import { tabs } from './mywork-util/mywork-segment-data'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import { Group, Stack } from '@mantine/core'
import { ToDoModal } from './todo/todo-modal'
import { useSelector } from 'react-redux'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import CustomDatePicker from 'app/views/components/date-picker-updated/date-picker-logic'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import { ToDoPage } from './todo-page'
import { PaymentsPage } from './payments-page'
import { UnscheduledPage } from './unscheduled-page'
import { ScheduledPage } from './scheduled-page'

interface props {
  tab: string
}

//This keeps the date set when navigating between pages
let setDate: Date;

export function MyWorkDataPage({ tab }: props) {
  const [open, setOpen] = useState(false)
  const businessDate = useSelector(getBusinessDate)
  const [dateFilter, setDateFilter] = useState(setDate ?? businessDate)

  const currentTab = () => {
    if (tab === 'unscheduled') {
      return tabs.unscheduled
    } else if (tab === 'scheduled') {
      return tabs.scheduled
    } else if (tab === 'payments') {
      return tabs.payments
    } else {
      return tabs.todo
    }
  }
  const activeTabs = {
    tab: currentTab(),
    locked: '',
  }
  return (
    <>
      <Stack w="100%">
        {/* this group is for segment control, gap, and calendar & Activity */}
        <Group w="100%" position="apart" className='tabsGapsActivity'>
          <Group position="right">
            <SegmentControlUpdated activeTabs={activeTabs} tabs={tabs} lockedTabs={{}} />
          </Group>
          <Group noWrap>
            <CustomDatePicker
              w="175px"
              label={''}
              date={dateFilter}
              setDate={(value: any) => {
                setDateFilter(value)
                setDate = value
              }}
              holidayCalendars={[]}
            />
            <PrimaryInput className='activityButton' onClick={() => setOpen(true)}>Create To Do</PrimaryInput>
          </Group>
        </Group>
        {
          tab === 'todo' ? <ToDoPage date={dateFilter} /> : null
        }
        {
          tab === 'payments' ? <PaymentsPage date={dateFilter} /> : null
        }
        {
          tab === 'unscheduled' ? <UnscheduledPage date={dateFilter} /> : null
        }
        {
          tab === 'scheduled' ? <ScheduledPage date={dateFilter} /> : null
        }
      </Stack>
      <ToDoModal open={open} setOpen={setOpen} />
    </>
  )
}
