import {
    Anchor,
    Breadcrumbs,
    Group,
    Stack,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity } from 'app/state/ducks/entities/selectors'
import { loadOneEntity } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import EntityHeader from './entity-header'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import EntityActions from '../entity-actions'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import ContactsPage from './contacts-page'
import WireInstructionsPage from './wire-instructions-page'
import PositionsPage from './positions-page'
import EntityPage from './entity-page'


interface props {
    tab: string
}

export function EntityDrillPage({ tab }: props) {
    const { entityId, status } = useParams()

    const tabs = {
        entityBase: { route: `/entitymanagement/entity/${entityId}/${status}`, label: 'ENTITY' },
        entityContacts: { route: `/entitymanagement/contacts/${entityId}/${status}`, label: 'CONTACTS' },
        entityWI: { route: `/entitymanagement/wireinstructions/${entityId}/${status}`, label: 'WIRE INSTRUCTIONS' },
        entityPositions: { route: `/entitymanagement/positions/${entityId}/${status}`, label: 'POSITIONS' },
    }

    const currentTab = () => {
        if (tab === 'positions') {
            return tabs.entityPositions
        } else if (tab === 'contacts') {
            return tabs.entityContacts
        } else if (tab === 'wireinstructions') {
            return tabs.entityWI
        } else {
            return tabs.entityBase
        }
    }

    const activeTabs = {
        tab: currentTab(),
        locked: '',
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const entity = useSelector(getEntity)

    const items = [
        { title: 'Entity Management', href: '/entitymanagement' },
        { title: entity?.entityName },
    ].map((item, index) => (
        <Anchor
            onClick={item.href ? () => navigate(item.href) : () => null}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))

    useEffect(() => {
        if (entityId) {
            dispatch(loadOneEntity(entityId, status ?? 'Inactive'))
            dispatch(loadNoteByContract(entityId, ContractType.Entities))
        }
    }, [entityId])

    return (
        <Stack w="100%">
            <Breadcrumbs
                separator={<IonIcon name="chevron-forward-outline" />}
                mt="xs"
            >
                {items}
            </Breadcrumbs>
            <EntityHeader entity={entity} />
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated activeTabs={activeTabs} tabs={tabs} lockedTabs={{}} />
                </Group>
                <Group noWrap>
                    <EntityActions row={entity} primaryInput={true} extended withRedirection />
                </Group>
            </Group>
            {
                tab === 'entity' ? <EntityPage /> : null
            }
            {
                tab === 'contacts' ? <ContactsPage /> : null
            }
            {
                tab === 'wireinstructions' ? <WireInstructionsPage /> : null
            }
            {
                tab === 'positions' ? <PositionsPage /> : null
            }
        </Stack>
    )
}