import { ToDoParams } from "app/models/todo-params"

export const getTodos = (state: {
  todos: { todos: ToDoParams[] }
}) => {
  return state.todos.todos
}

export const getTodo = (state: { todos: { todo: ToDoParams } }) => {
  return state.todos.todo
}
