import { Accordion, Flex } from '@mantine/core';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Bold from '@tiptap/extension-bold';
import PrimaryButton from '../buttons/PrimaryButton';
import { ContractType } from 'app/models/common-types';
import { editNoteText, saveNote } from 'app/state/ducks/notes/thunks';
import { getNoteForType } from 'app/state/ducks/notes/selectors';
import Placeholder from '@tiptap/extension-placeholder';
import { ErrorNotification, SuccessNotification } from '../notifications/notification';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  contractId: string;
  contractAdmin: string;
  type: ContractType;
  fullSize?: boolean;
  form?: any;
  setNoteId?: (noteId: string) => void;
}

const content = ``;

export function NotesAccordion({
  type,
  contractId,
  contractAdmin,
  fullSize,
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const note = useSelector(getNoteForType(type));
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Bold.configure({
        HTMLAttributes: {
          class: 'notes-bold',
        },
      }),
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({
        placeholder: `Enter Notes Here`,
      }),
    ],
    content: note?.text ?? content,
  }, [note]);

  const handleSaveClick = async () => {
    let response: any;
    if (note) {
      response = await dispatch(editNoteText(note.id ?? '', editor?.getHTML() || ''));
    } else if (editor?.getHTML() !== '') {
      const noteSave = {
        contractId: contractId
          ? {
            admin: contractAdmin,
            id: contractId,
          }
          : null,
        text: editor?.getHTML() || '',
        contractType: type,
      };

      response = await dispatch(saveNote(noteSave));
    }

    if (response && response.success) {
      SuccessNotification({
        title: 'Note successfully updated',
        message: 'You have successfully updated note.',
      });
    } else if (response && !response.success) {
      ErrorNotification({
        title: 'Note not updated',
        message: response.payload,
      });
    } else {
      ErrorNotification({
        title: 'Note not updated',
        message: 'Note was not updated. Please try again.',
      });
    }
  };

  return (
    <Accordion.Item w="100%" h={fullSize ? '100%' : 'auto'} value="notes" className="accordionFull">
      <Accordion.Control p="lg" sx={{
        fontWeight: 600, // Set explicitly here
      }}>
        <div className='accordionFullTitle'>Notes</div>
      </Accordion.Control>
      <Accordion.Panel sx={{ height: '300px' }}>
        <RichTextEditor editor={editor ?? null}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Code />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content h='200px' />
        </RichTextEditor>
        <Flex justify="flex-end">
          <PrimaryButton style={{ marginTop: '10px' }} onClick={handleSaveClick}>
            Save
          </PrimaryButton>
        </Flex>
      </Accordion.Panel>
    </Accordion.Item>
  );
}