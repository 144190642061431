import { KpiProps } from 'app/views/components/KPI/KPI';
import IonIcon from '@reacticons/ionicons';
// import { useKPIFilters } from 'app/views/components/KPI/KPI-filters';

export const getKPIData = (
  data: any,
  // setData?: any// React.Dispatch<React.SetStateAction<any[]>> | undefined
): KpiProps[] => {
  //setData is only here to match the other data files until this one is updated to actually setData
  // setData
  if (!data) return [];

  // const filterFunctions: Record<string, (item: any) => boolean> = {
  // Add appropriate filter functions here once data structure is known
  // 'SomeFilter': (item: any) => item.someProperty === 'SomeValue',
  // };

  // const { applyFilter, appliedFilters } = useKPIFilters({ data, filterFunctions, setData });

  return [
    {
      title: 'Saved Reports',
      amount: 6,
      color: 0,
      activeFilter: false, // appliedFilters.has('Saved Reports'),
      event: () => console.log('Saved Reports'), // Replace with actual setData once implemented
    },
    {
      title: 'Scheduled Reports',
      amount: 3,
      color: 1,
      activeFilter: false, // appliedFilters.has('Scheduled
      event: () => console.log('Scheduled Reports'), // Replace with actual setData once implemented
    },
    {
      title: 'Regulatory Reporting',
      amount: 2,
      color: 2,
      activeFilter: false, // appliedFilters.has('Regulatory Reporting'),
      event: () => console.log('Regulatory Reporting'), // Replace with actual setData once implemented
    },
    {
      title: 'Report Builder',
      amount: <IonIcon name="construct" />,
      color: 3,
      activeFilter: false, // appliedFilters.has('Report Builder'),
      event: () => console.log('Report Builder'), // Replace with actual setData once implemented
    },
    {
      title: 'Report History',
      amount: <IonIcon name="construct" />,
      color: 4,
      activeFilter: false, // appliedFilters.has('Report History'),
      event: () => console.log('Report History'), // Replace with actual setData once implemented
    },
  ];
};
