import { AssignmentParams } from 'app/models/assignment-params'
import {
  submitForApproval,
  rejectSubmittedAssignment,
  approveSubmittedAssignment,
  deleteDraftAssignment,
  loadAllAssignments,
} from 'app/state/ducks/assignments/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitAssignment(
  assignment: AssignmentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(assignment))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Assignment has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload ?? 'Assignment could not be Submitted',
    })
  }
  dispatch(loadAllAssignments())
  return
}

export async function rejectAssignment(
  assignment: AssignmentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSubmittedAssignment(assignment))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Assignment has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload ?? 'Assignment could not be Rejected',
    })
  }
  dispatch(loadAllAssignments())
  return
}

export async function approveAssignment(
  assignment: AssignmentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(approveSubmittedAssignment(assignment))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Assignment has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload ?? 'Assignment could not be Approved',
    })
  }
  dispatch(loadAllAssignments())
  return
}

export async function deleteAssignment(
  assignment: AssignmentParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteDraftAssignment([assignment.id]))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Assignment has been Deleted',
    })
  }
  dispatch(loadAllAssignments())
  return
}
