import * as types from './types'

export const getWireInstructions = (payload: any) => ({
  type: types.GET_WIRE_INSTRUCTIONS,
  payload,
})

export const getWireInstruction = (payload: any) => ({
  type: types.GET_WIRE_INSTRUCTION,
  payload,
})

export const addWireInstruction = (payload: any) => ({
  type: types.ADD_WIRE_INSTRUCTIONS,
  payload,
})

export const editWireInstruction = (payload: any) => ({
  type: types.EDIT_WIRE_INSTRUCTIONS,
  payload,
})

export const deleteWireInstructions = (payload: any) => ({
  type: types.DELETE_WIRE_INSTRUCTIONS,
  payload,
})

export const activeWireInstruction = (payload: any) => ({
  type: types.ACTIVE_WIRE_INSTRUCTION,
  payload,
})

export const inactiveWireInstruction = (payload: any) => ({
  type: types.INACTIVE_WIRE_INSTRUCTION,
  payload,
})
