import React, { useEffect } from 'react'
import { Stack } from '@mantine/core'
import { MyWorkSummary } from './mywork_summary'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { useListState } from '@mantine/hooks'
import { getTodos } from 'app/state/ducks/todos/selectors'
import { loadTodos } from 'app/state/ducks/todos/thunks'
import { ToDoParams } from 'app/models/todo-params'
import { useDispatch, useSelector } from 'react-redux'
import { todoFakeTable } from './mywork-util/mywork-fake-data'
import { colsTodo } from './todo/todo-columnDefs'

interface props {
  date: Date
}

export function ToDoPage({ date }: props) {
  const dispatch = useDispatch()

  const todos: ToDoParams[] = useSelector(getTodos) || []
  const [todoTable, setTodoTable] = useListState<ToDoParams>([])

  const formattedTodos = (todos: ToDoParams[]) => {
    const sortedTodos = todos.sort((a, b) => {
      const timeDueA = new Date(a.timeDue).getTime()
      const timeDueB = new Date(b.timeDue).getTime()
      return timeDueA - timeDueB
    })
    const statusUpdated = sortedTodos.map((todo: ToDoParams) => {
      if ((new Date(todo.timeDue) < new Date(date)) && todo.status !== 'Completed') {
        todo.overdue = true
      } else {
        todo.overdue = false
      }
      return todo
    })
    return statusUpdated
  }

  useEffect(() => {
    dispatch(loadTodos())
  }, [])

  useEffect(() => {
    const updatedTodos = formattedTodos(todos.concat(todoFakeTable))
    setTodoTable.setState(updatedTodos)
  }, [todos, date])

  const percentageComplete = Math.round((todoTable.filter((a: ToDoParams) => {
    return a.status.toLocaleLowerCase() === 'completed'
  }).length / todoTable.length) * 100).toString();

  const deadLine = new Date(todoTable[0]?.timeDue)?.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }) ?? 'N/A'


  return (
    <>
      <Stack w="100%">
        <MyWorkSummary activityCount={todoTable.length.toString()} percentageComplete={percentageComplete + '%'} deadlineApproaching={deadLine} totalActivityAmount={'N/A'} />
        <TableColumnClickExclusion
          tableName='Todo Table'
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          overflow="hidden"
          minHeight="0px"
          columnDefs={colsTodo}
          data={todoTable}
          setRow={(e: ToDoParams, cellName: string) => {
            if (cellName != 'id') {
              console.log('row clicked', e)
            }
          }}
        />
      </Stack>
    </>
  )
}
