import { Flex, Group, Highlight, Text, Title } from '@mantine/core'
import React from 'react'

const COLORS = [
  "#001565", // Dark Blue
  "#F7BE00", // Yellow
  "#3BAF2A", // Green
  "#733188", // Purple
  "#D40153", // Pink
  "#4B4B4D", // Gray
]

type COLOR_OPTIONS = 0 | 1 | 2 | 3 | 4 | 5

export type KpiProps = {
  title: string
  reportDescription?: string
  amount: number | JSX.Element
  percentage?: number
  color?: COLOR_OPTIONS // What should we do about this? Should I make words match those color codes?
  activeFilter: boolean
  event?: () => void
}

const KPI: React.FC<KpiProps> = (
  { title, amount, percentage, color, activeFilter, event, reportDescription }: KpiProps,
  key: string | number
) => {
  return (
    <Flex
      key={key}
      direction="column"
      onClick={event ? () => event() : undefined}
      className="kpiTileMain"
    >
      <Text className="kpiTileAmount" color={COLORS[color ?? 0]}>{amount}</Text>
      <Title className="kpiTileTitle" underline={activeFilter} color={COLORS[color ?? 0]}>{title}</Title>
      {percentage !== undefined ? (
        <Group w="100%" align="flex-end" position="center" noWrap>
        </Group>
      ) : reportDescription ? (
        <Highlight
          color="white"
          highlight={['above', 'below', 'average']}
          highlightStyles={() => ({
            fontWeight: 800,
            backgroundColor: 'transparent',
          })}
        >
          {reportDescription}
        </Highlight>
      ) : null}
    </Flex>
  )
}

export default KPI
