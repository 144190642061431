import * as types from './types'

export const getLetterOfCredits = (payload: any) => ({
  type: types.GET_LETTER_OF_CREDITS,
  payload,
})

export const getLetterOfCredit = (payload: any) => ({
  type: types.GET_LETTER_OF_CREDIT,
  payload,
})

export const getApprovedLetterOfCredits = (payload: any) => ({
  type: types.GET_APPROVED_LETTER_OF_CREDITS,
  payload,
})

export const getAllApprovedLetterOfCredits = (payload: any) => ({
  type: types.GET_ALL_APPROVED_LETTER_OF_CREDITS,
  payload,
})

export const addLetterOfCredit = (payload: any) => ({
  type: types.ADD_LETTER_OF_CREDIT,
  payload,
})

export const editLetterOfCredit = (payload: any) => ({
  type: types.EDIT_LETTER_OF_CREDIT,
  payload,
})

export const submitLetterOfCredit = (payload: any) => ({
  type: types.SUBMIT_LETTER_OF_CREDIT,
  payload,
})

export const approveLetterOfCredit = (payload: any) => ({
  type: types.APPROVE_LETTER_OF_CREDIT,
  payload,
})

export const rejectLetterOfCredit = (payload: any) => ({
  type: types.REJECT_LETTER_OF_CREDIT,
  payload,
})

export const deleteLetterOfCredit = (payload: any) => ({
  type: types.DELETE_LETTER_OF_CREDIT,
  payload,
})

export const getLocHistory = (payload: any) => ({
  type: types.GET_LETTER_OF_CREDIT_HISTORY,
  payload,
})
