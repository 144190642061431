import { WireInstructionParams } from 'app/models/wire-instruction-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface WireInstructionState {
  wireInstructions: WireInstructionParams[]
}

const initialState: WireInstructionState = {
  wireInstructions: [],
}

const wireInstructionsReducer = createReducer(initialState)({
  [types.GET_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    return { ...state, wireInstructions: payload.payload }
  },
  [types.GET_WIRE_INSTRUCTION]: (state: any, payload: any) => {
    return { ...state, wireInstruction: payload.payload }
  },
  [types.ADD_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    return {
      ...state,
      wireInstructions: [payload.payload.data, ...state.wireInstructions],
    }
  },
  [types.EDIT_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    const index = state.wireInstructions.findIndex(
      (wireInstruction: { id: any }) =>
        wireInstruction.id === payload.payload.data.id
    )
    const newArray = [...state.wireInstructions]
    newArray[index] = payload.payload.data
    return {
      ...state,
      wireInstructions: newArray,
    }
  },
  [types.DELETE_WIRE_INSTRUCTIONS]: (state: any, payload: any) => {
    const filteredWireInstructions = state.wireInstructions.filter(
      (wireInstruction: any) => {
        return wireInstruction.id !== payload.payload[0].id
      }
    )

    return {
      ...state,
      entities: filteredWireInstructions,
    }
  },
})

export default wireInstructionsReducer
