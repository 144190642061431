import {
  Accordion,
  Group,
  Stack,
  TextInput,
} from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import React, { useEffect, useState } from 'react'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { HolidayCalendarSummaryAccordion } from './holiday-calendar-summary-accordion'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { useDispatch, useSelector } from 'react-redux'
import { getCalendars } from 'app/state/ducks/holiday-calendars/selectors'
import { getColumnDefs } from './holiday-calendar-table-columnDefs'
import { loadCalendars } from 'app/state/ducks/holiday-calendars/thunks'
import { HolidayCalendarModal } from './holiday-calendar-modal'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import IonIcon from '@reacticons/ionicons'

export default function HolidayCalendarsHeader() {
  const dispatch = useDispatch()
  const [openHolidayCalendar, setOpenHolidayCalendar] = useState(false)
  const [currentCalendar, setCurrentCalendar] = useState<HolidayCalendarParams | undefined>(undefined)
  const holidayCalendars = useSelector(getCalendars)

  useEffect(() => {
    dispatch(loadCalendars())
  }, [])

  function creatingCalendar() {
    setCurrentCalendar(undefined);
    setOpenHolidayCalendar(true);
  }

  function handleRowClick(row: HolidayCalendarParams) {
    setCurrentCalendar(row);
  }

  function updateCurrentCalendar(updatedCalendar: HolidayCalendarParams) {
    setCurrentCalendar(updatedCalendar)
  }

  return (
    <>
      <Stack>
        <Group position="apart" align="center">
          <PageTitle text={'Holiday Calendars'} />
          <Group noWrap>
            <TextInput w="120px" rightSection={<IonIcon size='large' name='search-outline' />} size="lg" />
            <PrimaryButton onClick={creatingCalendar}>
              Create Calendar
            </PrimaryButton>
          </Group>
        </Group>

        <Group noWrap position="apart" align="flex-start" h="100vh">
          <Stack className="data-header-part">
            <TableColumnClickExclusion
              tableName="Holiday_Calendars Table"
              withPagination={false}
              columnDefs={getColumnDefs(setOpenHolidayCalendar)}
              data={holidayCalendars ?? []}
              setRow={(e: any, cellName: string) => {
                handleRowClick(e);
                if (cellName != 'id') {
                  // navigate('/dealmanagement/deal/' + e.id + '/' + e.status)
                }
              }}
            />
          </Stack>

          <Stack className="accordions-header-part">
            <Stack bg="#F9F8F8" w="100%" p="md">
              <Accordion
                multiple
                defaultValue={['HolidayCalendarSummary', 'pdfs', 'notes']}
                variant="contained"
                bg="white"
                styles={{
                  control: {
                    fontFamily: 'Plus Jakarta Sans',
                    '&[data-active]': {
                      backgroundColor: '#A4CAFE',
                    },
                  },
                }}>
                <HolidayCalendarSummaryAccordion calendar={currentCalendar} />
              </Accordion>
            </Stack>
          </Stack>
        </Group>
      </Stack>
      <HolidayCalendarModal
        open={openHolidayCalendar}
        setOpen={setOpenHolidayCalendar}
        holidayCalendar={currentCalendar ?? undefined}
        updateCalendar={updateCurrentCalendar}
      />
    </>
  )
}
