import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Badge, Tooltip, Avatar } from '@mantine/core'
import { ReactNode } from 'react'
import getInitials from 'app/views/components/functions/getInitials'
import { MyWorkDataParams } from 'app/models/mywork-data-params'
import MyWorkActions from './mywork-actions'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

export enum pillState {
  'PendingReview' = 'Pending Review',
  'PendingSubmit' = 'Pending Submit',
  'PendingApproval' = 'Pending Approval',
  'Approved' = 'Approved',
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const colsMyWork: MRT_ColumnDef<MyWorkDataParams>[] = [
  {
    size: 5,
    accessorKey: 'borrower',
    header: 'Borrower',
    Header: <TableHead header={'Borrower'} />,
    mantineFilterTextInputProps: { placeholder: 'Borrower' },
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'taskType',
    header: 'Task Type',
    Header: <TableHead header={'Task Type'} />,
    mantineFilterTextInputProps: { placeholder: 'Task Type' },
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'amount',
    header: 'Amount',
    Header: <TableHead header={'Amount'} />,
    filterVariant: 'range',
    Cell: ({ cell }) => (
      <TableRowText
        text={formatNumberToCurrency(cell.row.original.amount, cell.row.original.currency)}
      />
    ),
  },
  {
    size: 5,
    accessorKey: 'currency',
    header: 'Currency',
    Header: <TableHead header={'Currency'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 5,
    accessorKey: 'effectiveDate',
    header: 'Date',
    Header: <TableHead header={'Event Date'} />,
    accessorFn: row => {
      const effectiveDate = row.effectiveDate instanceof Date ? row.effectiveDate : stringToDate(row.effectiveDate);
      return effectiveDate;
    },
    mantineFilterTextInputProps: {
      placeholder: 'Date',
    },
    filterFn: 'lessThanOrEqualTo',
    filterVariant: 'date',
    sortingFn: 'datetime',
    Cell: ({ cell }) => (
      <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
    ), //render Date as a string
  },
  {
    size: 5,
    accessorFn: row => new Date(row.serviceTime), //convert to Date for sorting and filtering
    accessorKey: 'serviceTime',
    header: 'Time',
    Header: <TableHead header={'Time'} />,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }) ?? 'N/A'}</Text>
    ), //render Date as a string
  },
  {
    size: 5,
    accessorKey: 'stage',
    header: 'Stage',
    Header: <TableHead header={'Stage'} />,
    Cell: ({ cell }) => {
      const state = cell.getValue<string>()
      let styles = {}
      if (state === pillState.PendingReview) {
        styles = {
          backgroundColor: '#FF5A1F',
        }
      }

      if (state === pillState.PendingSubmit) {
        styles = {
          backgroundColor: '#E8006E',
        }
      }
      if (state === pillState.PendingApproval) {
        styles = {
          backgroundColor: '#7E3AF2',
        }
      }
      if (state === pillState.Approved) {
        styles = {
          backgroundColor: '#32439A',
        }
      }

      return (
        <Badge
          styles={{
            root: {
              fontFamily: 'Plus Jakarta Sans',
              color: 'white',
              borderRadius: '4px',
              padding: '1.5em',
              ...styles,
            },
          }}
        >
          <TableRowText text={cell.getValue<string>()} />
        </Badge>
      )
    },
  },
  {
    size: 5,
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: cell => <MyWorkActions row={cell.row.original} />,
  },
  {
    size: 5,
    accessorKey: 'admin',
    header: 'Admin',
    Header: <TableHead header={'Admin'} />,
    Cell: ({ cell }) => {
      const adminName = cell.getValue<string>() ? cell.getValue<string>().toString() : ''
      return (
        <Tooltip label={cell.getValue<string>()}>
          <Avatar radius="xl" color="cyan" size="lg">
            {getInitials(adminName)}
          </Avatar>
        </Tooltip>
      )
    },
  },
]