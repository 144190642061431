import { isNotEmpty, useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Group, Select, Stack } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { Option } from 'app/models/dropdown-options'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { useListState } from '@mantine/hooks'
import {
  createDraftTermination,
  submitFacilityAmendment,
} from './facility-daml-actions'
import { FacilityParams } from 'app/models/facility-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  close: any
  facility: FacilityParams
}

export default function FacilityTerminationForm({ close, facility }: Props) {
  const facilities = useSelector(getFacilities)
  const businessDate = useSelector(getBusinessDate)

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [facilityOptions, setFacilityOptions] = useListState<Option>()

  useEffect(() => {
    dispatch(loadAllFacilities())
  }, [])

  useEffect(() => {
    setFacilityOptions.setState(
      facilities.map(facility => ({ label: facility.name, value: facility.id }))
    )
  }, [facilities])

  const form: any = useForm({
    initialValues: {
      id: facility.id,
      amendmentDate: facility?.amendment
        ? stringToDate(facility?.amendmentDate ?? '')
        : stringToDate(facility?.maturity) ?? businessDate,
    },

    transformValues: values => {
      return {
        id: { id: facility.id, admin: facility.accountManagementAdmin },
        amendmentDate: formatDateToUTC(values.amendmentDate),
      }
    },

    validate: {
      id: isNotEmpty('Facility Id'),
      amendmentDate: isNotEmpty('Amendment Date'),
    },
  })

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await createDraftTermination(
      form.getTransformedValues(),
      dispatch,
      facility?.amendment ? facility : undefined
    )
    setIsLoading(false)
    if (result) {
      close()
    }
  }

  const saveAndSubmit = async () => {
    setIsLoading(true)
    const response: any = await createDraftTermination(
      form.getTransformedValues(),
      dispatch,
      facility?.amendment ? facility : undefined
    )
    if (!response) {
      setIsLoading(false)

      return
    }
    await submitFacilityAmendment(response, dispatch)
    setIsLoading(false)

    close()
  }

  return (
    <FormWrapper title="Terminate">
      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Stack spacing="xl">
              <Group noWrap>
                <Select
                  searchable
                  readOnly
                  withAsterisk
                  label="Facility"
                  placeholder="Select Facility"
                  w="100%"
                  data={facilityOptions}
                  {...form.getInputProps('id')}
                />
                <CustomDatePicker
                  required
                  label={'Termination Date'}
                  date={form.values.amendmentDate}
                  setDate={(value: any) =>
                    form.setFieldValue('amendmentDate', value)
                  }
                  holidayCalendars={facility?.holidayCalendar ?? []}
                />
              </Group>
              <Group noWrap>
                <PrimaryButton
                  loading={isLoading}
                  type="submit"
                  disabled={!form.isValid()}
                  text="Save"
                  w="100%"
                />
                <PrimaryButton
                  loading={isLoading}
                  onClick={() => saveAndSubmit()}
                  disabled={!form.isValid()}
                  text="Create and Submit"
                  w="100%"
                />
              </Group>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
