import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getAssignments,
  addAssignment,
  editAssignment,
  getAssignment,
  submitAssignment,
  rejectAssignment,
  approveAssignment,
  deleteAssignment,
} from 'app/state/ducks/assignments/actions'
import {
  DraftAssignmentParams,
  AssignmentParams,
} from 'app/models/assignment-params'

export const saveAssignment =
  (savedAssignment: DraftAssignmentParams, assignment?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const updated_savedAssignment: DraftAssignmentParams = {
      ...savedAssignment,
      hasError: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }

    if (assignment) {
      return client
        .put(`${apiServerUrl}/api/draft-assignments`, updated_savedAssignment)
        .then(assignment => {
          return dispatch(editAssignment(assignment.data))
        }).catch(e => {
          return {success: false, payload: e.response.data.error}
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/draft-assignments`, updated_savedAssignment)
        .then(assignment => {
          return dispatch(addAssignment(assignment.data))
        }).catch(e => {
          return {success: false, payload: e.response.data.error}
        })
    }
  }

const loadApprovedAssignments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/approved-assignments/`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

const loadDraftAssignments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/draft-assignments`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

const loadSubmittedAssignments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/submitted-assignments`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllAssignments = () => async (dispatch: Dispatch) => {
  const draftAssignments = await loadDraftAssignments()
  const submittedAssignments = await loadSubmittedAssignments()
  const approvedAssignments = await loadApprovedAssignments()

  const subAssignments = submittedAssignments.map((a: { assignment: any }) => ({
    ...a.assignment,
    status: 'Submitted',
  }))

  const allAssignments = draftAssignments
    .map((assignment: any) => ({ ...assignment, status: 'Draft' }))
    .concat(subAssignments)
    .concat(
      approvedAssignments.map((assignment: any) => ({
        ...assignment,
        status: 'Approved',
      }))
    )

  return dispatch(getAssignments(allAssignments))
}

export const loadOneAssignment =
  (assignmentId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(
        `${apiServerUrl}/api/${statusLower}-assignments/one?id=${assignmentId}`
      )
      .then(assignments => {
        if (statusLower === 'submitted') {
          return dispatch(
            getAssignment({ ...assignments.data.assignment, status: status })
          )
        }
        return dispatch(getAssignment({ ...assignments.data, status: status }))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const submitForApproval =
  (assignment: AssignmentParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-assignments/submit`, {
        id: assignment.id,
      })
      .then(response => {
        const payload = dispatch(submitAssignment(response.data))
        return {success: true, payload: payload.payload}
      })
      .catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const rejectSubmittedAssignment =
  (assignment: AssignmentParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-assignments/reject`, {
        id: assignment.id,
      })
      .then(response => {
        const payload = dispatch(rejectAssignment(response.data))
        return {success: true, payload: payload.payload}
      })
      .catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const approveSubmittedAssignment =
  (assignment: AssignmentParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-assignments/approve`, {
        id: assignment.id,
      })
      .then(response => {
        const payload = dispatch(approveAssignment(response.data))
        return {success: true, payload: payload.payload}
      })
      .catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const deleteDraftAssignment =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/draft-assignments?ids[]=${ids}`)
      .then(response => {
        return dispatch(deleteAssignment(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }
