import {
  Anchor,
  Breadcrumbs,
  Group,
  Modal,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { loadOneFacility } from 'app/state/ducks/facilities/thunks'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { UpdatedFacilitiesScreen } from '../facilities/updated-facilities-screen'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import {
  loadLendersPositionsByFacility,
  loadLendersPositionsByLoan,
} from 'app/state/ducks/lenders-positions/thunks'
import { LendersPosition } from 'app/models/lenders-position-params'
import {
  getFacilityLendersPositionsWithLoading,
  getLoanLendersPositionsWithLoading,
} from 'app/state/ducks/lenders-positions/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import StatusPill, { pillState } from '../common/pill-status'
import { DealParams } from 'app/models/deal-params'
import { loadOneLoan } from 'app/state/ducks/loans/thunks'
import { getLoan } from 'app/state/ducks/loans/selectors'
import { useListState } from '@mantine/hooks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { stringToDate } from 'app/utils/util-functions'
import LenderAllocationTable from './funds-table'
import FundManagerLenderTable from './fund-managers-table'
import { lockedTabs, tabs } from './lenders-segment-data'
import SegmentControl from 'app/views/components/segments/segment-control'

interface props {
  tab?: string
}

export default function LenderAllocationPageData({ tab }: props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { contractId, status, contractType } = useParams()

  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [effectiveDate, setEffectiveDate] = useState<Date>(new Date())
  const [deal, setDeal] = useState<DealParams>()
  const [lendersPositions, lendersPositionshandler] =
    useListState<LendersPosition>([])

  const { data: facilityLendersPositions, isLoading: loadingFacilityLendersPositions } = useSelector(
    getFacilityLendersPositionsWithLoading
  )
  const { data: loanLendersPositions, isLoading: loadingLoanLendersPositions } = useSelector(
    getLoanLendersPositionsWithLoading
  )
  const facility = useSelector(getFacility)
  const deals = useSelector(getDeals)
  const loan = useSelector(getLoan)
  const currentDate = useSelector(getBusinessDate)

  useEffect(() => {
    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadWireInstructions())
    dispatch(loadAllDeals())
    dispatch(loadIndexRateOptions())
  }, [])

  useEffect(() => {
    const contractStartDate = stringToDate((contractType === 'loans' ? loan?.startDate : facility?.startDate) ?? '')
    setEffectiveDate(contractStartDate > currentDate ? contractStartDate : currentDate)
  }, [facility, loan, currentDate])

  useEffect(() => {
    if (contractType === 'loans') {
      if (!loanLendersPositions) {
        return
      }
      lendersPositionshandler.setState(loanLendersPositions)
      return
    }
    if (contractType === 'facilities') {
      if (!facilityLendersPositions) {
        return
      }
      lendersPositionshandler.setState(facilityLendersPositions)
      return
    }
    lendersPositionshandler.setState([])
  }, [facilityLendersPositions, loanLendersPositions, contractType])

  useEffect(() => {
    if (contractType === 'loans') {
      dispatch(loadLendersPositionsByLoan(contractId ?? ''))
      dispatch(loadOneLoan(contractId ?? '', status ?? ''))
      return
    }
    if (contractType === 'facilities') {
      dispatch(loadLendersPositionsByFacility(contractId ?? ''))
      dispatch(loadOneFacility(contractId ?? '', status ?? ''))
      return
    }
  }, [contractId, contractType, status])

  useEffect(() => {
    if (!loan || contractType !== 'loans') {
      return
    }
    if (loan.status === "Locked") {
      dispatch(loadOneFacility(loan.facilityId.id, 'Locked'))
    }
    dispatch(loadOneFacility(loan.facilityId.id, 'Approved'))
  }, [loan, contractType])

  useEffect(() => {
    if (!facility || !deals) {
      return
    }
    const selectedDeal = deals.find(
      (deal: any) => deal.id === facility.dealId.id
    )
    if (!selectedDeal) {
      return
    }
    setDeal(selectedDeal)
  }, [facility, deals])

  const items = [
    { title: 'Deal Management', action: () => navigate('/dealmanagement') },
    {
      title: 'Facilities',
      action: () => navigate('/dealmanagement/facilities'),
    },
    {
      title: facility?.id ?? '',
      action: () =>
        navigate(
          `/dealmanagement/facilities/${facility?.id}/${facility?.status}`
        ),
    },
  ].map((item, index) => (
    <Anchor
      onClick={() => item.action()}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  const activeTabs = {
    tab: !tab || tab === 'funds' ? tabs.funds : tabs.fundsManager,
    locked: '',
  }

  return (
    <PageLayout>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      <Group position="apart" align="center">
        <PageTitle text="Lender Allocations" />
        <Group noWrap>
          {contractType == 'facilities' && facility?.status === 'Draft' && (
            <PrimaryButton
              text="Edit Lender Allocations"
              onClick={() => setOpenEdit(true)}
            />
          )}
        </Group>
      </Group>
      <Group noWrap >
        <StatusPill state={status as pillState} />
        <SegmentControl tabs={tabs} activeTabs={activeTabs} lockedTabs={lockedTabs} id={contractId} status={status} contractType={contractType} />
      </Group>
      {((contractType === 'loans' && !loadingLoanLendersPositions)
        || (contractType === 'facilities' && !loadingFacilityLendersPositions))
        &&
        tab === 'funds' ? (
        <LenderAllocationTable
          effectiveDate={effectiveDate}
          currency={contractType === 'facilities'
            ? facility?.baseCurrency ?? 'USD'
            : loan?.currency ?? 'USD'
          }
          lendersPositions={lendersPositions}
        />
      ) :
        (<FundManagerLenderTable
          effectiveDate={effectiveDate}
          currency={contractType === 'facilities'
            ? facility?.baseCurrency ?? 'USD'
            : loan?.currency ?? 'USD'
          }
          lendersPositions={lendersPositions}
        />)}
      {contractType == 'facilities' && facility?.status === 'Draft' && (
        <Modal
          className="modal-body modal-body-template fit-content-modal"
          opened={openEdit}
          onClose={() => setOpenEdit(false)}
          centered={true}
          size="77%"
        >
          <UpdatedFacilitiesScreen
            deal={deal}
            facility={facility}
            onClose={() => setOpenEdit(false)}
            step={1}
          />
        </Modal>
      )}
    </PageLayout>
  )
}

