import { Button, Table, Text } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { StableKey } from 'app/models/common-types'
import { PaymentParams, PaymentReceivedFromLender, SenderParams } from 'app/models/payments-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getSenders } from 'app/state/ducks/payments/selectors'
import { changeReceivedLenders } from 'app/state/ducks/payments/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

interface Props {
  payment: PaymentParams
}
export const SenderTable = ({ payment }: Props) => {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const senders = useSelector(getSenders)
  const [lenderReceived, lenderReceivedHandler] = useListState<PaymentReceivedFromLender>()
  const [disabled, setDisabled] = React.useState(true)
  useEffect(() => {
    lenderReceivedHandler.setState(senders.map(({ lenderId, paymentReceived }) => ({ lenderId, oldPaymentReceived: paymentReceived, newPaymentReceived: paymentReceived })))
  }, [senders])

  const changeReceived = (lenderId: StableKey, value: boolean) => {
    const newLenderReceived = [...lenderReceived]
    const index = newLenderReceived.findIndex(lender => lender.lenderId === lenderId)
    if (index < 0) {
      return
    }
    newLenderReceived[index].newPaymentReceived = value
    lenderReceivedHandler.setState(newLenderReceived)
    setDisabled(newLenderReceived.every(lender => lender.oldPaymentReceived === lender.newPaymentReceived))
  }

  const saveReceived = async () => {
    const updatedLenders = lenderReceived.filter(lender => lender.oldPaymentReceived !== lender.newPaymentReceived).map(({ lenderId, newPaymentReceived }) => ({ lender: lenderId, paymentReceived: newPaymentReceived }))
    const response: any = await dispatch(changeReceivedLenders(payment, updatedLenders))
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Received status Failed',
        message: response.payload ?? 'Failed to change payment received status',
      })
      return
    }

    SuccessNotification({
      title: 'Successful changed payment received status',
      message: 'Statuses are updated.',
    })

  }
  return (
    <>
      <Table fontSize="1.2em">
        <thead>
          <tr>
            <th>Lender</th>
            <th>Currency</th>
            <th>Amount Expected</th>
            <th>Received</th>
          </tr>
        </thead>
        <tbody>
          {senders.map((lender: SenderParams, index: number) => {
            const received = lenderReceived.find(({ lenderId }) => lenderId === lender.lenderId)?.newPaymentReceived ?? lender.paymentReceived
            return (
              <tr key={'lenderallocation-' + index}>
                <td>
                  {entities &&
                    entities.length > 0 &&
                    (entities.find(entity => entity.id === lender.lenderId.id)
                      ?.entityName ??
                      '')}
                </td>
                <td>{payment.payment.currency}</td>
                <td>
                  {formatNumberToCurrency(Number(lender.amount), payment.payment.currency)}
                </td>
                <Button
                  disabled={payment.status !== 'Queued' || payment.submitted}
                  variant="outline"
                  onClick={() => changeReceived(lender.lenderId, !received)}
                >
                  {received ? (
                    <Text color="green" fz="1em">
                      Yes
                    </Text>
                  ) : (
                    <Text color="red">No</Text>
                  )}
                </Button>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingTop: '1em' }}>
        <PrimaryButton disabled={disabled} onClick={saveReceived}>Save</PrimaryButton>
      </div>
    </>
  )
}
