import {
    Accordion,
    Group,
    Stack,
} from '@mantine/core'
import { AttachmentsAccordion } from 'app/views/components/Accordion/attachments-accordion/attachments-accordion'
import { NotesAccordion } from 'app/views/components/Accordion/notes-accordion'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ListAccordion } from 'app/views/components/Accordion/two-column-list-accordion'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity } from 'app/state/ducks/entities/selectors'
import { loadOneEntity } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import { getEntityOverviewData } from '../../entities/drawer/accordion-data'
import ManageEntityContacts from '../../entities/single-entity-page/manage-entity-contact'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'

export default function ContactsPage() {
    const { entityId, status } = useParams()
    const entity = useSelector(getEntity)

    const dispatch = useDispatch()


    useEffect(() => {
        if (entityId) {
            dispatch(loadOneEntity(entityId, status ?? 'Inactive'))
            dispatch(loadNoteByContract(entityId, ContractType.Entities))
        }
    }, [entityId])

    return (
        <Stack w="100%">
            <Group noWrap position="apart" align="flex-start" h="100vh">
                <Stack className="data-header-part">
                    <ManageEntityContacts entityId={entity?.id} />
                </Stack>
                <Stack className="accordions-header-part">
                    <Stack h="100%" bg="#F9F8F8" w="100%" p="md">
                        <Accordion
                            defaultValue={['pdfs', 'Entity Overview', 'notes']}
                            multiple
                            variant="contained"
                            bg="white"
                            styles={{
                                control: {
                                    fontFamily: 'Plus Jakarta Sans',
                                    '&[data-active]': {
                                        backgroundColor: '#A4CAFE',
                                    },
                                },
                            }}
                        >
                            <ListAccordion
                                title="Entity Overview"
                                data={getEntityOverviewData(entity)}
                            />
                            <AttachmentsAccordion
                                id={entityId ?? ''}
                                type={ContractType.Entities}
                            />
                            <NotesAccordion
                                contractId={entity?.id ?? ''}
                                contractAdmin={entity?.maker ?? ''}

                                type={ContractType.Entities}
                            />
                        </Accordion>
                    </Stack>
                </Stack>
            </Group>
        </Stack>
    )
}