import { useEffect, useState } from 'react'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import { TableData, iroCols } from './index-rate-options-table-columnDefs'
import { Col, Grid, Stack } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
// import { useNavigate } from 'react-router-dom'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { DailyRates } from './interest-rates/daily-rates'

export function ManageIndexRateOptions() {
  // const navigate = useNavigate()
  // Leave Navigate for further implementation (if needed)

  const dispatch = useDispatch()
  const [dailyRates, setDailyRates] = useState<IndexRateOptionParams>()
  const [tableData, setTableData] = useState<TableData[]>([])
  const indexRateOptions: IndexRateOptionParams[] =
    useSelector(getIndexRateOptions)

  useEffect(() => {
    dispatch(loadIndexRateOptions())
  }, [])

  useEffect(() => {
    setTableData(
      indexRateOptions.map(data => ({ ...data, setDailyRates: setDailyRates }))
    )
  }, [indexRateOptions])

  return (
    <Stack>
      <Grid gutter="lg">
        <Col span={dailyRates ? 7 : 12}>
          <div style={{ height: '100%' }}>
            <TableColumnClickExclusion
              tableName='Index_Rate_Options Table'
              columnDefs={iroCols}
              data={tableData}
              setRow={(e: any, cellName: string) => {
                if (cellName != 'id') {
                  // navigate('/indexrateoptions/indexrateoption/' + e.id + '/' + e.status)
                }
              }}
            />
          </div>
        </Col>
        {dailyRates && (
          <Col span={5}>
            <DailyRates
              index={dailyRates}
              closeDailyRates={() => setDailyRates(undefined)}
            />
          </Col>
        )}
      </Grid>
    </Stack>
  )
}
