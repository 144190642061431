import * as types from './types'

export const getContacts = (payload: any) => ({
  type: types.GET_CONTACTS,
  payload,
})

export const getContact = (payload: any) => ({
  type: types.GET_CONTACT,
  payload,
})

export const addContact = (payload: any) => ({
  type: types.ADD_CONTACT,
  payload,
})

export const editContact = (payload: any) => ({
  type: types.EDIT_CONTACTS,
  payload,
})

export const deleteContacts = (payload: any) => ({
  type: types.DELETE_CONTACTS,
  payload,
})

export const activeContact = (payload: any) => ({
  type: types.ACTIVE_CONTACT,
  payload,
})

export const inactiveContact = (payload: any) => ({
  type: types.INACTIVE_CONTACT,
  payload,
})