import { NoticeParams } from 'app/models/notices-params'
import {
    submitForApproval,
    approveSelectedNotice,
    rejectSelectedNotice,
    previewSelectedNotice,
} from 'app/state/ducks/notices/thunks'
import {
    ErrorNotification,
    SuccessNotification,
} from 'app/views/components/notifications/notification'
import { Dispatch } from 'redux'

export async function submitNotice(notice: NoticeParams, dispatch: Dispatch<any>) {
    const response: any = await dispatch(submitForApproval(notice))
    if (response.success) {
        SuccessNotification({
            title: 'Successful Submission',
            message: 'Notice has been Submitted for Approval',
        })
    } else {
        ErrorNotification({
            title: 'Submission Failed',
            message: response.payload ?? 'Notice submission failed',
        })
    }
    return
}

export async function rejectNotice(notice: NoticeParams, dispatch: Dispatch<any>) {
    const response: any = await dispatch(rejectSelectedNotice(notice))
    if (response.success) {
        SuccessNotification({
            title: 'Successful Rejection',
            message: 'Notice has been moved back to a Draft',
        })
    } else {
        ErrorNotification({
            title: 'Rejection Failed',
            message: response.payload ?? 'Notice rejection failed',
        })
    }
    return
}

export async function approveNotice(notice: NoticeParams, dispatch: Dispatch<any>) {
    const response: any = await dispatch(approveSelectedNotice(notice))
    if (response.success) {
        SuccessNotification({
            title: 'Successful Approval',
            message: 'Notice has been Approved',
        })
    } else {
        ErrorNotification({
            title: 'Approval Failed',
            message: response.payload,
        })
    }
    return
}

export async function previewNotice(notice: NoticeParams, dispatch: Dispatch<any>) {
    const response: any = await dispatch(previewSelectedNotice(notice, notice.noticeType))
    console.log('notice-daml-actions--->previewNotice', response)
    if (response.success) {
        SuccessNotification({
            title: 'Successful Preview',
            message: 'Notice has been previewed',
        })
    } else {
        ErrorNotification({
            title: 'Preview Failed',
            message: response.payload ?? 'Notice preview failed',
        })
    }
    return
}