import { EntityParams } from 'app/models/entity-params';
import { KpiProps } from 'app/views/components/KPI/KPI';
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters';

export const getKPIData = (
  data: EntityParams[],
  setData: React.Dispatch<React.SetStateAction<EntityParams[]>>
): KpiProps[] => {
  const filterFunctions: Record<string, (ent: EntityParams) => boolean> = {
    'Unvalidated Tax Form': (ent: EntityParams) => ent.taxFormStatus !== 'Validated',
    'Inactive': (ent: EntityParams) => ent.status === 'Inactive',
    'Active': (ent: EntityParams) => ent.status === 'Active',
    'Pending KYC': (ent: EntityParams) => ent.kycStatus === 'PendingKycStatus',
  };
  const { applyFilter, appliedFilters } = useKPIFilters({ data, filterFunctions, setData });

  if (!data) return [];

  const allEntities = data;
  const validatedEnts = data.filter(ent => ent.taxFormStatus !== 'Validated');
  const inactiveEnts = data.filter(ent => ent.status === 'Inactive');
  const activeEnts = data.filter(ent => ent.status === 'Active');
  const pendingKYCEnts = data.filter(ent => ent.kycStatus === 'PendingKycStatus');

  return [
    {
      title: 'All Entities',
      amount: allEntities.length,
      color: 0,
      activeFilter: appliedFilters.size === 0,
      event: () => applyFilter('All Entities')
    },
    {
      title: 'Unvalidated Tax Form',
      amount: validatedEnts.length,
      color: 1,
      activeFilter: appliedFilters.has('Unvalidated Tax Form'),
      event: () => applyFilter('Unvalidated Tax Form')
    },
    {
      title: 'Inactive',
      amount: inactiveEnts.length,
      color: 2,
      activeFilter: appliedFilters.has('Inactive'),
      event: () => applyFilter('Inactive')
    },
    {
      title: 'Active',
      amount: activeEnts.length,
      color: 3,
      activeFilter: appliedFilters.has('Active'),
      event: () => applyFilter('Active')
    },
    {
      title: 'Pending KYC',
      amount: pendingKYCEnts.length,
      color: 4,
      activeFilter: appliedFilters.has('Pending KYC'),
      event: () => applyFilter('Pending KYC'),
    },
  ];
};
