import { ContactParams } from 'app/models/contact-params';
import * as types from './types'
import { createReducer } from 'app/state/utils'

interface ContactsState {
  contacts: ContactParams[]; // Array of contact objects
  contact: ContactParams | null; // Single contact or null
}

const initialState: ContactsState = { contacts: [], contact: null }
const contactsReducer = createReducer(initialState)({
  [types.GET_CONTACTS]: (state: any, payload: any) => {
    return { ...state, contacts: payload.payload }
  },
  [types.GET_CONTACT]: (state: any, payload: any) => {
    return { ...state, contact: payload.payload }
  },
  [types.ADD_CONTACT]: (state: any, payload: any) => {
    return {
      ...state,
      contacts: [payload.payload.data, ...state.contacts],
    }
  },
  [types.EDIT_CONTACTS]: (state: any, payload: any) => {
    const index = state.contacts.findIndex(
      (contact: { id: any }) => contact.id === payload.payload.data.id
    )
    const newArray = [...state.contacts]
    newArray[index] = { ...payload.payload.data, status: 'Inactive' }
    return {
      ...state,
      contacts: newArray,
      contact: { ...payload.payload.data, status: 'Inactive' },
    }
  },
  [types.ACTIVE_CONTACT]: (state: any, payload: any) => {
    const index = state.contacts.findIndex(
      (contact: { id: any }) => contact.id === payload.payload.id
    )
    const newArray = [...state.contacts]
    newArray[index] = { ...newArray[index], status: 'Active' }
    if (payload.payload.id === state.contact?.id) {
      return {
        ...state,
        contact: { ...state.contact, status: 'Active' },
        contacts: newArray,
      }
    }
    return {
      ...state,
      contacts: newArray,
    }
  },
  [types.INACTIVE_CONTACT]: (state: any, payload: any) => {
    const index = state.contacts.findIndex(
      (contact: { id: any }) => contact.id === payload.payload.id
    )
    const newArray = [...state.contacts]
    newArray[index] = { ...newArray[index], status: 'Inactive' }
    if (payload.payload.id === state.contact?.id) {
      return {
        ...state,
        contact: { ...state.contact, status: 'Inactive' },
        contacts: newArray,
      }
    }
    return {
      ...state,
      contacts: newArray,
    }
  },
  [types.DELETE_CONTACTS]: (state: any, payload: any) => {
    const filteredContacts = state.contacts.filter((contact: any) => {
      return contact.id !== payload.payload[0].id
    })

    return {
      ...state,
      contacts: filteredContacts,
    }
  },
})

export default contactsReducer