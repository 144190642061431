import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getCalendars,
  getCalendar,
  addCalendar,
  editCalendar,
  deleteCalendars,
  activeCalendar,
  inactiveCalendar,
} from 'app/state/ducks/holiday-calendars/actions'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'

export const saveCalendar =
  (savedCalendar: HolidayCalendarParams, calendar?: HolidayCalendarParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = savedCalendar.status.toLowerCase() || 'inactive'

    if (calendar) {
      savedCalendar.id = calendar.id
      return client
        .put(`${apiServerUrl}/api/${statusLower}-holiday-calendars`, savedCalendar)
        .then(calendar => {
          dispatch(editCalendar(calendar))
          return { success: true, payload: calendar}
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    }
    delete savedCalendar.id
    return client
      .post(`${apiServerUrl}/api/${statusLower}-holiday-calendars`, savedCalendar)
      .then(calendar => {
        calendar.data.status = statusLower
        dispatch(addCalendar(calendar))
        return { success: true, payload: calendar}
      })
      .catch(error => {
        return { success: false, payload: error.response.data.error }
      })
  }

const loadInactiveCalendars = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/inactive-holiday-calendars`)
    .then(response => {
      return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

const loadActiveCalendars = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/active-holiday-calendars`)
    .then(response => {
        return response.data
    }).catch(e => {
      console.log(e)
      return []
    })
}

export const loadCalendars = () => async (dispatch: Dispatch) => {
  const inactiveCalendars = await loadInactiveCalendars()
  const activeCalendars = await loadActiveCalendars()
  const allCalendars = inactiveCalendars
    .map((calendar: any) => ({ ...calendar, status: 'Inactive' }))
    .concat(
      activeCalendars.map((calendar: any) => ({ ...calendar, status: 'Active' }))
    )
  return dispatch(getCalendars(allCalendars))
}

export const loadOneCalendar =
  (calendarId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-holiday-calendars/one?id=${calendarId}`)
      .then(calendar => {
        return dispatch(getCalendar({ ...calendar.data, status: status }))
      }).catch(e => {
        return {success: false, payload: e.response.data.error}
      })
  }

export const removeCalendars = (calendar: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/inactive-holiday-calendars?ids[]=${calendar}`)
    .then(calendars => {
      return dispatch(deleteCalendars(calendars.data))
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const activateCalendar =
  (calendar: HolidayCalendarParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/inactive-holiday-calendars/activate`, { id: calendar.id })
      .then(response => {
        dispatch(activeCalendar(response.data))
        return { success: true, payload: response.data}
      })
      .catch(error => {
        return { success: false, payload: error.response.data.error }
      })
  }

export const deactivateCalendar =
  (calendar: HolidayCalendarParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/active-holiday-calendars/deactivate`, { id: calendar.id })
      .then(response => {
        dispatch(inactiveCalendar(response.data))
        return { success: true}
      })
      .catch(error => {
        return { success: false, payload: error.response.data.error }
      })
  }