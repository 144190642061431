/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllActiveNotices, getAllLockedNotices } from 'app/state/ducks/notices/selectors'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import Table2 from 'app/views/components/Table2/Table2'
import { cols } from './notices-columnDefs'
import { loadActiveNotices, loadLockedNotices } from 'app/state/ducks/notices/thunks'
import { NoticeParams } from 'app/models/notices-params'
import { Group, Stack } from '@mantine/core'
import { getKPIData } from './notices-kpi-data'
import SegmentControl from 'app/views/components/segments/segment-control'
import { tabs, lockedTabs } from './notices-segment-data'


interface props {
    tab?: string | null | undefined
}

export function NoticePage({ tab }: props) {
    const [row, setRow] = useState<NoticeParams>()
    const [open, setOpen] = useState<boolean>(false)
    const activeNotices: NoticeParams[] = useSelector(getAllActiveNotices)
    const lockedNotices: NoticeParams[] = useSelector(getAllLockedNotices)
    const [filteredNotices, setFilteredNotices] = useState<NoticeParams[]>(
        tab === 'archived' ? lockedNotices : activeNotices
    )

    const kpiData = getKPIData(tab === "archived" ? lockedNotices : activeNotices, setFilteredNotices)
    const dispatch = useDispatch()

    function handleRowClicked(e: any) {
        setRow(e)
        setOpen(true)
    }

    useEffect(() => {
        if (tab === 'archived') {
            dispatch(loadLockedNotices())
        } else {
            dispatch(loadActiveNotices())
        }
    }, [])

    useEffect(() => {
        if (tab === 'archived') {
            setFilteredNotices(lockedNotices)
        } else {
            setFilteredNotices(activeNotices)
        }
    }, [tab, activeNotices, lockedNotices])

    const activeTabs = {
        tab: tabs.notices,
        locked: tab === 'archived' ? lockedTabs.archived : lockedTabs.active,
    }

    return (
        <Stack>
            <KPI_Section
                kpiData={kpiData}
            />
            <Group position="right">
                <SegmentControl activeTabs={activeTabs} tabs={tabs} lockedTabs={lockedTabs} />
            </Group>
            <Table2
                columnDefs={cols()}
                data={filteredNotices.sort((a, b) => a.effectiveDate.localeCompare(b.effectiveDate))}
                setRow={handleRowClicked}
            />
        </Stack>
    )
}
