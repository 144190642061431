import { Group, Stack } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import CreateDealButton from './deal-management-components/create-deal-btn'

function DealManagementHeaderSection() {
  return (
    <>
      <Stack>
        <Group position="apart" style={{ marginBottom: '15px' }}>
          <PageTitle>Deal Management</PageTitle>
          <CreateDealButton />
        </Group>
      </Stack>
    </>
  )
}

export default DealManagementHeaderSection
