import { FacilitiyState } from './reducer'

export const getFacilities = (state: { facilities: FacilitiyState }) =>
  state.facilities.facilities

export const getFacility = (state: { facilities: FacilitiyState }) => {
  return state.facilities.facility
}

export const getFacilityHistory = (state: { facilities: FacilitiyState }) =>
  state.facilities.facilityHistory

export const getLockedFacilities = (state: { facilities: FacilitiyState }) => {
  return state.facilities.lockedFacilities
}
