import { Accordion } from '@mantine/core'
import React, { ReactNode } from 'react'

type Props<T extends { id?: string }> = {
  items: T[]
  children: (item: T) => ReactNode
  defaultExpanded?: boolean // Add a defaultExpanded prop
}

const NestedAccordion = <T extends { id?: string }>({
  items,
  children,
  defaultExpanded = false,
}: Props<T>) => {
  return (
    <Accordion
      multiple
      defaultValue={
        defaultExpanded
          ? items.map((item, index) => item.id ?? index.toString())
          : []
      }
      w="100%"
      p={0}
      variant="contained"
    >
      {items.map((item, index) => (
        <div key={item.id}>
          <Accordion.Item value={item.id ?? index.toString()}>
            {children(item)}
          </Accordion.Item>
        </div>
      ))}
    </Accordion>
  )
}

export default NestedAccordion
