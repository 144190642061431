import * as types from './types'

export const getDealAmendments = (payload: any) => ({
  type: types.GET_DEAL_AMENDMENTS,
  payload,
})

export const getDealAmendment = (payload: any) => ({
  type: types.GET_DEAL_AMENDMENT,
  payload,
})

export const addDealAmendment = (payload: any) => ({
  type: types.ADD_DEAL_AMENDMENTS,
  payload,
})

export const editDealAmendment = (payload: any) => ({
  type: types.EDIT_DEAL_AMENDMENTS,
  payload,
})

export const submitDealAmendment = (payload: any) => ({
  type: types.SUBMIT_DEAL_AMENDMENT,
  payload,
})

export const rejectDealAmendment = (payload: any) => ({
  type: types.REJECT_DEAL_AMENDMENT,
  payload,
})

export const approveDealAmendment = (payload: any) => ({
  type: types.APPROVE_DEAL_AMENDMENT,
  payload,
})

export const deleteDealAmendment = (payload: any) => ({
  type: types.DELETE_DEAL_AMENDMENT,
  payload,
})
