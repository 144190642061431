export const tabs = {
  deals: '/dealmanagement/deals',
  facilities: '/dealmanagement/facilities',
}

export const lockedTabs = {
  active: '',
  terminated: '/terminated',
}

export const activeTabs = {
  tab: tabs.deals,
  locked: lockedTabs.terminated,
}
