import {
  Accordion,
  Anchor,
  Breadcrumbs,
  Group,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import DealAccordion from './deal-main-accordion'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import StatusPill from '../common/pill-status'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import { loadAllDealAmendments } from 'app/state/ducks/deal-amendments/thunks'
import { loadAllDeals, setOneDeal } from 'app/state/ducks/deals/thunks'
import DealHeader from './deal-header'
import DealDetailSidebar from './deal-detail-sidebar'
import RelatedFacilitiesAccordion from '../facilities-page/single-facility/related-accordion'

enum Tab {
  deal = 'Deal',
}

export default function DealContentPage(): JSX.Element {
  const navigate = useNavigate()
  const { dealId, status } = useParams()

  const dispatch = useDispatch()
  const deal: DealParams | undefined = useSelector(getDeal)
  const allDeals: DealParams[] = useSelector(getDeals)
  const dealAmendments: DealParams[] = useSelector(getDealAmendments)

  useEffect(() => {
    if (allDeals) {
      const selectedDeal = allDeals.find(deal => deal.id === dealId)
      if (selectedDeal) {
        selectedDeal.amendment = false
        dispatch(setOneDeal(selectedDeal))
      }
    }
    if (dealAmendments) {
      const amended_deal = dealAmendments.find(
        amendment => amendment.id === dealId
      )
      if (amended_deal) {
        amended_deal.amendment = true
        dispatch(setOneDeal(amended_deal))
      }
    }
  }, [allDeals, dealId, dealAmendments])

  useEffect(() => {
    dispatch(loadAllDeals())
    dispatch(loadEntities())
  }, [dealId])

  useEffect(() => {
    dispatch(loadAllFacilities())
    dispatch(loadAllDealAmendments())
  }, [])

  const items = [
    { title: 'Deal Management', href: '/dealmanagement' },
    { title: 'Deal', href: "/dealmanagement/deal/" + dealId + "/" + status },
  ].map((item, index) => (
    <Anchor
      onClick={() => navigate(item.href)}
      key={'deal-page-breadcrum' + index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  function navigateToFacilities() {
    // navigate('/dealmanagement/facilities')
  }

  return (
    <Stack>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      {deal ? <DealHeader deal={deal} /> : null}
      <Group h="100%">
        <StatusPill
          state={deal?.status ?? 'Draft'}
          additionalState={deal?.amendmentType ?? ''}
        />
        <Text>Show:</Text>
        <SegmentedControl
          onClick={() => {
            navigateToFacilities()
          }}
          size="lg"
          data={[{ value: Tab.deal, label: 'Deal' }]}
          styles={{
            controlActive: {
              backgroundColor: '#A4CAFE',
            },
          }}
        />
      </Group>

      <Group noWrap position="apart" align="flex-start" h="100vh">
        <Stack className="data-header-part">
          <Stack w="100%">
            <Accordion
              defaultValue={['deal accordion', 'related Facilities']}
              multiple
              bg="#32429A"
              variant="contained"
              styles={{
                control: {
                  fontFamily: 'Plus Jakarta Sans',
                  '&[data-active]': {
                    backgroundColor: '#32429A',
                  },
                  '&:hover': {
                    backgroundColor: '#32429A',
                  },
                },
                chevron: {
                  color: 'white',
                },
              }}
            >
              <DealAccordion />
              <RelatedFacilitiesAccordion />
            </Accordion>
          </Stack>
        </Stack>
        {deal ? <DealDetailSidebar deal={deal} /> : null}
      </Group>
    </Stack>
  )
}
