/* eslint-disable @typescript-eslint/no-unused-vars */
import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    deleteContacts,
    setActiveContact,
    makeInactiveContact,
} from './contacts-daml-actions'
import { ContactParams } from 'app/models/contact-params'
import IonIcon from '@reacticons/ionicons'
import ContactsForm from '../../contacts/contacts-form'
import { ContactsModal } from '../../contacts/contacts-modal'

type Props = {
    row: ContactParams
}

export default function ContactActions({ row }: Props) {
    const dispatch = useDispatch()
    const [addContacts, setAddContacts] = useState<boolean>(false)

    function editContact() {
        setAddContacts(true)
    }

    return (
        <div className='ContactActions'>
            <ContactsModal
                open={addContacts}
                setOpen={setAddContacts}
                contact={row}
                entityId={row.entity.id ?? ''}
            />
            <Menu shadow="md" width={200} position="bottom" withinPortal>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        <PrimaryDropDown>Actions</PrimaryDropDown>
                    </div>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                        onClick={() => editContact()}
                    >
                        Edit
                    </Menu.Item>

                    {row?.status?.toLowerCase() == 'inactive' ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => {
                                setActiveContact(row, dispatch)
                            }}
                        >
                            Activate
                        </Menu.Item>
                    ) : (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => {
                                makeInactiveContact(row, dispatch)
                            }}
                        >
                            Deactivate
                        </Menu.Item>
                    )}

                    {row?.status?.toLowerCase() == 'inactive' ? (
                        <Menu.Item
                            color="red"
                            icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
                            onClick={() => {
                                deleteContacts(row, dispatch)
                            }}
                        >
                            Delete
                        </Menu.Item>
                    ) : null}
                </Menu.Dropdown>
            </Menu>
        </div>
    )
}
