import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { PageLayout } from 'app/views/components/layout/page-layout'
import DealManagementHeaderSection from './deal-management-header'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadCalendars } from 'app/state/ducks/holiday-calendars/thunks'
import DealManagmentPage from './deals/deal-management'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadAllDealAmendments } from 'app/state/ducks/deal-amendments/thunks'
export function DealManagementPage() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadCalendars())
    dispatch(loadWireInstructions())
    dispatch(loadIndexRateOptions())
    dispatch(loadAllDeals())
    dispatch(loadAllDealAmendments())
  }, [])

  return (
    <PageLayout>
      <DealManagementHeaderSection />
      <DealManagmentPage />
    </PageLayout>
  )
}
