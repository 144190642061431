import VerticalStepper from 'app/views/components/stepper-component'
import { useEffect, useRef, useState } from 'react'
import ManageLoanLenders, {
    ChildManageLendersRef,
} from 'app/views/components/lenders/manage-loan-lenders'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import UpdatedLoanModal, {
    ChildLoanRef,
} from 'app/views/pages/deals-management/loans/loan-form-presentation'
import { isNotEmpty, useForm } from '@mantine/form'
import { useDispatch, useSelector } from 'react-redux'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { EntityParams } from 'app/models/entity-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { FacilityParams } from 'app/models/facility-params'
import { LoanParams } from 'app/models/loan-params'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'

type Props = {
    facility: FacilityParams | undefined
    loan?: LoanParams | undefined
    onClose: () => void
    step?: number
}

interface CustomStepContentProps {
    onNext: () => void
    onPrevious: () => void
}

export const UpdatedLoanScreen: React.FC<Props> = ({
    loan,
    onClose,
    facility,
    step,
}) => {
    const [updated_loan, setLoan] = useState(loan)

    const LoanContent: React.FC<CustomStepContentProps> = ({
        onNext,
    }) => {
        const childRef = useRef<ChildLoanRef>(null)
        const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handleSaveFacilityAndNext = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onNext()
            }
        }

        const dispatch = useDispatch()
        const wireInstructionList = useSelector(getWireInstructions)
        const entities = useSelector(getEntities)
        const businessDate = useSelector(getBusinessDate)
        const allIndexes = useSelector(getIndexRateOptions)


        useEffect(() => {
            dispatch(loadWireInstructions())
            dispatch(loadIndexRateOptions())
        }, [])


        const form: any = useForm({
            initialValues: {
                id: updated_loan?.id ?? undefined,
                facilityId: cleanUUID(facility?.id ?? '') ?? '',
                amount: Number(updated_loan?.amount) ?? 0,
                currency:
                    updated_loan?.currency ?? facility?.baseCurrency ?? facility?.baseCurrency,
                dayBasis: updated_loan?.dayBasis ?? '',
                interestBaseRate: Number(updated_loan?.interestBaseRate) ?? 0,
                indexOption: updated_loan?.indexOption.id ?? '',
                interestBaseRateWithRounding:
                    Number(updated_loan?.interestBaseRateWithRounding) ?? 0,
                rounding: updated_loan?.rounding ?? '',
                endDate: updated_loan?.endDate ? stringToDate(updated_loan.endDate) : null,
                startDate: updated_loan?.startDate
                    ? stringToDate(updated_loan.startDate)
                    : businessDate,
                isSwingLine: updated_loan?.isSwingLine == true ? 'true' : 'false',
                isNonProRata: updated_loan?.isNonProRata == true ? 'true' : 'false',
                margin: Number(updated_loan?.margin) ?? 0,
                allInRate: Number(updated_loan?.allInRate) ?? 0,
                amountDue: null,
                accrualInterestMaturityDate: null,
                casValue: Number(updated_loan?.casValue) ?? 0,
                contractPeriod: updated_loan?.contractPeriod ?? '',
                borrower: cleanUUID(updated_loan?.borrower?.id ?? '') ?? '',
                borrowerPaymentInstructions:
                    cleanUUID(updated_loan?.borrowerPaymentInstructions?.id) ?? '',
                hasError: false,
                ErrorMessage: '',
                approveRate: false,
                lendersPositions: updated_loan?.lendersPositions ?? [],
                hasSuccessfulEntitySave: false,
                customer_token: '',
                readyForApproval: false,
                frontingProhibited: true,
                nextInterestPaymentDate: updated_loan?.nextInterestPaymentDate ?? null,
                nextPIKDate: updated_loan?.nextPIKDate ?? null
            },

            transformValues: values => {
                const borrowerPaymentInstructions = wireInstructionList.find(
                    (wireInstruction: any) =>
                        values.borrowerPaymentInstructions == wireInstruction.id
                )
                const borrower: EntityParams | undefined = entities.find(
                    entity => entity.id == form.values.borrower
                )

                const indexOption = allIndexes.find(
                    option => option.id === values.indexOption
                )
                return {
                    ...values,
                    startDate: formatDateToUTC(values.startDate),
                    endDate: formatDateToUTC(values.endDate as Date),
                    borrower: borrower ? { id: borrower.id, admin: borrower.maker } : null,
                    borrowerPaymentInstructions: borrowerPaymentInstructions
                        ? {
                            id: borrowerPaymentInstructions.id,
                            admin: borrowerPaymentInstructions.public,
                        }
                        : null,
                    facilityId: {
                        id: facility?.id,
                        admin: facility?.accountManagementAdmin,
                    },
                    isSwingLine: values.isSwingLine == 'false' ? false : true,
                    isNonProRata: values.isNonProRata == 'false' ? false : true,
                    lendersPositions: [],
                    rounding: values.rounding != '' ? values.rounding : null,
                    dayBasis: values.dayBasis != '' ? values.dayBasis : null,
                    indexOption: {
                        id: indexOption?.id ?? '',
                        admin: indexOption?.public ?? '',
                    },
                    contractPeriod:
                        values.contractPeriod != '' ? values.contractPeriod : null,
                    allInRate: values.allInRate !== 0.0 ? values.allInRate : null,
                    interestBaseRate:
                        values.interestBaseRate !== 0.0 ? values.interestBaseRate : null,
                    interestBaseRateWithRounding:
                        values.interestBaseRateWithRounding !== 0.0
                            ? Number(values.interestBaseRateWithRounding)
                            : null,
                }
            },

            validate: {
                currency: isNotEmpty('Currency cannot be empty'),
                facilityId: isNotEmpty('Facility cannot be empty'),
                amount: (value: number, values: any) => {
                    if (value <= 0.0) {
                        return 'Invalid amount'
                    }
                    if (!facility) {
                        return null
                    }
                    if (value > Number(facility?.amount)) {
                        return 'Amount cannot be greater than facility amount'
                    }
                    if (values.isSwingLine == 'false') {
                        return null
                    }
                    if (!facility.isSublimit) {
                        return null
                    }
                    const swingline = facility?.subLimits.find(
                        (sublimit: { subLimitsType: string }) => sublimit.subLimitsType === 'SwingLine'
                    )
                    if (!swingline) {
                        return null
                    }
                    if (value > Number(swingline.amount)) {
                        return 'Amount cannot be greater than swingline amount'
                    }
                    return null
                },
                endDate: (value) => {
                    if (!value) {
                        return 'Maturity Date Must Be Set'
                    }
                    if (facility && value && value > stringToDate(facility?.maturity)) {
                        return 'Date must be less than Facility Maturity Date'
                    }
                    return null
                },
            },
            validateInputOnBlur: true,
        })

        return (
            <div className="create-new">
                <UpdatedLoanModal
                    facility={facility}
                    loan={updated_loan}
                    form={form}
                    ref={childRef}
                    setSaveButtonDisabled={setIsSaveButtonDisabled}
                    setLoan={setLoan}
                />
                <div
                    className="btn-group"
                    style={{
                        marginTop: 50,
                        textAlign: 'right',
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="33%"
                        disabled={isSaveButtonDisabled}
                    >
                        Save and Exit
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndNext()}
                        className="form-button"
                        w="33%"
                        disabled={isSaveButtonDisabled}
                    >
                        Save and Proceed
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="33%"
                    >
                        Exit
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const LenderAllocationContent: React.FC<CustomStepContentProps> = ({
        onNext,
        onPrevious,
    }) => {
        const childRef = useRef<ChildManageLendersRef>(null)

        const handleSaveFacilityAndExit = async () => {
            if (childRef.current) {
                await childRef.current.handleClick()
                onClose()
            }
        }

        const handlePrevious = () => {
            if (typeof onNext === 'function') {
                onPrevious()
            }
        }

        return (
            <div className="create-new">
                <ManageLoanLenders
                    loan={updated_loan as LoanParams}
                    ref={childRef}
                />
                <div
                    className="btn-group"
                    style={{ marginTop: 10, display: 'flex', gap: '10px' }}
                >
                    <PrimaryButton
                        onClick={() => handleSaveFacilityAndExit()}
                        className="form-button"
                        w="25%"
                    >
                        Save and Exit
                    </PrimaryButton>

                    <PrimaryButton
                        onClick={() => handlePrevious()}
                        className="form-button"
                        w="25%"
                    >
                        Previous
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => onClose()}
                        className="form-button"
                        w="25%"
                    >
                        Close
                    </PrimaryButton>
                </div>
            </div>
        )
    }


    const steps = [
        {
            label: '1. Loan',
            content: (
                <LoanContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
        {
            label: 'i. Lender Allocation',
            content: (
                <LenderAllocationContent
                    onNext={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                    onPrevious={function (): void {
                        throw new Error('Function not implemented.')
                    }}
                />
            ),
        },
    ]

    return (
        <>
            {!updated_loan || Object.keys(updated_loan).length === 0 ? (
                //create
                <VerticalStepper
                    steps={steps}
                    disabledSteps={[1]}
                    step={step}
                />
            ) : (
                // edit
                <VerticalStepper
                    steps={steps}
                    disabledSteps={[]}
                    step={step}
                />
            )}
        </>
    )
}
