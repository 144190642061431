/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLockedPayments, getPayments } from 'app/state/ducks/payments/selectors'
import Table2 from 'app/views/components/Table2/Table2'
import { cols } from './release-queue-columnDefs'
import { loadActivePayments, loadLockedPayments } from 'app/state/ducks/payments/thunks'
import { PaymentParams } from 'app/models/payments-params'
import { MRT_ColumnDef } from 'mantine-react-table'
import ReleaseQueueDrawer from './drawer/drawer'
import { Group, Stack } from '@mantine/core'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './release-queue-kpi-data'
import SegmentControl from 'app/views/components/segments/segment-control'
import { lockedTabs, tabs } from './release-segment-data'

interface props {
    tab?: string | null | undefined
}

export function ReleaseQueue({ tab }: props) {
    const [row, setRow] = useState<PaymentParams>()
    const [open, setOpen] = useState<boolean>(false)
    const [columnDefs, setColumnDefs] = useState<MRT_ColumnDef<PaymentParams>[]>(
        []
    )
    const [payments, setPayments] = useState<PaymentParams[]>([])
    const activePayments: PaymentParams[] = useSelector(getPayments)
    const lockedPayments: PaymentParams[] = useSelector(getLockedPayments)
    const dispatch = useDispatch()

    const [filteredPayments, setFilteredPayments] = useState<PaymentParams[]>(payments)
    const kpiData = getKPIData(payments, setFilteredPayments)

    useEffect(() => {
        if (tab === 'archived') {
            dispatch(loadLockedPayments())
        } else {
            dispatch(loadActivePayments())
        }
    }, [tab])

    useEffect(() => {
        if (tab === 'archived') {
            setPayments(lockedPayments)
        } else {
            setPayments(activePayments)
        }
    }, [tab, activePayments, lockedPayments])

    useEffect(() => {
        setColumnDefs(cols())
    }, [])

    function compare(a: PaymentParams, b: PaymentParams) {
        const weight = new Map([
            ['Prepayment', 1],
            ['Repayment', 2],
            ['Notice', 3],
            ['PrincipalPaymentTransaction', 4],
            ['PrincipalWithInterestPaymentTransaction', 5],
            ['InterestPaymentTransaction', 6],
            ['NewBorrowingTransaction', 7],
            ['Facility Fees', 8],
        ])

        const aWeight = weight.get(a.payment.transactionType) ?? 0
        const bWeight = weight.get(b.payment.transactionType) ?? 0
        if (aWeight > bWeight) {
            return 1
        }
        if (aWeight < bWeight) {
            return -1
        }
        return 0
    }

    useEffect(() => {
        const pay = payments || []
        setFilteredPayments(pay.sort((a, b) => compare(a, b)))
    }, [payments])

    function handleRowClicked(e: any) {
        setRow(e)
        setOpen(true)
    }

    const activeTabs = {
        tab: tabs.payments,
        locked: tab === 'archived' ? lockedTabs.archived : lockedTabs.active,
    }

    return (
        <Stack>
            <KPI_Section
                kpiData={kpiData}
            />
            <Group position="right">
                <SegmentControl activeTabs={activeTabs} tabs={tabs} lockedTabs={lockedTabs} />
            </Group>
            {columnDefs.length != 0 ? (
                <Table2
                    columnDefs={columnDefs}
                    data={filteredPayments.sort((a, b) => a.payment.effectiveDate.localeCompare(b.payment.effectiveDate))}
                    setRow={handleRowClicked}
                />
            ) : null}
            <ReleaseQueueDrawer open={open} setOpen={setOpen} row={row} />
        </Stack>
    )
}