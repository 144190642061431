import * as types from './types'

export const getTodos = (payload: any) => ({
  type: types.GET_TODOS,
  payload,
})

export const getTodo = (payload: any) => ({
  type: types.GET_TODO,
  payload,
})

export const addTodo = (payload: any) => ({
  type: types.ADD_TODOS,
  payload,
})

export const editTodo = (payload: any) => ({
  type: types.EDIT_TODOS,
  payload,
})

export const deleteTodos = (payload: any) => ({
  type: types.DELETE_TODOS,
  payload,
})
