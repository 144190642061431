import { Accordion, ScrollArea } from '@mantine/core'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { useEffect } from 'react'
import OneCurrency from './one-currency'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useDispatch } from 'react-redux'
import { updateLendersPosition } from 'app/state/ducks/lenders-positions/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { useSetState } from '@mantine/hooks'

interface Props {
  lendersPosition: any
  wireInstructions: WireInstructionParams[]
  closeDrawer: any
}

export function WireInstructionsAccordion({
  wireInstructions,
  lendersPosition,
  closeDrawer,
}: Props) {
  const dispatch = useDispatch()


  const [selectedWireInstructions, setSelectedWireInstructions] = useSetState({})


  useEffect(() => {
    lendersPosition.wireInstructions.forEach(({ id }: any) => {
      const wireInstruction = wireInstructions.find(instruction => instruction.id === id);

      if (wireInstruction) {
        setSelectedWireInstructions({ [wireInstruction.currency]: wireInstruction })
      }
    });

  }, [lendersPosition, wireInstructions]);

  const changeWireInstruction = (
    currency: string,
    selectedWire: WireInstructionParams | undefined
  ) => {
    setSelectedWireInstructions({ [currency]: selectedWire })
  }

  const saveWireInstructions = async () => {
    lendersPosition.wireInstructions = [
      ...Object.values(selectedWireInstructions),
    ]
      .filter(wireInstructions => wireInstructions)
      .map((wireInstruction: any) => ({
        id: wireInstruction?.id ?? '',
        admin: wireInstruction?.public ?? '',
      }))
    const response: any = await dispatch(updateLendersPosition(lendersPosition))

    if (!response.success) {
      ErrorNotification({
        title: 'Lender Position Updated Failed',
        message: response?.payload ?? 'Check your input and try again',
      })
      return
    }
    SuccessNotification({
      title: 'Wire Instructions Updated',
      message: 'You can also set admin and credit contact',
    })
    closeDrawer()
  }

  return (
    <>
      <ScrollArea>
        <div style={{ flexGrow: 1 }}>
          <Accordion
            multiple
            defaultValue={[
              ...new Set(
                wireInstructions.map(instruction => instruction.currency)
              ),
            ]}
            variant="contained"
            styles={{
              control: {
                fontFamily: 'Plus Jakarta Sans',
                '&[data-active]': {
                  backgroundColor: '#A4CAFE',
                },
              },
            }}
          >
            {[
              ...new Set(
                wireInstructions.map(instruction => instruction.currency)
              ),
            ].map(currency => (
              <OneCurrency
                selectedWireInstructions={selectedWireInstructions}
                key={currency}
                lenderPosition={lendersPosition}
                currency={currency}
                setSelectedPaymentInstructions={changeWireInstruction}
                allWireInstructions={wireInstructions.filter(
                  wi => wi.currency == currency
                )}
              />
            ))}
          </Accordion>
        </div>
      </ScrollArea>
      <div style={{ textAlign: 'right', padding: '16px' }}>
        <PrimaryButton text="Save" onClick={() => saveWireInstructions()} />
      </div>
    </>
  )
}
