import React from 'react'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { colsMyWork } from './mywork-util/mywork-columnDefs'
import {
  MyWorkDataParams,
} from 'app/models/mywork-data-params'
import {
  paymentsFakeTable,
} from './mywork-util/mywork-fake-data'
import { useListState } from '@mantine/hooks'
import { MyWorkSummary } from './mywork_summary'
import { Stack } from '@mantine/core'
import { calcTotalAmount } from './mywork-util/my-work-utils'
import { formatNumberToCurrency } from 'app/utils/util-functions'

interface props {
  date: Date
}

export function PaymentsPage({ date }: props) {
  const [paymentsTable] = useListState(paymentsFakeTable)

  const paymentsTableFiltered = paymentsTable.filter(a => {
    const effectiveDate = new Date(a.effectiveDate);
    // Compare the year, month, and day of both dates
    return (
      date.getFullYear() === effectiveDate.getFullYear() &&
      date.getMonth() === effectiveDate.getMonth() &&
      date.getDate() === effectiveDate.getDate()
    );
  });

  const activityCount = paymentsTableFiltered.length.toString()
  const percentageComplete = '10'
  const deadline = paymentsTableFiltered[0]?.serviceTime?.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }) ?? 'N/A'
  const totalAmount = formatNumberToCurrency(calcTotalAmount(paymentsTableFiltered?.map(item => item?.amount ?? 0)) ?? 0, 'USD')?.toString() ?? 'N/A'

  return (
    <>
      <Stack w="100%">
        <MyWorkSummary activityCount={activityCount} percentageComplete={percentageComplete + '%'} deadlineApproaching={deadline} totalActivityAmount={totalAmount} />
        <TableColumnClickExclusion
          tableName='Payments Table'
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          overflow="hidden"
          minHeight="0px"
          columnDefs={colsMyWork}
          data={paymentsTableFiltered}
          setRow={(e: MyWorkDataParams, cellName: string) => {
            if (cellName != 'id') {
              console.log('row clicked', e)
            }
          }}
        />
      </Stack>
    </>
  )
}
