import { useState } from 'react'
import { DealsModal } from 'app/views/pages/deals-management/deals/deals-modal'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'

function CreateDealButton() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <PrimaryButton onClick={() => setOpen(true)} text="Create Deal" />
      <DealsModal open={open} setOpen={setOpen} />
    </>
  )
}

export default CreateDealButton
