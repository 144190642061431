import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getEntities,
  getEntity,
  addEntity,
  editEntity,
  deleteEntities,
  activeEntity,
  inactiveEntity,
} from 'app/state/ducks/entities/actions'
import { EntityParams } from 'app/models/entity-params'

export const saveEntity =
  (savedEntity: EntityParams, entity?: EntityParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = savedEntity.status.toLowerCase() || 'inactive'
    if (!savedEntity.entityParty) savedEntity.entityParty = entity?.entityParty

    if (entity) {
      return client
        .put(`${apiServerUrl}/api/${statusLower}-entities`, savedEntity)
        .then(entity => {
          const payload = dispatch(editEntity(entity))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
    delete savedEntity.id
    return client
      .post(`${apiServerUrl}/api/${statusLower}-entities`, savedEntity)
      .then(entity => {
        entity.data.status = statusLower
        const payload = dispatch(addEntity(entity))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

const loadInactiveEntities = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/inactive-entities`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadActiveEntities = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/active-entities`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadEntities = () => async (dispatch: Dispatch) => {
  const inactiveEntities = await loadInactiveEntities()
  const activeEntities = await loadActiveEntities()
  const allEntities = inactiveEntities
    .map((entity: any) => ({ ...entity, status: 'Inactive' }))
    .concat(
      activeEntities.map((entity: any) => ({ ...entity, status: 'Active' })),
    )
  return dispatch(getEntities(allEntities))
}

export const loadOneEntity =
  (entityId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-entities/one?id=${entityId}`)
      .then(entity => {
        return dispatch(getEntity({ ...entity.data, status: status }))
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const removeEntities = (entity: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/inactive-entities?ids[]=${entity}`)
    .then(entities => {
      return dispatch(deleteEntities(entities.data))
    })
    .catch(e => {
      return { payload: e.response.data.error, success: false }
    })
}

export const activateEntity =
  (entity: EntityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/inactive-entities/activate`, { id: entity.id })
      .then(response => {
        dispatch(activeEntity(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deactivateEntity =
  (entity: EntityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/active-entities/deactivate`, { id: entity.id })
      .then(response => {
        dispatch(inactiveEntity(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
