import { AssignmentParams } from 'app/models/assignment-params'

export const getAssignments = (state: {
  assignments: { assignments: AssignmentParams[] }
}) => state.assignments.assignments

export const getAssignment = (state: {
  assignments: { assignment: AssignmentParams }
}) => {
  return state.assignments.assignment
}
