import { ContactParams } from 'app/models/contact-params'

export const getContacts = (state: {
  contacts: { contacts: ContactParams[] }
}) => {
  return state.contacts.contacts
}

export const getContact = (state: { contacts: { contact: ContactParams } }) => {
  return state.contacts.contact
}
