import { Group, SegmentedControl, Text, Stack, SegmentedControlItem, Switch } from '@mantine/core';
import { camelCaseToHumanReadable } from 'app/utils/util-functions';
import { useNavigate } from 'react-router-dom';

type Props = {
  tabs: {
    deals?: string,
    facilities?: string,
    all?: string,
    scheduled?: string,
    unscheduled?: string,
    payments?: string,
    notices?: string,
    allTrades?: string,
    parent?: string,
    subTade?: string,
    facility?: (id: string, status: string, contractType: string) => string,
    loans?: (id: string, status: string, contractType: string) => string,
    lettersOfCredit?: (id: string, status: string, contractType: string) => string,
    entity?: (id: string, status: string, contractType: string) => string,
    contacts?: (id: string, status: string, contractType: string) => string,
    wireinstructions?: (id: string, status: string, contractType: string) => string,
    positions?: (id: string, status: string, contractType: string) => string,
    funds?: (id: string, status: string, contractType: string) => string,
    fundsManager?: (id: string, status: string, contractType: string) => string,
  },
  lockedTabs: {
    active?: string,
    terminated?: string,
  },
  activeTabs: {
    tab: string | ((id: string, status: string) => string) | ((id: string, status: string, contractType: string) => string) | undefined,
    locked?: string,
  },
  id?: string,
  status?: string,
  contractType?: string,
  showInactiveLoans?: boolean,
  onToggleShowInactiveLoans?: (showInactiveLoans: boolean) => void,
};

function SegmentControl({ tabs, lockedTabs, activeTabs, id, status, contractType, showInactiveLoans, onToggleShowInactiveLoans }: Props) {
  const navigate = useNavigate();


  // Prepare the data for the SegmentedControl
  const tabData: SegmentedControlItem[] = Object.entries(tabs).map(([key, value]) => {
    const resolvedValue = typeof value === 'function' ? value?.(id ?? '', status ?? '', contractType ?? "") : value ?? '';
    return {
      value: resolvedValue,
      label: camelCaseToHumanReadable(key),
    };
  });

  const lockedTabsData: SegmentedControlItem[] = Object.entries(lockedTabs).map(([key, value]) => ({
    value: value ?? '',
    label: camelCaseToHumanReadable(key),
  }));

  // Determine the currently active tab URL
  let activeTabUrl = activeTabs.tab;

  if (Object.prototype.hasOwnProperty.call(activeTabs, 'tab')) {
    if (typeof activeTabs.tab === 'function') {
      try {
        activeTabUrl = activeTabs.tab(id ?? '', status ?? '', contractType ?? "");
      } catch (error) {
        console.error("Error invoking tab function:", error);
        activeTabUrl = '';
      }
    } else if (typeof activeTabs.tab === 'string') {
      activeTabUrl = activeTabs.tab;
    } else {
      activeTabUrl = '';
    }
  } else {
    activeTabUrl = '';
  }

  function changeTab(e: string): void {
    navigate(e + (activeTabs.locked || ''));
  }

  function changeLockedTab(e: string): void {
    navigate(activeTabs.tab + e);
  }


  const isLoansTabActive = activeTabUrl.endsWith('/loans');

  function handleToggleShowInactiveLoans(event: React.ChangeEvent<HTMLInputElement>) {
    onToggleShowInactiveLoans?.(event.currentTarget.checked);
  }

  return (
    <Stack align="stretch" w="100%">
      <Group noWrap w="100%" grow >
        <Group noWrap w="100%">
          <Text
            fz="14px"
            fw={400}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            Show:
          </Text>
          <Group w="100%" style={{ flexGrow: 1, justifyContent: "space-between" }}>
            <SegmentedControl
              size="lg"
              onChange={(e: string) => changeTab(e)}
              value={activeTabUrl.toString()}
              data={tabData}
              styles={{
                controlActive: {
                  backgroundColor: '#A4CAFE',
                },
                label: {
                  fontSize: '12px',
                },
              }}
            />
            <SegmentedControl
              size="lg"
              onChange={(e: string) => changeLockedTab(e)}
              value={activeTabs.locked}
              data={lockedTabsData}
              styles={{
                controlActive: {
                  backgroundColor: '#A4CAFE',
                },
                label: {
                  fontSize: '12px',
                },
              }}
            />


            {isLoansTabActive && (
              <Switch
                label="Show Inactive"
                checked={showInactiveLoans}
                onChange={handleToggleShowInactiveLoans}
                styles={{
                  root: {
                    paddingRight: "0.25em",
                  },
                  label: {
                    fontSize: '1.5em',
                  },
                }}
              />
            )}
          </Group>
        </Group>
      </Group>
    </Stack>
  );
}

export default SegmentControl;
