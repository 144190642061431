import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { PageLayout } from 'app/views/components/layout/page-layout'
import FacilityPageHeaderSection from './facility-page-header'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadCalendars } from 'app/state/ducks/holiday-calendars/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import FacilitiesContentPage from './facilities-content-page'
export function FacilityPage() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadCalendars())
    dispatch(loadWireInstructions())
    dispatch(loadIndexRateOptions())
    dispatch(loadAllFacilities())
    dispatch(loadAllFacilityAmendments())
  }, [])

  return (
    <PageLayout>
      <FacilityPageHeaderSection />
      <FacilitiesContentPage />
    </PageLayout>
  )
}
