import { FacilityParams } from 'app/models/facility-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

interface FacilityAmendmentState {
  facilityAmendments: FacilityParams[]
  facilityAmendment: FacilityParams | null
}

const initialState: FacilityAmendmentState = {
  facilityAmendments: [],
  facilityAmendment: null,
}

const facilityAmendmentsReducer = createReducer(initialState)({
  [types.GET_FACILITY_AMENDMENTS]: (state: any, payload: any) => {
    return { ...state, facilityAmendments: payload.payload }
  },
  [types.GET_FACILITY_AMENDMENT]: (state: any, payload: any) => {
    return { ...state, facilityAmendment: payload.payload }
  },
  [types.NULL_FACILITY_AMENDMENT]: (state: any) => {
    return { ...state, facilityAmendment: null }
  },
  [types.CHANGE_FACILITY_FEES_AMENDMENT]: (state: any, payload: any) => {
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]

    if(index === -1){
      newArray.push({...payload.payload, status: 'Draft'})
    } else{
      newArray[index] = { ...payload.payload, status: 'Draft' }
    }
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.CHANGE_FACILITY_AMORTIZATION_AMENDMENT]: (state: any, payload: any) => {
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]

    if(index === -1){
      newArray.push({...payload.payload, status: 'Draft'})
    } else{
      newArray[index] = { ...payload.payload, status: 'Draft' }
    }
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.ADD_FACILITY_AMENDMENTS]: (state: any, payload: any) => {
    return {
      ...state,
      facilityAmendments: [
        { ...payload.payload, status: 'Draft' },
        ...state.facilityAmendments,
      ],
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.EDIT_FACILITY_AMENDMENTS]: (state: any, payload: any) => {
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: { ...payload.payload, status: 'Draft' },
    }
  },
  [types.APPROVE_FACILITY_AMENDMENT]: (state: any, payload: any) => {
    const newFacilityAmendment = state.facilityAmendment?.id === payload.payload.id ? null : state.facilityAmendment
    const index = state.facilityAmendments.findIndex(
      (facilityAmendment: { id: any }) =>
        facilityAmendment.id === payload.payload.id
    )
    const newArray = [...state.facilityAmendments]
    newArray.splice(index, 1)
    return {
      ...state,
      facilityAmendments: newArray,
      facilityAmendment: newFacilityAmendment,
    }
  }
})

export default facilityAmendmentsReducer
