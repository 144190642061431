import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { wireInstructionCols } from './wire-instructions-columnDefs'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { Stack } from '@mantine/core'
import { WireInstructionsModal } from '../../wire-instruction-management/wire-instructions/modal-wire-instructions'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'

interface Props {
  entityId?: string
}

export default function ManageEntityWireInstructions({ entityId }: Props) {
  const dispatch = useDispatch()
  const wireInstructions: WireInstructionParams[] =
    useSelector(getWireInstructions)
  const [addWireInstructions, setAddWireInstructions] = useState<boolean>(false)
  const [wireInstruction, setWireInstruction] = useState<
    undefined | WireInstructionParams
  >(undefined)

  useEffect(() => {
    dispatch(loadWireInstructions())
  }, [])

  if (!entityId) {
    return <></>
  }

  return (
    <Stack w="100%">
      {wireInstructions ? (
        <TableColumnClickExclusion
          tableName='Wire_Instructions Table'
          data={wireInstructions.filter(
            p => cleanUUID(p?.entity?.id) === entityId
          ).sort((a, b) => a.entityName.localeCompare(b.entityName)) ?? []}
          columnDefs={wireInstructionCols}
          setRow={(e: WireInstructionParams, cellName: string) => {
            if (cellName != 'id') {
              setWireInstruction(e)
              setAddWireInstructions(true)
            }
          }}
        />
      ) : null}

      <WireInstructionsModal
        setOpen={setAddWireInstructions}
        open={addWireInstructions}
        paymentInstruction={wireInstruction}
      />
    </Stack>
  )
}
