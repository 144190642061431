import React, { useMemo } from 'react'
import { MantineReactTable } from 'mantine-react-table'
import type { MRT_ColumnDef } from 'mantine-react-table'
import IonIcon from '@reacticons/ionicons'

type Props<T extends Record<string, T>> = {
  columnDefs: MRT_ColumnDef<T>[]
  data?: T[]
  setRow: (e: T) => void
  enableColumnOrdering?: boolean
  enableGrouping?: boolean
  enableColumnFilters?: boolean
  enableColumnActions?: boolean
  enableFilters?: boolean
  enableSorting?: boolean
  enableFullScreenToggle?: boolean,
  withPagination?: boolean
}

export default function Table2<T extends Record<string, any>>({
  columnDefs,
  data,
  setRow,
  enableColumnOrdering,
  enableGrouping,
  enableColumnFilters,
  enableColumnActions,
  enableFilters,
  enableSorting,
  enableFullScreenToggle,
  withPagination = true,
}: Props<T>) {
  const columns = useMemo<MRT_ColumnDef<T>[]>(() => columnDefs, [])

  return (
    <MantineReactTable
      defaultColumn={{
        maxSize: 400,
        minSize: 50,
        size: 150,
      }}
      enableColumnResizing
      columns={columns}
      data={data ?? []}
      mantineTableBodyProps={{
        sx: {
          //stripe the rows, make odd rows a darker color
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5',
          },
        },
      }}
      mantineBottomToolbarProps={{
        mih: "5.5rem"
      }}
      enableColumnOrdering={enableColumnOrdering ?? true}
      enableGrouping={enableGrouping ?? true}
      enableColumnFilters={enableColumnFilters ?? true}
      enableColumnActions={enableColumnActions ?? true}
      enableFilters={enableFilters ?? true}
      enableSorting={enableSorting ?? true}
      enableFullScreenToggle={enableFullScreenToggle ?? true}
      enableStickyHeader
      mantineTableContainerProps={{
        sx: {
          minHeight: '250px',
          maxWidth: '100%',
        },
      }}
      mantineTopToolbarProps={{
        sx: {
          maxWidth: '100%',
        }
      }}
      mantineFilterDateInputProps={{
        firstDayOfWeek: 0,
      }}
      mantineTableBodyRowProps={({
        row: { original },
      }: {
        row: { original: T }
      }) => ({
        onClick: () => {
          setRow(original)
        },
      })}
      icons={{
        IconDotsVertical: () => <IonIcon name='funnel-outline' />,
      }}
      // PAGINATION
      enableBottomToolbar={withPagination}
      enablePagination={withPagination}
      autoResetPageIndex={true}
      paginationDisplayMode='pages'
      mantinePaginationProps={{
        rowsPerPageOptions: ["10", "15", "20", "25"],
        size: 'xl',
        radius: 'xl',
        siblings: 1,
        withEdges: false,
      }}
      initialState={{ pagination: { pageSize: 25, pageIndex: 0 } }}
    />
  )
}
