import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteEntity,
  activeEntity,
  makeInactiveEntity,
} from './entity-daml-actions'
import { EntityParams } from 'app/models/entity-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { useNavigate } from 'react-router-dom'
import { ContactsModal } from '../contacts/contacts-modal'
import { WireInstructionsModal } from '../wire-instruction-management/wire-instructions/modal-wire-instructions'
import IonIcon from '@reacticons/ionicons'
import MultiPartForm from 'app/views/pages/accounts-management/entities/multipart-form-modal'
import { ContactParams } from 'app/models/contact-params'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'


type Props = {
  row: EntityParams
  withRedirection?: boolean
  extended?: boolean
  primaryInput?: boolean
}

export default function EntityActions({
  row,
  withRedirection,
  extended,
  primaryInput,
}: Props) {
  const [entity, setEntity] = useState<EntityParams | undefined>(row)
  const [addContact, setAddContact] = useState<boolean>(false)
  const [addPaymentInstructions, setAddPaymentInstructions] =
    useState<boolean>(false)
  const entities: EntityParams[] = useSelector(getEntities)
  const contacts: ContactParams[] = useSelector(getContacts)
  const wireInstructions: WireInstructionParams[] = useSelector(getWireInstructions)
  const wireInstructionsForEntity = wireInstructions.filter(
    instruction => cleanUUID(instruction.entity.id) == entity?.id
  )
  const contactsForEntity = contacts?.filter(
    p => cleanUUID(p?.entity?.id) === entity?.id
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [openEntity, setOpenEntity] = useState(false)

  useEffect(() => {
    setEntity(row)
  }, [row])

  function editEntity(entityId: string) {
    setEntity(entities.find(f => f.id == entityId))
    setOpenEntity(true)
  }

  async function activate(): Promise<void> {
    const result = await activeEntity(row, dispatch)
    if (result && withRedirection) {
      navigate(`/entitymanagement/entity/${row.id}/Active`)
    }
  }
  async function deactivate(): Promise<void> {
    const result = await makeInactiveEntity(row, dispatch)
    if (result && withRedirection) {
      navigate(`/entitymanagement/entity/${row.id}/Inactive`)
    }
  }

  return (
    <div>
      <MultiPartForm setOpen={setOpenEntity} open={openEntity} entityToEdit={entity} contactsToEdit={contactsForEntity} wireInstructionsToEdit={wireInstructionsForEntity} />
      <ContactsModal
        open={addContact}
        setOpen={setAddContact}
        entityId={entity?.id}
      />
      <WireInstructionsModal
        setOpen={setAddPaymentInstructions}
        open={addPaymentInstructions}
        entityId={entity?.id}
      />
      <Menu shadow="md" width={primaryInput ? '250px' : 200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            {primaryInput ?
              <PrimaryInput w='159px'>
                Actions
                <IonIcon name='chevron-down-outline' className='primaryInputChevronDown' />
              </PrimaryInput>
              :
              <PrimaryDropDown>Actions</PrimaryDropDown>
            }
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
            onClick={() => editEntity(row.id as string)}
          >
            Edit
          </Menu.Item>

          {row?.status?.toLowerCase() == 'inactive' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => activate()}
            >
              Activate
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => deactivate()}
            >
              Deactivate
            </Menu.Item>
          )}
          {extended ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                onClick={() => setAddContact(true)}
              >
                Add Contact
              </Menu.Item>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                onClick={() => setAddPaymentInstructions(true)}
              >
                Add Wire Instruction
              </Menu.Item>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'inactive' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => {
                deleteEntity(row, dispatch)
              }}
            >
              Delete
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}