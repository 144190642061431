import * as types from './types'

export const getDeals = (payload: any) => ({
  type: types.GET_DEALS,
  payload,
})

export const getDeal = (payload: any) => ({
  type: types.GET_DEAL,
  payload,
})

export const addDeal = (payload: any) => ({
  type: types.ADD_DEALS,
  payload,
})

export const editDeal = (payload: any) => ({
  type: types.EDIT_DEALS,
  payload,
})

export const submitDeal = (payload: any) => ({
  type: types.SUBMIT_DEAL,
  payload,
})

export const rejectDeal = (payload: any) => ({
  type: types.REJECT_DEAL,
  payload,
})

export const approveDeal = (payload: any) => ({
  type: types.APPROVE_DEAL,
  payload,
})

export const deleteDeal = (payload: any) => ({
  type: types.DELETE_DEAL,
  payload,
})

export const getDealHistory = (payload: any) => ({
  type: types.GET_DEAL_HISTORY,
  payload,
})

export const getLockedDeals = (payload: any) => ({
  type: types.GET_LOCKED_DEALS,
  payload,
})
