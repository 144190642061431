import {
  getAmount,
  getPercentage,
} from 'app/state/ducks/lenders-positions/thunks'
import { CleanedFundManagerData, FundManagerData, LendersPosition } from 'app/models/lenders-position-params'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { Stack } from '@mantine/core'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { getColumnDefs } from './table-columnDefs-fund-manager'

interface Params {
  effectiveDate: Date
  currency: string
  lendersPositions: LendersPosition[]
}

export default function FundManagerLenderTable({ effectiveDate, currency, lendersPositions }: Params) {

  const entities = useSelector(getEntities)
  const [lenderListData, setLenderListData] = useState(new Map())
  const [totalAmount, setTotalAmount] = useState('');
  const totalAmountRef = useRef(totalAmount);

  useEffect(() => {
    const fundManagerMap = new Map()
    lendersPositions.forEach((lp: LendersPosition) => {
      const entity = entities?.find(item => item.id == lp.lender.id)
      const fmParent = entity?.fundManager ?? entity?.parent ?? 'Missing FM/Parent'
      const amount = getAmount(lp, effectiveDate)
      const percentage = getPercentage(lp, effectiveDate)
      const mei = entity?.fundManagerMei ?? entity?.parentMei ?? entity?.mei ?? ''

      if (!fundManagerMap.get(fmParent)) {
        fundManagerMap.set(fmParent, { amount, percentage, mei })
      }
      else {
        fundManagerMap.set(fmParent, {
          amount: fundManagerMap.get(fmParent).amount + amount,
          percentage: fundManagerMap.get(fmParent).percentage + percentage,
          mei: fundManagerMap.get(fmParent).mei
        })
      }

    })
    setLenderListData(fundManagerMap)

  }, [lendersPositions])


  function cleanFundManagers(
    lenderListData: Map<string, FundManagerData>,
  ): CleanedFundManagerData[] {
    return (
      [...lenderListData.keys()].map((fundManager) => {
        const lenderData = lenderListData.get(fundManager)
        return {
          'Fund Manager / Parent': fundManager,
          'Allocation': formatNumberToCurrency(Number(lenderData?.amount ?? 0), currency),
          'MEI': lenderData?.mei ?? '',
          'Percentage': (lenderData?.percentage ?? '0') + '%',
        }
      }) ?? []
    );
  }

  useEffect(() => {
    totalAmountRef.current = totalAmount;
  }, [totalAmount]);

  const getTotalAmount = useCallback(() => totalAmountRef.current, []);

  useEffect(() => {
    const totalAmount =
      formatNumberToCurrency(
        lendersPositions
          ? lendersPositions.reduce(
            (acc: number, curr: LendersPosition) => acc + getAmount(curr, effectiveDate),
            0
          )
          : 0,
        currency ?? 'USD'
      )
    setTotalAmount(totalAmount)
  }, [lendersPositions])

  return (
    <Stack w="100%">
      <TableColumnClickExclusion
        tableName='Lender History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs(getTotalAmount)}
        data={cleanFundManagers(lenderListData)}
        setRow={() => {
        }}
      />
    </Stack>

  )
}