import { PaymentParams } from 'app/models/payments-params'
import { KpiProps } from 'app/views/components/KPI/KPI'
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters'

export const getKPIData = (
  data: PaymentParams[],
  setData: React.Dispatch<React.SetStateAction<PaymentParams[]>>,
): KpiProps[] => {
  const filterFunctions: Record<string, (payment: PaymentParams) => boolean> = {
    'Repayment or Prepayment': (payment: PaymentParams) =>
      ['Repayment', 'Prepayment'].includes(payment.payment.transactionType),
    'Principal Payments': (payment: PaymentParams) =>
      payment.payment.transactionType.includes('Principal'),
    'New Borrowings': (payment: PaymentParams) =>
      payment.payment.transactionType.includes('NewBorrowing'),
    'Facility Fees': (payment: PaymentParams) =>
      payment.payment.transactionType === 'Facility Fees',
    'Fronted Payments': (payment: PaymentParams) =>
      payment.payment.fronting === true,
  }

  const { applyFilter, appliedFilters } = useKPIFilters({
    data,
    filterFunctions,
    setData,
  })

  if (!data) return []

  const repaymentOrPrepayment = data.filter(row =>
    ['Repayment', 'Prepayment'].includes(row.payment.transactionType),
  )
  const principalPayments = data.filter(row =>
    row.payment.transactionType.includes('Principal'),
  )
  const newBorrowings = data.filter(row =>
    row.payment.transactionType.includes('NewBorrowing'),
  )
  const facilityFees = data.filter(
    row => row.payment.transactionType === 'Facility Fees',
  )
  const frontedPayments = data.filter(row => row.payment.fronting)

  return [
    {
      title: 'Payments Today',
      amount: repaymentOrPrepayment.length,
      color: 0,
      activeFilter: appliedFilters.has('Repayment or Prepayment'),
      event: () => applyFilter('Repayment or Prepayment'),
    },
    {
      title: 'Principal Payments',
      amount: principalPayments.length,
      color: 1,
      activeFilter: appliedFilters.has('Principal Payments'),
      event: () => applyFilter('Principal Payments'),
    },
    {
      title: 'New Borrowings',
      amount: newBorrowings.length,
      color: 2,
      activeFilter: appliedFilters.has('New Borrowings'),
      event: () => applyFilter('New Borrowings'),
    },
    {
      title: 'Facility Fees',
      amount: facilityFees.length,
      color: 3,
      activeFilter: appliedFilters.has('Facility Fees'),
      event: () => applyFilter('Facility Fees'),
    },
    {
      title: 'Fronted Payments',
      amount: frontedPayments.length,
      color: 4,
      activeFilter: appliedFilters.has('Fronted Payments'),
      event: () => applyFilter('Fronted Payments'),
    },
  ]
}
