import { HolidayCalendarParams } from "app/models/holiday-calendar-params"

export const getCalendars = (state: {
  calendars: { calendars: HolidayCalendarParams[] }
}) => {
  return state.calendars.calendars
}

export const getCalendar = (state: { calendars: { calendar: HolidayCalendarParams } }) => {
  return state.calendars.calendar
}
