import { FacilityParams } from 'app/models/facility-params'

export const getFacilityAmendments = (state: {
  facilityAmendments: { facilityAmendments: FacilityParams[] }
}) => state.facilityAmendments.facilityAmendments

export const getFacilityAmendment = (state: {
  facilityAmendments: { facilityAmendment: FacilityParams }
}) => {
  return state.facilityAmendments.facilityAmendment
}
