import React, { useEffect } from 'react'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { ServicingCols } from '../servicing/servicing-columnDefs'
import {
  MyWorkDataParams,
} from 'app/models/mywork-data-params'
import { ServicingParams } from 'app/models/servicing-params'
import { loadAllLoanRollovers } from 'app/state/ducks/loan-rollovers/thunks'
import { loadAllLoanIncreases } from 'app/state/ducks/loan-increases/thunks'
import { loadAllLoans } from 'app/state/ducks/loans/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { getLoanRollovers } from 'app/state/ducks/loan-rollovers/selectors'
import { getLoanIncreases } from 'app/state/ducks/loan-increases/selectors'
import { getAllLoans } from 'app/state/ducks/loans/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'

import { useListState } from '@mantine/hooks'
import { loadAllLoanRateSettings } from 'app/state/ducks/loan-rate-settings/thunks'
import { getFeePayments } from 'app/state/ducks/fee-payment/selectors'
import { loadFeePayments } from 'app/state/ducks/fee-payment/thunks'
import { getLoanConversions } from 'app/state/ducks/loan-conversions/selectors'
import { loadAllLoanConversions } from 'app/state/ducks/loan-conversions/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { MyWorkSummary } from './mywork_summary'
import { Stack } from '@mantine/core'
import { scheduledFakeTable } from './mywork-util/mywork-fake-data'
import { calcTotalAmount } from './mywork-util/my-work-utils'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { checkLoanRateSettings, fetchAmortizationScheduleFacility, fetchConversions, fetchFeePayments, fetchIncreases, fetchMaturingDeals, fetchMaturingFacilities, fetchMaturingLoan, fetchRateSettings, fetchRollovers, handleServicingRowClicked } from '../servicing/servicing-utils'
import { getLoanRateSettings } from 'app/state/ducks/loan-rate-settings/selectors'
import { useNavigate } from 'react-router'

interface props {
  date: Date
}

export function ScheduledPage({ date }: props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loanRollovers = useSelector(getLoanRollovers)
  const loanIncreases = useSelector(getLoanIncreases)
  const loanConversions = useSelector(getLoanConversions)
  const allLoans = useSelector(getAllLoans)
  const allFacilities = useSelector(getFacilities)
  const allDeals = useSelector(getDeals)
  const allEntities = useSelector(getEntities)
  const allFeePayments = useSelector(getFeePayments)

  const [scheduledTable, scheduleTableHandler] =
    useListState(scheduledFakeTable)
  const [rolloverServicing, rolloverServicingHandler] =
    useListState<ServicingParams>()
  const [increaseServicing, increaseServicingHandler] =
    useListState<ServicingParams>()
  const [conversionServicing, conversionServicingHandler] =
    useListState<ServicingParams>()
  const [matturingLoanServicing, matturingLoanServicingHandler] =
    useListState<ServicingParams>()
  const [maturingDealServicing, maturingDealServicingHandler] =
    useListState<ServicingParams>()
  const [maturingFacilityServicing, maturingFacilityServicingHandler] =
    useListState<ServicingParams>()
  const [rateSettingServicing, rateSettingServicingHandler] =
    useListState<ServicingParams>()
  const [loanWithoutRatesServicing, loanWithoutRatesServicingHandler] =
    useListState<ServicingParams>()
  const [amortizationFacilitiesServicing, amortizationFacilitiesServicingHandler] =
    useListState<ServicingParams>()
  const [feePaymentServicing, feePaymentHandler] =
    useListState<ServicingParams>()
  const loanRateSettings = useSelector(getLoanRateSettings)


  useEffect(() => {
    dispatch(loadAllLoanRollovers())
    dispatch(loadAllLoanIncreases())
    dispatch(loadAllLoans())
    dispatch(loadAllFacilities())
    dispatch(loadEntities())
    dispatch(loadFeePayments())
    dispatch(loadAllLoanRateSettings())
    dispatch(loadAllLoanConversions())
    dispatch(loadAllDeals())
  }, [])


  // Set fee payment servicing data
  useEffect(() => {
    if (!allFeePayments || !allFacilities || !allEntities) {
      return
    }
    const feePayments = fetchFeePayments(
      allFeePayments,
      allEntities,
      allFacilities
    )
    if (feePayments !== feePaymentServicing) {
      feePaymentHandler.setState(feePayments)
    }
  }, [allFeePayments, allFacilities, allEntities])

  // Set amortization facilities
  useEffect(() => {
    if (!allFacilities || !allEntities) {
      return
    }
    amortizationFacilitiesServicingHandler.setState(fetchAmortizationScheduleFacility(allFacilities, allEntities, date))
  }, [allFacilities, allEntities, date])

  // Set rate settings servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !loanRateSettings) {
      return
    }
    const rateSettings = fetchRateSettings(
      loanRateSettings,
      allLoans,
      allEntities,
      allFacilities
    )
    if (rateSettings !== rateSettingServicing) {
      rateSettingServicingHandler.setState(rateSettings)
    }

    const loanWithoutRates = checkLoanRateSettings(
      loanRateSettings,
      allLoans,
      allEntities,
      allFacilities
    )
    if (loanWithoutRates !== loanWithoutRatesServicing) {
      loanWithoutRatesServicingHandler.setState(loanWithoutRates)
    }
  }, [allFacilities, allLoans, allEntities, loanRateSettings])

  // Set maturing Facilities
  useEffect(() => {
    if (!allFacilities || !allEntities) {
      return
    }
    const maturingFacilities = fetchMaturingFacilities(
      allEntities,
      allFacilities,
      date,
    )
    if (maturingFacilities !== maturingFacilityServicing) {
      maturingFacilityServicingHandler.setState(maturingFacilities)
    }

  }, [allFacilities, allEntities, date])

  // Set maturing Deals
  useEffect(() => {
    if (!allDeals || !allEntities) {
      return
    }
    const maturingDeals = fetchMaturingDeals(
      allDeals,
      allEntities,
      date,
    )
    if (maturingDeals !== maturingDealServicing) {
      maturingDealServicingHandler.setState(maturingDeals)
    }

  }, [allDeals, allEntities, date])

  // Set loan conversion servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !loanConversions) {
      return
    }
    const conversions = fetchConversions(
      loanConversions,
      allLoans,
      allEntities,
      allFacilities
    )

    if (conversions !== conversionServicing) {
      conversionServicingHandler.setState(conversions)
    }

  }, [allFacilities, allLoans, allEntities, loanConversions])

  // Set maturing Loans and rollovers servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities || !loanRollovers) {
      return
    }
    const rollovers = fetchRollovers(
      loanRollovers,
      allLoans,
      allEntities,
      allFacilities
    )
    if (rollovers !== rolloverServicing) {
      rolloverServicingHandler.setState(rollovers)
    }
    const increases = fetchIncreases(
      loanIncreases,
      allLoans,
      allEntities,
      allFacilities
    )
    if (increases !== increaseServicing) {
      increaseServicingHandler.setState(increases)
    }
    const maturingLoans = fetchMaturingLoan(
      loanRollovers,
      allLoans,
      allEntities,
      allFacilities
    )
    if (maturingLoans !== matturingLoanServicing) {
      matturingLoanServicingHandler.setState(maturingLoans)
    }
  }, [allFacilities, allLoans, allEntities, loanRollovers, loanIncreases])

  // Set servicing data
  useEffect(() => {
    const result = [
      ...rolloverServicing,
      ...increaseServicing,
      ...matturingLoanServicing,
      ...rateSettingServicing,
      ...loanWithoutRatesServicing,
      ...amortizationFacilitiesServicing,
      ...feePaymentServicing,
      ...conversionServicing,
      ...maturingDealServicing,
      ...maturingFacilityServicing,
    ].sort(
      (a, b) =>
        new Date(a.serviceTime).getTime() - new Date(b.serviceTime).getTime()
    )

    scheduleTableHandler.setState(result) //this makes it so we don't use fake data
  }, [
    rolloverServicing,
    increaseServicing,
    matturingLoanServicing,
    rateSettingServicing,
    loanWithoutRatesServicing,
    amortizationFacilitiesServicing,
    feePaymentServicing,
    conversionServicing,
    maturingDealServicing,
    maturingFacilityServicing,
  ])


  const scheduleTableFiltered = scheduledTable.filter(a => {
    const effectiveDate = new Date(a.effectiveDate);

    // Compare the year, month, and day of both dates
    return (
      date.getFullYear() === effectiveDate.getFullYear() &&
      date.getMonth() === effectiveDate.getMonth() &&
      date.getDate() === effectiveDate.getDate()
    );
  })

  const activityCount = scheduleTableFiltered.length.toString()
  const percentageComplete = '25'
  const deadline = stringToDate(scheduleTableFiltered[0]?.serviceTime.toString()).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  }) ?? 'N/A'
  const totalAmount = formatNumberToCurrency(calcTotalAmount(scheduleTableFiltered?.map(item => item?.amount ?? 0)) ?? 0, 'USD')?.toString() ?? 'N/A'

  return (
    <>
      <Stack w="100%">
        <MyWorkSummary activityCount={activityCount} percentageComplete={percentageComplete + '%'} deadlineApproaching={deadline === 'Invalid Date' ? 'N/A' : deadline} totalActivityAmount={totalAmount} />
        <TableColumnClickExclusion
          tableName='Scheduled Table'
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          overflow="hidden"
          minHeight="0px"
          columnDefs={ServicingCols}
          data={scheduleTableFiltered}
          setRow={(e: MyWorkDataParams, cellName: string) => {
            if (cellName != 'id') {
              return handleServicingRowClicked(
                e as unknown as ServicingParams,
                navigate,
                allLoans,
                allFacilities
              )
            }
          }}
        />
      </Stack>
    </>
  )
}
