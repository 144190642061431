import { NoticeParams } from 'app/models/notices-params'
import { KpiProps } from 'app/views/components/KPI/KPI'
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters'

export const getKPIData = (
  data: NoticeParams[],
  setData: React.Dispatch<React.SetStateAction<NoticeParams[]>>,
): KpiProps[] => {
  const filterFunctions: Record<string, (notice: NoticeParams) => boolean> = {
    Lender: (notice: NoticeParams) => notice.receiver === 'Lenders',
    Borrower: (notice: NoticeParams) => notice.receiver === 'Borrower',
    Draft: (notice: NoticeParams) => notice.status === 'Draft',
    Submitted: (notice: NoticeParams) => notice.status === 'Submitted',
  }

  const { applyFilter, appliedFilters } = useKPIFilters({
    data,
    filterFunctions,
    setData,
  })

  if (!data) return []

  const borrower = data.filter(row => row.receiver === 'Borrower')
  const lender = data.filter(row => row.receiver === 'Lenders')
  const draft = data.filter(row => row.status === 'Draft')
  const submitted = data.filter(row => row.status === 'Submitted')

  return [
    {
      title: 'All Notices',
      amount: data.length,
      percentage: Math.round((data.length / data.length) * 100),
      color: 0,
      activeFilter: appliedFilters.size === 0,
      event: () => applyFilter('All Notices'),
    },
    {
      title: 'Lender',
      amount: lender.length,
      percentage: Math.round((lender.length / data.length) * 100),
      color: 0,
      activeFilter: appliedFilters.has('Lender'),
      event: () => applyFilter('Lender'),
    },
    {
      title: 'Borrower',
      amount: borrower.length,
      percentage: Math.round((borrower.length / data.length) * 100),
      color: 1,
      activeFilter: appliedFilters.has('Borrower'),
      event: () => applyFilter('Borrower'),
    },
    {
      title: 'Pending Approval',
      amount: submitted.length,
      percentage: Math.round((submitted.length / data.length) * 100),
      color: 2,
      activeFilter: appliedFilters.has('Submitted'),
      event: () => applyFilter('Submitted'),
    },
    {
      title: 'Pending Review',
      amount: draft.length,
      percentage: Math.round((draft.length / data.length) * 100),
      color: 3,
      activeFilter: appliedFilters.has('Draft'),
      event: () => applyFilter('Draft'),
    },
  ]
}
