export const GET_PAYMENTS = 'payments/GET_PAYMENTS'
export const GET_LOCKED_PAYMENTS = 'payments/GET_LOCKED_PAYMENTS'
export const SUBMIT_PAYMENT = 'payments/SUBMIT_PAYMENT'
export const RELEASE_PAYMENT = 'payments/RELEASE_PAYMENT'
export const SETTLE_PAYMENT = 'payments/SETTLE_PAYMENT'
export const CANCEL_PAYMENT = 'payments/CANCEL_PAYMENT'
export const CHANGE_FRONTING = 'payments/CHANGE_FRONTING'
export const GET_RECEIVERS = 'payments/GET_RECEIVERS'
export const GET_SENDERS = 'payments/GET_SENDERS'
export const CHANGE_RECEIVED_PAYMENTS = 'payments/CHANGE_RECEIVED_PAYMENTS'
export const REJECT_RECEIVER = 'payments/REJECT_RECEIVER'
export const QUEUE_PAYMENT = 'payments/QUEUE_PAYMENT'
