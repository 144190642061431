import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PageLayout } from 'app/views/components/layout/page-layout'
import FacilityPageHeaderSection from '../facility-page-header'
import { loadLockedFacilities } from 'app/state/ducks/facilities/thunks'
import LockedFacilitiesPage from './locked-facility-page'
import { loadAllDeals, loadLockedDeals } from 'app/state/ducks/deals/thunks'
export function LockedFacilityPage() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadLockedFacilities())
    dispatch(loadLockedDeals())
    dispatch(loadAllDeals())
  }, [])

  return (
    <PageLayout>
      <FacilityPageHeaderSection />
      <LockedFacilitiesPage />
    </PageLayout>
  )
}
