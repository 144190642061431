import * as types from './types'

export const getAllLoans = (payload: any) => ({
  type: types.GET_ALL_LOANS,
  payload,
})

export const getFacilityLoans = (payload: any) => ({
  type: types.GET_LOANS_BY_FACILITY,
  payload,
})

export const getLoan = (payload: any) => ({
  type: types.GET_LOAN,
  payload,
})

export const getApprovedLoans = (payload: any) => ({
  type: types.GET_APPROVED_LOANS,
  payload,
})

export const getAllApprovedLoans = (payload: any) => ({
  type: types.GET_ALL_APPROVED_LOANS,
  payload,
})

export const addLoan = (payload: any) => ({
  type: types.ADD_LOAN,
  payload,
})

export const editLoan = (payload: any) => ({
  type: types.EDIT_LOAN,
  payload,
})

export const submitLoan = (payload: any) => ({
  type: types.SUBMIT_LOAN,
  payload,
})

export const approveLoan = (payload: any) => ({
  type: types.APPROVE_LOAN,
  payload,
})

export const rejectLoan = (payload: any) => ({
  type: types.REJECT_LOAN,
  payload,
})

export const deleteLoan = (payload: any) => ({
  type: types.DELETE_LOAN,
  payload,
})

export const getLoanHistory = (payload: any) => ({
  type: types.GET_LOAN_HISTORY,
  payload,
})

export const getLockedLoans = (payload: any) => ({
  type: types.GET_LOCKED_LOANS,
  payload,
})

export const getLockedLoansByFacility = (payload: any) => ({
  type: types.GET_LOCKED_LOANS_BY_FACILITY,
  payload,
})

export const startLoadingFacilityLoans = () => ({
  type: types.START_LOADING_FACILITY_LOANS,
})
