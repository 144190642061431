import { LendersPositionState } from './reducer'

export const getFacilityLendersPositions = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.facilityLendersPositions.data
export const getLoanLendersPositions = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.loanLendersPositions.data
export const getFacilityLendersPositionsWithLoading = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.facilityLendersPositions
export const getLoanLendersPositionsWithLoading = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.loanLendersPositions
