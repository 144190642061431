import React from 'react'
import { Group, Stack, Text } from '@mantine/core'

//we will convert these to string before passing them in
interface props {
  activityCount: string
  percentageComplete: string
  deadlineApproaching: string
  totalActivityAmount: string
}

export function MyWorkSummary({ activityCount, percentageComplete, deadlineApproaching, totalActivityAmount }: props) {
  return (
    <>
      <Stack w="100%" className='myworkSummarySection'>
        <Group noWrap w="100%" position='apart'>
          <Group noWrap className='summaryActivity'>
            <Stack>
              <Text className='summaryActivityTitle'>Activity Count</Text>
              <Text className='summaryActivityValue'>{activityCount}</Text>
            </Stack>
          </Group>
          <Group noWrap className='summaryActivity'>
            <Stack>
              <Text className='summaryActivityTitle'>Percentage Complete</Text>
              <Text className='summaryActivityValue'>{percentageComplete}</Text>
            </Stack>
          </Group>
          <Group noWrap className='summaryActivity'>
            <Stack>
              <Text className='summaryActivityTitle'>Deadline Approaching</Text>
              <Text className='summaryActivityValue'>{deadlineApproaching}</Text>
            </Stack>
          </Group>
          <Group noWrap className='summaryActivity'>
            <Stack>
              <Text className='summaryActivityTitle'>Total Activity Amount</Text>
              <Text className='summaryActivityValue'>{totalActivityAmount}</Text>
            </Stack>
          </Group>
        </Group>
      </Stack>
    </>
  )
}
