import { DealParams } from 'app/models/deal-params';
import { getBusinessDate } from 'app/state/ducks/business-date/selectors';
import { formatDateToUTC } from 'app/utils/util-functions';
import { KpiProps } from 'app/views/components/KPI/KPI';
import { useSelector } from 'react-redux';
import { useKPIFilters } from 'app/views/components/KPI/KPI-filters';

export const getKPIData = (
  data: DealParams[],
  setData: React.Dispatch<React.SetStateAction<DealParams[]>>
): KpiProps[] => {
  const businessDate = useSelector(getBusinessDate);

  const filterFunctions: Record<string, (deal: DealParams) => boolean> = {
    'Submitted': (deal: DealParams) => deal.status === 'Submitted',
    'Approved': (deal: DealParams) => deal.status === 'Approved',
    'Draft': (deal: DealParams) => deal.status === 'Draft',
    'Expired': (deal: DealParams) => deal.maturity < formatDateToUTC(businessDate),
    'Amendment': (deal: DealParams) => deal.amendment === true,
  };

  const { applyFilter, appliedFilters } = useKPIFilters({ data, filterFunctions, setData });

  if (!data) return [];

  const submittedDeals = data.filter(deal => deal.status === 'Submitted');
  const approvedDeals = data.filter(deal => deal.status === 'Approved');
  const draftDeals = data.filter(deal => deal.status === 'Draft');
  const expiredDeals = data.filter(deal => deal.maturity < formatDateToUTC(businessDate));
  const amendedDeals = data.filter(deal => deal.amendment === true);

  return [
    {
      title: 'Pending Deals',
      amount: submittedDeals.length,
      percentage: Math.round((submittedDeals.length / data.length) * 100),
      color: 0,
      activeFilter: appliedFilters.has('Submitted'),
      event: () => applyFilter('Submitted'),
    },
    {
      title: 'Expiring Deals',
      amount: expiredDeals.length,
      percentage: Math.round((expiredDeals.length / data.length) * 100),
      color: 1,
      activeFilter: appliedFilters.has('Expired'),
      event: () => applyFilter('Expired'),
    },
    {
      title: 'In Progress Deals',
      amount: draftDeals.length,
      percentage: Math.round((draftDeals.length / data.length) * 100),
      color: 2,
      activeFilter: appliedFilters.has('Draft'),
      event: () => applyFilter('Draft'),
    },
    {
      title: 'Active Deals',
      amount: approvedDeals.length,
      percentage: Math.round((approvedDeals.length / data.length) * 100),
      color: 3,
      activeFilter: appliedFilters.has('Approved'),
      event: () => applyFilter('Approved'),
    },
    {
      title: 'Pending Amendments',
      amount: amendedDeals.length,
      percentage: Math.round((amendedDeals.length / data.length) * 100),
      color: 4,
      activeFilter: appliedFilters.has('Amendment'),
      event: () => applyFilter('Amendment'),
    },
  ];
};
