import * as types from './types'

export const getFacilities = (payload: any) => ({
  type: types.GET_FACILITIES,
  payload,
})

export const getFacility = (payload: any) => ({
  type: types.GET_FACILITY,
  payload,
})

export const addFacility = (payload: any) => ({
  type: types.ADD_FACILITIES,
  payload,
})

export const editFacility = (payload: any) => ({
  type: types.EDIT_FACILITIES,
  payload,
})

export const submitFacility = (payload: any) => ({
  type: types.SUBMIT_FACILITY,
  payload,
})

export const rejectFacility = (payload: any) => ({
  type: types.REJECT_FACILITY,
  payload,
})

export const approveFacility = (payload: any) => ({
  type: types.APPROVE_FACILITY,
  payload,
})

export const deleteFacility = (payload: any) => ({
  type: types.DELETE_FACILITY,
  payload,
})

export const getFacilityHistory = (payload: any) => ({
  type: types.GET_FACILITY_HISTORY,
  payload,
})

export const getLockedFacilities = (payload: any) => ({
  type: types.GET_LOCKED_FACILITIES,
  payload,
})
