import * as types from './types'
import { EntityParams } from 'app/models/entity-params'
import { createReducer } from 'app/state/utils'


interface EntitiesState {
  entities: EntityParams[];   // Array of Entity objects
  entity: EntityParams | null; // Single entity or null
}

const initialState: EntitiesState = { entities: [], entity: null }

const entitiesReducer = createReducer(initialState)({
  [types.GET_ENTITIES]: (state: any, payload: any) => {
    return { ...state, entities: payload.payload }
  },
  [types.GET_ENTITY]: (state: any, payload: any) => {
    return { ...state, entity: payload.payload }
  },
  [types.ADD_ENTITIES]: (state: any, payload: any) => {
    return {
      ...state,
      entities: [payload.payload.data, ...state.entities],
    }
  },
  [types.EDIT_ENTITIES]: (state: any, payload: any) => {
    const index = state.entities.findIndex(
      (entity: { id: any }) => entity.id === payload.payload.data.id
    )
    const newArray = [...state.entities]
    newArray[index] = { ...payload.payload.data, status: 'Inactive' }
    return {
      ...state,
      entities: newArray,
      entity: { ...payload.payload.data, status: 'Inactive' },
    }
  },
  [types.ACTIVE_ENTITY]: (state: any, payload: any) => {
    const index = state.entities.findIndex(
      (entity: { id: any }) => entity.id === payload.payload.id
    )
    const newArray = [...state.entities]
    newArray[index] = { ...newArray[index], status: 'Active' }
    if (payload.payload.id === state.entity?.id) {
      return {
        ...state,
        entity: { ...state.entity, status: 'Active' },
        entities: newArray,
      }
    }
    return {
      ...state,
      entities: newArray,
    }
  },
  [types.INACTIVE_ENTITY]: (state: any, payload: any) => {
    const index = state.entities.findIndex(
      (entity: { id: any }) => entity.id === payload.payload.id
    )
    const newArray = [...state.entities]
    newArray[index] = { ...newArray[index], status: 'Inactive' }
    if (payload.payload.id === state.entity?.id) {
      return {
        ...state,
        entity: { ...state.entity, status: 'Inactive' },
        entities: newArray,
      }
    }
    return {
      ...state,
      entities: newArray,
    }
  },
  [types.DELETE_ENTITIES]: (state: any, payload: any) => {
    const filteredEntities = state.entities.filter((entity: any) => {
      return entity.id !== payload.payload[0].id
    })

    return {
      ...state,
      entities: filteredEntities,
    }
  },
})

export default entitiesReducer
