import { Group, Stack, Modal } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { useState } from 'react'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { UpdatedFacilitiesScreen } from '../facilities/updated-facilities-screen'

export enum Tab {
  deals = 'deals',
  facilies = 'facilities',
}
export enum StatusTab {
  active = 'active',
  locked = 'locked',
}

function FacilityPageHeaderSection() {
  const [openFacility, setOpenFacility] = useState(false)


  return (
    <>
      <Stack>
        <Group position="apart">
          <PageTitle>Deal Management</PageTitle>
          <PrimaryButton
            text="Create Facility"
            onClick={() => setOpenFacility(true)}
          />
        </Group>
      </Stack>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openFacility}
        onClose={() => setOpenFacility(false)}
        centered={true}
        size="77%"
      >
        <UpdatedFacilitiesScreen
          deal={undefined}
          facility={undefined}
          onClose={() => setOpenFacility(false)}
        />
      </Modal>
    </>
  )
}

export default FacilityPageHeaderSection
