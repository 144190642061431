import { EntityParams } from 'app/models/entity-params'

export const getEntities = (state: {
  entities: { entities: EntityParams[] }
}) => {
  return state.entities.entities
}

export const getEntity = (state: { entities: { entity: EntityParams } }) => {
  return state.entities.entity
}
