import { Anchor, Avatar, Badge, Group, Modal, Tooltip } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadOneFacility,
  setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { ClickText } from 'app/views/components/Headers&Text/Text'
import { useNavigate, useParams } from 'react-router-dom'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import MainAccordion from 'app/views/components/Accordion/main-accordion'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getInitials from 'app/views/components/functions/getInitials'
import { EntityParams } from 'app/models/entity-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { getFacilityLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { UpdatedFacilitiesScreen } from '../../facilities/updated-facilities-screen'
import { DealParams } from 'app/models/deal-params'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { loadFeesByFacility } from 'app/state/ducks/fees/thunks'
import { Fees } from 'app/models/fees-params'
import { getFacilityFees } from 'app/state/ducks/fees/selectors'
import { loadSchedulerAmortizationByFacility } from 'app/state/ducks/amortization/thunks'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'


export default function FacilityAccordion() {
  const { facilityId, status } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const facility = useSelector(getFacility)
  const currentDeal = useSelector(getDeal)
  const entityIdToName = new Map<string, string>()
  const entities: EntityParams[] = useSelector(getEntities)
  const { data, isLoading } = useSelector(
    getFacilityLendersPositionsWithLoading
  )
  let lendersPositions = data
  const deals: DealParams[] = useSelector(getDeals)
  const [deal, setDeal] = useState<DealParams | undefined>()
  const facilityAmendments: FacilityParams[] = useSelector(
    getFacilityAmendments
  )
  const allIndexes = useSelector(getIndexRateOptions)
  const [facilityAmendment, setFacilityAmendment] =
    useState<FacilityParams | null>(null)
  const [amendment, setAmendment] = useState<string | null>(null)
  let fees: Fees[] = useSelector(getFacilityFees)
  // const amortizationScheduler: AmortizationScheduleParams[] = useSelector(getFacilitySchedulerAmortization)


  if (
    facility &&
    facility.amendmentLenders &&
    facility.amendmentLenders.length > 0
  ) {
    lendersPositions = facility.amendmentLenders
  }
  if (
    facility &&
    facility.amendmentFees &&
    facility.amendmentFees.length > 0
  ) {
    fees = facility.amendmentFees
  }

  const [openEdit, setOpenEdit] = useState(false)

  useEffect(() => {
    if (!facilityId) {
      return
    }
    dispatch(loadOneFacility(facilityId, status ?? 'Draft'))
    dispatch(loadEntities())
    dispatch(loadAllFacilityAmendments())
    dispatch(loadLendersPositionsByFacility(facilityId))
    dispatch(loadFeesByFacility(facilityId))
    dispatch(loadSchedulerAmortizationByFacility(facilityId))
    dispatch(loadIndexRateOptions())
  }, [facilityId])
  useEffect(() => {
    if (!facility || !deals) {
      return
    }
    const deal = deals.find(d => d.id == facility.dealId.id)
    if (deal) {
      setDeal(deal)
    }
  }, [deals, facility])

  useEffect(() => {
    if (!currentDeal) {
      return
    }
    setDeal(currentDeal)
  }, [currentDeal])

  useEffect(() => {
    if (!facilityAmendments || !facilityId) {
      return
    }
    const amended_facility = facilityAmendments.find(
      amendment => amendment.id === facilityId
    )
    if (amended_facility) {
      setFacilityAmendment(amended_facility ?? null)
      dispatch(setOneFacility(amended_facility))
      setAmendment('amend')
    }
  }, [facilityId, facilityAmendments, fees])

  if (Array.isArray(entities)) {
    for (const entity of entities) {
      if (entity.id) {
        entityIdToName.set(cleanUUID(entity.id), entity.entityName)
      }
    }
  }

  return (
    <>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openEdit}
        onClose={() => setOpenEdit(false)}
        centered={true}
        size="77%"
      >
        {amendment ? (
          <UpdatedFacilitiesScreen
            deal={deal}
            facility={facilityAmendment ?? facility}
            onClose={() => setOpenEdit(false)}
            amendment={amendment}
            step={5}
          />
        ) : (
          <UpdatedFacilitiesScreen
            deal={deal}
            facility={facility}
            onClose={() => setOpenEdit(false)}
            step={5}
          />
        )}
      </Modal>
      <MainAccordion
        key={facilityId}
        value="facility accordion"
        title="Facility Details"
        data={[
          {
            label: 'Facility Type',
            value: facility?.facilityType,
          },
          {
            label: 'Amount',
            value: formatNumberToCurrency(Number(facility?.amount) ?? 0, facility?.baseCurrency ?? 'USD'),
          }, {
            label: 'Utilized Amount',
            value: formatNumberToCurrency(Number(facility?.utilizedAmount) ?? 0, facility?.baseCurrency ?? 'USD'),
          },
          {
            label: 'Start Date',
            value: facility?.startDate,
          },
          {
            label: 'Maturity Date',
            value: facility?.maturity,
          },
          {
            label: 'Facility ID',
            value: facility?.id,
          },
          {
            label: 'Facility CUSIP / ISIN',
            value: facility?.cusipIsin,
          },
          {
            label: 'Facility LXID',
            value: facility?.lxId,
          },
          {
            label: (
              <Anchor
                onClick={() =>
                  navigate(
                    `/dealmanagement/facilities/${facility?.id}/borrowers`,
                    { state: { facility, entities } }
                  )
                }
              >
                <Group>
                  <ClickText text="Borrowers" />
                  <Badge size="xl" color="violet" p="0.8rem">
                    {facility?.borrowers?.length}
                  </Badge>
                </Group>
              </Anchor>
            ),
            value: (
              <Group>
                <Tooltip.Group openDelay={300} closeDelay={100}>
                  <Avatar.Group
                    spacing="sm"
                    onClick={() =>
                      navigate(
                        `/dealmanagement/facilities/${facility?.id}/borrowers`,
                        { state: { facility, entities } }
                      )
                    }
                  >
                    {facility?.borrowers?.map((borrower, i) => {
                      const entityName = entityIdToName.get(
                        cleanUUID(borrower.id)
                      )
                      return (
                        <Anchor key={'facility-borrower' + facilityId + i}>
                          <Tooltip label={entityName} withArrow>
                            <Avatar color="violet" radius="xl">
                              {getInitials(entityName)}
                            </Avatar>
                          </Tooltip>
                        </Anchor>
                      )
                    })}
                  </Avatar.Group>
                </Tooltip.Group>
              </Group>
            ),
          },
          {
            label: (
              <Anchor
                onClick={() =>
                  navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}/lenders`
                  )
                }
              >
                <Group noWrap>
                  <ClickText text="Lender Allocations" />
                  <Badge size="xl" color="teal" p="0.8rem">
                    {isLoading ? '' : lendersPositions?.length}
                  </Badge>
                </Group>
              </Anchor>
            ),
            value: isLoading ? <></> : (
              <Group
                noWrap
                onClick={() =>
                  navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}/lenders`
                  )
                }
              >
                <Tooltip.Group openDelay={300} closeDelay={100}>
                  <Avatar.Group spacing="sm">
                    {lendersPositions?.map((lender, i) => {
                      const lenderName =
                        entities?.find(item => item.id === lender.lender.id)
                          ?.entityName ?? ''
                      return (
                        <Anchor
                          key={'facility-accordion-lender-' + lender.id + i}
                        >
                          <Tooltip label={lenderName} withArrow>
                            <Avatar size="md" color="teal" radius="xl">
                              {getInitials(lenderName)}
                            </Avatar>
                          </Tooltip>
                        </Anchor>
                      )
                    })}
                  </Avatar.Group>
                </Tooltip.Group>
              </Group>
            ),
          },
          {
            label: 'Admin Agent',
            value: (
              <Tooltip
                label={entityIdToName.get(cleanUUID(facility?.adminEntity?.id))}
              >
                <Anchor>
                  <Avatar size="md" color="cyan" radius="xl">
                    {getInitials(
                      entityIdToName.get(cleanUUID(facility?.adminEntity?.id))
                    )}
                  </Avatar>
                </Anchor>
              </Tooltip>
            ),
          },
          {
            label: 'Administrative Agent MEI',
            value: entities && deal
              ? entities.find(
                entity =>
                  entity.id === cleanUUID(deal?.adminEntity?.id)
              )?.mei ?? ''
              : ''
          },
          {
            label: 'Interest Rate Option',
            value: facility?.iroValues.map(iro => allIndexes.find(index => index.id === iro.indexOption.id)?.indexOption).join(', '),
          },
          {
            label: 'Available Currencies',
            value: facility?.currencies.join(', '),
          },
          {
            label: 'Base Currency',
            value: facility?.baseCurrency,
          },
          {
            label: fees[0]?.feeType ? <Anchor
              onClick={() =>
                navigate(`/dealmanagement/facilities/${facility?.id}/${facility?.status}/fees/${fees[0]?.feeType}`)
              }
            >
              <Group noWrap>
                <ClickText text="Fees" />
              </Group>
            </Anchor> : 'Fees',
            value: fees.length.toString() ?? '0',
          },
          {
            label: (
              <Anchor
                onClick={() =>
                  navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}/amortization-schedule`
                  )
                }
              >
                {' '}
                <ClickText text="Amortization Schedule" />
              </Anchor>
            ),
            value: facility?.amortizationScheduleId ? "Scheduled" : "Not Scheduled",
          },
          {
            label: 'Sub Limits',
            value: facility?.subLimits
              ?.map(sub => sub.subLimitsType)
              .join(', '),
          },
        ].concat(
          facility?.amendment
            ? [
              {
                label: 'Amendment Date',
                value: facility?.amendmentDate ?? '',
              },
            ]
            : []
        )}
      />
    </>
  )
}
