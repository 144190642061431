import { DealState } from './reducer'

export const getDeals = (state: { deals: DealState }) => state.deals.deals

export const getDealHistory = (state: { deals: DealState }) =>
  state.deals.dealHistory

export const getDeal = (state: { deals: DealState }) => {
  return state.deals.deal
}

export const getLockedDeals = (state: { deals: DealState }) => {
  return state.deals.lockedDeals
}
