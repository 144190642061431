import * as types from './types'

export const getAllDraftNotices = (payload: any) => ({
  type: types.GET_ALL_DRAFT_NOTICES,
  payload,
})

export const getAllLockedNotices = (payload: any) => ({
  type: types.GET_ALL_LOCKED_NOTICES,
  payload,
})

export const getAllActiveNotices = (payload: any) => ({
  type: types.GET_ALL_ACTIVE_NOTICES,
  payload,
})

export const getAllSubmittedNotices = (payload: any) => ({
  type: types.GET_ALL_SUBMITTED_NOTICES,
  payload,
})

export const getNotice = (payload: any) => ({
  type: types.GET_NOTICE,
  payload,
})


export const submitNotice = (payload: any) => ({
  type: types.SUBMIT_NOTICE,
  payload,
})

export const approveNotice = (payload: any) => ({
  type: types.APPROVE_NOTICE,
  payload,
})

export const rejectNotice = (payload: any) => ({
  type: types.REJECT_NOTICE,
  payload,
})

export const previewNotice = (payload: any) => ({
  type: types.PREVIEW_NOTICE,
  payload,
})