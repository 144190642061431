import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { loadLockedDeals } from 'app/state/ducks/deals/thunks'
import LockedDealManagmentPage from './locked-deals-management-page'
import LockedDealManagementHeaderSection from './locked-deal-management-header'
export function LockedDealManagementPage() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadLockedDeals())
  }, [])

  return (
    <PageLayout>
      <LockedDealManagementHeaderSection />
      <LockedDealManagmentPage />
    </PageLayout>
  )
}
