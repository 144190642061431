import { Accordion, Grid, Modal, Text } from '@mantine/core'
import { LoanParams } from 'app/models/loan-params'
import NestedAccordion from 'app/views/components/Accordion/nested-accordion'
import OneLoanAccordion from './one-loan-accordion'
import { useEffect, useState } from 'react'
import { UpdatedLoanScreen } from './loan-stepper'
import { useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { getLoansByFacility, getLockedLoansByFacility } from 'app/state/ducks/loans/selectors'

type Props = {
  showInactiveLoans: boolean
}

export default function FacilityLoans({ showInactiveLoans }: Props) {
  const data = useSelector(getLoansByFacility)
  const inactiveData = useSelector(getLockedLoansByFacility)
  const loans = showInactiveLoans ? inactiveData?.data ?? [] : data?.data ?? []
  const loading = data?.loading ?? false
  const [swinglineLoans, setSwinglineLoans] = useState<LoanParams[]>([])
  const [nonSwinglineLoans, setNonSwinglineLoans] = useState<LoanParams[]>([])
  const [openEdit, setOpenEdit] = useState(false)
  const [loan, setLoan] = useState<LoanParams>()
  const facility = useSelector(getFacility)
  useEffect(() => {
    if (!loans) {
      return
    }
    setSwinglineLoans(loans.filter(loan => loan.isSwingLine))
    setNonSwinglineLoans(loans.filter(loan => !loan.isSwingLine))
  }, [loans])

  return (
    <><Accordion
      w="100%"
      bg="#32439A"
      multiple
      variant="contained"
      defaultValue={['loans', 'swinglineLoans']}
      styles={{
        control: {
          fontFamily: 'Plus Jakarta Sans',
          '&[data-active]': {
            backgroundColor: '#32439A',
          },
          '&:hover': {
            backgroundColor: '#32439A',
          },
        },
        chevron: {
          color: 'white',
        },
      }}
    >
      <Accordion.Item value="loans">
        <Accordion.Control>
          <Text c="white" fw={600} fz="lg">
            Loans Within Facility ({nonSwinglineLoans?.length})
          </Text>
        </Accordion.Control>

        <Accordion.Panel>
          <div
            style={{
              paddingRight: '3.75rem',
              paddingLeft: 'calc(1rem + 0.25rem)',
            }}
          >
            <Grid
              className="accordion-table-head"
              columns={31}
              pb="5px"
              pt="5px"
            >
              <Grid.Col span={4}>
                <Text>Index Rate Option</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text>Start Date</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text>End Date</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text>Amount</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text>All-In Rate</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text>Status</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text>Action</Text>
              </Grid.Col>
            </Grid>
          </div>
          {!loading &&
            <NestedAccordion items={loans.filter(loan => !loan.isSwingLine)} key={nonSwinglineLoans.length}>
              {item => <OneLoanAccordion item={item}
                setOpenEdit={setOpenEdit}
                setLoan={setLoan} />
              }
            </NestedAccordion>
          }
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="swinglineLoans">
        <Accordion.Control>
          <Text c="white" fw={600} fz="lg">
            Swingline Loans Within Facility ({swinglineLoans?.length})
          </Text>
        </Accordion.Control>

        <Accordion.Panel>
          <div
            style={{
              paddingRight: '3.75rem',
              paddingLeft: 'calc(1rem + 0.25rem)',
            }}
          >
            <Grid
              className="accordion-table-head"
              columns={31}
              pb="5px"
              pt="5px"
            >
              <Grid.Col span={4} style={{ textAlign: 'center' }}>
                <Text>Index Rate Option</Text>
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'center' }}>
                <Text>Start Date</Text>
              </Grid.Col>
              <Grid.Col span={4} style={{ textAlign: 'center' }}>
                <Text>End Date</Text>
              </Grid.Col>
              <Grid.Col span={6} style={{ textAlign: 'center' }}>
                <Text>Amount</Text>
              </Grid.Col>
              <Grid.Col span={3} style={{ textAlign: 'center' }}>
                <Text>All-In Rate</Text>
              </Grid.Col>
              <Grid.Col span={5} style={{ textAlign: 'center' }}>
                <Text>Status</Text>
              </Grid.Col>
              <Grid.Col span={5} style={{ textAlign: 'center' }}>
                <Text>Action</Text>
              </Grid.Col>
            </Grid>
          </div>
          {!loading && <NestedAccordion items={loans.filter(loan => loan.isSwingLine)} key={swinglineLoans.length}>
            {item => <OneLoanAccordion
              item={item}
              setOpenEdit={setOpenEdit}
              setLoan={setLoan}
            />}
          </NestedAccordion>
          }
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion><Modal
      className="modal-body modal-body-template create-new-form fit-content-modal"
      opened={openEdit}
      onClose={() => setOpenEdit(false)}
      centered={true}
      size="77%"
    >
        <UpdatedLoanScreen
          loan={loan}
          facility={facility}
          step={0}
          onClose={() => setOpenEdit(false)} />
      </Modal></>
  )
}
