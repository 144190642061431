import { Dispatch } from 'redux'
import { EntityParams } from 'app/models/entity-params'
import {
  activateEntity,
  loadEntities,
  removeEntities,
  deactivateEntity,
} from 'app/state/ducks/entities/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

export async function activeEntity(
  entity: EntityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(activateEntity(entity))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Activated',
      message: 'Entity has been Activated',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload || 'Error with activation',
    })
    return false
  }
  return true
}

export async function makeInactiveEntity(
  entity: EntityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deactivateEntity(entity))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Deactivation',
      message: 'Entity has been deactivated.',
    })
  } else {
    ErrorNotification({
      title: 'Deactivation Failed',
      message: response.payload || 'Error with deactivation',
    })
    return false
  }
  dispatch(loadEntities())
  return true
}

export async function deleteEntity(
  entity: EntityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(removeEntities([entity.id]))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
  } else {
    SuccessNotification({
      title: 'Successful Deletion',
      message: 'Entity has been Deleted',
    })
  }
  dispatch(loadEntities())
  return
}
