export const GET_LETTER_OF_CREDITS = 'letterOfCredits/GET_LETTER_OF_CREDITS'
export const GET_LETTER_OF_CREDIT = 'letterOfCredits/GET_LETTER_OF_CREDIT'
export const ADD_LETTER_OF_CREDIT = 'letterOfCredits/ADD_LETTER_OF_CREDIT'
export const EDIT_LETTER_OF_CREDIT = 'letterOfCredits/EDIT_LETTER_OF_CREDIT'
export const SUBMIT_LETTER_OF_CREDIT = 'letterOfCredits/SUBMIT_LETTER_OF_CREDIT'
export const APPROVE_LETTER_OF_CREDIT =
  'letterOfCredits/APPROVE_LETTER_OF_CREDIT'
export const REJECT_LETTER_OF_CREDIT = 'letterOfCredits/REJECT_LETTER_OF_CREDIT'
export const GET_APPROVED_LETTER_OF_CREDITS =
  'letterOfCredits/GET_APPROVED_LETTER_OF_CREDITS'
export const GET_ALL_APPROVED_LETTER_OF_CREDITS =
  'letterOfCredits/GET_ALL_APPROVED_LETTER_OF_CREDITS'
export const DELETE_LETTER_OF_CREDIT = 'letterOfCredits/DELETE_LETTER_OF_CREDIT'
export const GET_LETTER_OF_CREDIT_HISTORY = 'letterOfCredits/GET_LETTER_OF_CREDIT_HISTORY'

