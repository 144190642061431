import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const dealAmendmentsReducer = createReducer(initialState)({
  [types.GET_DEAL_AMENDMENTS]: (state: any, payload: any) => {
    return { ...state, dealAmendments: payload.payload }
  },
  [types.GET_DEAL_AMENDMENT]: (state: any, payload: any) => {
    return { ...state, dealAmendment: payload.payload }
  },
  [types.ADD_DEAL_AMENDMENTS]: (state: any, payload: any) => {
    return {
      ...state,
      dealAmendments: [
        { ...payload.payload, status: 'Draft' },
        ...state.dealAmendments,
      ],
    }
  },
  [types.EDIT_DEAL_AMENDMENTS]: (state: any, payload: any) => {
    const index = state.dealAmendments.findIndex(
      (dealAmendment: { id: any }) => dealAmendment.id === payload.payload.id
    )
    const newArray = [...state.dealAmendments]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      dealAmendments: newArray,
    }
  },
})

export default dealAmendmentsReducer
