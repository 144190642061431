import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  submitNotice,
  approveNotice,
  rejectNotice,
  getNotice,
  getAllLockedNotices,
  getAllActiveNotices,
  previewNotice,
} from 'app/state/ducks/notices/actions'
import { NoticeParams } from 'app/models/notices-params'
import axios from 'axios'

export const loadOneNotice =
  (noticeId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-notices/one?id=${noticeId}`)
      .then(notice => {
        if (statusLower === 'submitted') {
          return dispatch(getNotice({ ...notice.data.notice, status: status }))
        }
        return dispatch(getNotice({ ...notice.data, status: status }))
      }).catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }


export const preview =
  async (noticeId: string, status: string) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-notices/preview?id=${noticeId}`)
      .then(notice => {
        return { success: true, payload: notice.data }
      }).catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadAllDraftNotices = async () => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/draft-notices`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const loadAllApprovedNotices = async () => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/approved-notices`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const loadAllSubmittedNotices = async () => {
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/submitted-notices`)
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const loadActiveNotices = () => async (dispatch: Dispatch) => {
  try {
    const allDraftNotices = await loadAllDraftNotices()
    const submittedNotices = await loadAllSubmittedNotices()

    const allSubmittedNotices = submittedNotices.map((n: { notice: any }) => n.notice)

    const allActiveNotices = allDraftNotices
      .map((notice: NoticeParams) => ({ ...notice, status: 'Draft' }))
      .concat(allSubmittedNotices.map((notice: NoticeParams) => ({ ...notice, status: 'Submitted' })))

    return dispatch(getAllActiveNotices(allActiveNotices))
  } catch (e) {
    console.error(e)
    return []
  }
}


export const loadLockedNotices = () => async (dispatch: Dispatch) => {
  try {
    const lockedNotices = await loadAllApprovedNotices()
    const allLockedNotices = lockedNotices.map((notice: NoticeParams) => ({ ...notice, status: 'Approved' }))
    return dispatch(getAllLockedNotices(allLockedNotices))
  } catch (e) {
    console.error(e)
    return []
  }
}

export const submitForApproval = (notice: NoticeParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/draft-notices/submit`, { id: notice.id })
    .then(response => {
      const payload = dispatch(submitNotice(response.data))
      return { success: true, payload: payload.payload }
    }).catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}

export const approveSelectedNotice =
  (notice: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    console.log('approve - notice', notice)
    return client
      .post(`${apiServerUrl}/api/submitted-notices/approve`, { id: notice.id })
      .then(response => {
        dispatch(approveNotice(response.data))
        return { success: true }
      }).catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedNotice = (notice: NoticeParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-notices/reject`, { id: notice.id })
    .then(response => {
      const payload = dispatch(rejectNotice(response.data))
      return { success: true, payload: payload.payload }
    }).catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}


export const previewSelectedNotice = (notice: NoticeParams, templateName: string) => async (dispatch: Dispatch) => {
  console.log('notice', notice)
  const templateData = JSON.stringify({
    borrowerName: "John Doe",
    date: "2023-05-01",
    entityLegalName: "Acme Corp",
    fromEmail: "noreply@company.com",
    indexRateType: "Fixed",
    effectiveDate: "2023-05-15",
    dealName: "Acme Loan",
    dealCusipIsin: "123456789",
    facilityName: "Acme Facility",
    facilityCusipIsin: "987654321",
    loanId: "LN123456",
    loanAmount: "1000000",
    loanIndexRate: "5%",
    loanCurrency: "USD",
    loanProrataShare: "10%",
    loanInterestRate: "6%",
    loanMargin: "1%",
    loanMaturityDate: "2025-05-15",
    wireInstructionCurrency: "USD",
    wireInstructionInstitutionBankAba: "123456789",
    wireInstructionAccountWithInstitutionName: "Bank of America",
    wireInstructionBeneficiaryCustomerName: "Acme Corp",
    wireInstructionBeneficiaryAccount: "987654321",
    wireInstructionPaymentReference: "Loan Payment",
    agentName: "Agent Smith",
    agentEmail: "agent@company.com"
  })
  const template = await getTemplate(templateName, templateData)
  console.log('template', template)
  const payload = await dispatch(previewNotice(template))
  return { success: true, payload: payload.payload }
}

export const getTemplate = async (templateName: string, templateData: any) => {
  try {
    const BASE_URL = 'https://tgpazweqnh.execute-api.us-east-1.amazonaws.com/dev/v1'

    const response = await axios.post(
      BASE_URL,
      {
        pathParameters: {
          templateName: templateName,
          templateData: templateData
        }
      },
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      }
    )
    console.log('getTemplate -> response', response)
    return response.data
  } catch (error: any) {
    console.log('error', error.response.data)
    throw new Error('Error calling getTemplate')
  }
}