import * as types from './types'

export const getEntities = (payload: any) => ({
  type: types.GET_ENTITIES,
  payload,
})

export const getEntity = (payload: any) => ({
  type: types.GET_ENTITY,
  payload,
})

export const addEntity = (payload: any) => ({
  type: types.ADD_ENTITIES,
  payload,
})

export const editEntity = (payload: any) => ({
  type: types.EDIT_ENTITIES,
  payload,
})

export const deleteEntities = (payload: any) => ({
  type: types.DELETE_ENTITIES,
  payload,
})

export const activeEntity = (payload: any) => ({
  type: types.ACTIVE_ENTITY,
  payload,
})

export const inactiveEntity = (payload: any) => ({
  type: types.INACTIVE_ENTITY,
  payload,
})
