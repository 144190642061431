import React, { useEffect } from 'react'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { ServicingCols } from '../servicing/servicing-columnDefs'
import {
  MyWorkDataParams,
} from 'app/models/mywork-data-params'
import { ServicingParams } from 'app/models/servicing-params'
import { calcTotalAmount } from './mywork-util/my-work-utils'
import { loadAllLoans } from 'app/state/ducks/loans/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLoans } from 'app/state/ducks/loans/selectors'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import {
  unscheduledFakeTable,
} from './mywork-util/mywork-fake-data'
import { useListState } from '@mantine/hooks'
import { getPrincipalPayments } from 'app/state/ducks/principal-payment/selectors'
import { loadAllPrincipalPayments } from 'app/state/ducks/principal-payment/thunks'
import {
  fetchNewBorrowings,
  fetchPiks,
  fetchPrincipals,
} from '../servicing/servicing-utils'
import { getPiks } from 'app/state/ducks/payment-in-kind/selectors'
import { loadFeePayments } from 'app/state/ducks/fee-payment/thunks'
import { loadAllFees } from 'app/state/ducks/fees/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { MyWorkSummary } from './mywork_summary'
import { Stack } from '@mantine/core'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

interface props {
  date: Date
}

export function UnscheduledPage({ date }: props) {
  const dispatch = useDispatch()
  const allLoans = useSelector(getAllLoans)
  const allFacilities = useSelector(getFacilities)
  const allEntities = useSelector(getEntities)
  const allPrincipals = useSelector(getPrincipalPayments)
  const allPiks = useSelector(getPiks)

  const [newBorrowingServicing, newBorrowingServicingHandler] =
    useListState<ServicingParams>()
  const [principalServicing, principalServicingHandler] =
    useListState<ServicingParams>()
  const [pikServicing, pikServicingHandler] =
    useListState<ServicingParams>()

  const [unscheduledTable, unscheduledTableHandler] = useListState<ServicingParams>(unscheduledFakeTable)


  useEffect(() => {
    dispatch(loadAllLoans())
    dispatch(loadAllFacilities())
    dispatch(loadEntities())
    dispatch(loadAllPrincipalPayments())
    dispatch(loadFeePayments())
    dispatch(loadAllFees())
    dispatch(loadAllDeals())
  }, [])

  // Set pik servicing data
  useEffect(() => {
    if (!allPiks || !allFacilities || !allLoans || !allEntities) {
      return
    }
    const piks = fetchPiks(
      allPiks,
      allLoans,
      allEntities,
      allFacilities
    )
    if (piks !== pikServicing) {
      pikServicingHandler.setState(piks)
    }
  }, [allPiks, allFacilities, allLoans, allEntities])

  // Set principal servicing data
  useEffect(() => {
    if (!allPrincipals || !allFacilities || !allLoans || !allEntities) {
      return
    }
    const principals = fetchPrincipals(
      allPrincipals,
      allLoans,
      allEntities,
      allFacilities
    )
    if (principals !== principalServicing) {
      principalServicingHandler.setState(principals)
    }
  }, [allPrincipals, allFacilities, allLoans, allEntities])

  // Set new Borrowing servicing data
  useEffect(() => {
    if (!allFacilities || !allLoans || !allEntities) {
      return
    }
    const newBorrowings = fetchNewBorrowings(
      allLoans,
      allEntities,
      allFacilities
    )
    if (newBorrowings !== newBorrowingServicing) {
      newBorrowingServicingHandler.setState(newBorrowings)
    }
  }, [allFacilities, allLoans, allEntities])


  useEffect(() => {
    const result = [
      ...newBorrowingServicing,
      ...principalServicing,
      ...pikServicing,
    ].sort(
      (a, b) =>
        new Date(a.serviceTime).getTime() - new Date(b.serviceTime).getTime()
    )
    unscheduledTableHandler.setState(result)//switch this to setState when we don't need fake data
  }, [
    principalServicing,
    newBorrowingServicing,
    pikServicing,
  ])

  const unscheduledFiltered = unscheduledTable.filter(a => {
    const effectiveDate = new Date(a.effectiveDate);
    // Compare the year, month, and day of both dates
    return (
      date.getFullYear() === effectiveDate.getFullYear() &&
      date.getMonth() === effectiveDate.getMonth() &&
      date.getDate() === effectiveDate.getDate()
    );
  });

  const activityCount = unscheduledFiltered.length.toString()
  const percentageComplete = '25'
  const deadline = stringToDate(unscheduledFiltered[0]?.serviceTime.toString())?.toLocaleTimeString(
    [],
    { hour: '2-digit', minute: '2-digit' }
  ) ?? 'N/A'
  const totalAmount = formatNumberToCurrency(calcTotalAmount(unscheduledFiltered?.map(item => item?.amount ?? 0)) ?? 0, 'USD')?.toString() ?? 'N/A'

  return (
    <>
      <Stack w="100%">
        <MyWorkSummary activityCount={activityCount} percentageComplete={percentageComplete + '%'} deadlineApproaching={deadline === 'Invalid Date' ? 'N/A' : deadline} totalActivityAmount={totalAmount} />
        <TableColumnClickExclusion
          tableName='Unscheduled Table'
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          overflow="hidden"
          minHeight="0px"
          columnDefs={ServicingCols}
          data={unscheduledFiltered}
          setRow={(e: MyWorkDataParams, cellName: string) => {
            if (cellName != 'id') {
              console.log('row clicked', e)
            }
          }}
        />
      </Stack>
    </>
  )
}
