import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  submitPayment,
  getPayments,
  releasePayment,
  settlePayment,
  getReceivers,
  getSenders,
  getLockedPayments,
  changeFronting,
  lockPayment,
  changeReceivedPayments,
  rejectReceiver,
  queuePayment,
} from 'app/state/ducks/payments/actions'
import { PaymentParams, PaymentReceived, ReceiverParams } from 'app/models/payments-params'

export const loadAllQueuedPayments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/queued-payments`)
    .then(payments => {
      return payments.data.map((payment: any) => ({
        ...payment,
        status:'Queued',
      }))
    }).catch(e => {
      console.log(e)
      return []
    })
  // return await generatePaymentData(queuedPayments, "queued")
}


export const loadAllReleasedPayments = async () => {
  const apiServerUrl = config.backendUrl

  return await client
    .get(`${apiServerUrl}/api/released-payments`)
    .then(payments => {
      return payments.data.map((payment: any) => ({
        ...payment,
        status: 'Released',
      }))
    }).catch(e => {
      console.log(e)
      return []
    })
}
export const loadAllRejectedPayments = async () => {
  const apiServerUrl = config.backendUrl

  return await client
    .get(`${apiServerUrl}/api/rejected-payments`)
    .then(payments => {
      return payments.data.map((payment: any) => ({
        ...payment,
        status: 'Rejected',
      }))
    }).catch(e => {
      console.log(e)
      return []
    })
}

export const loadLockedPayments = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl

  return await client
    .get(`${apiServerUrl}/api/locked-payments`)
    .then(payments => {
      return dispatch (getLockedPayments(payments.data))
      })
    .catch(e => {
      console.log(e)
    })
}

export const loadActivePayments = () => async (dispatch: Dispatch) => {
  // Load all new Borrowing Payments = Get pending submit(new payments when loans created), pending release payments(when submitted payments),
  // released payments && settled payments

  let all_payments = {}

  const allQueuedPayments = await loadAllQueuedPayments()
  const allReleasedPayments = await loadAllReleasedPayments()
  const allRejectedPayments = await loadAllRejectedPayments()

  all_payments = allQueuedPayments
    .concat(allReleasedPayments).concat(allRejectedPayments)

  return dispatch(getPayments(all_payments))
}

export const loadPayments = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client.get(`${apiServerUrl}/api/staticdata/payment`).then(payments => {
    return dispatch(getPayments(payments.data))
  }).catch(e => {
    console.log(e)
    return []
  })
}

export const submitPayments = (payment: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  
  return client
    .post(
      `${apiServerUrl}/api/queued-payments/submit`,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin}}
    )
    .then(payment => {
      const payload = dispatch(submitPayment(payment.data))
      return {success: true, payload: payload.payload}
    }).catch(e => {
      console.log(e)
      return {success: false, payload: e.response.data.error}
    })
}

export const releasePayments = (payment: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(
      `${apiServerUrl}/api/queued-payments/release`,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin}}

    )
    .then(payment => {
      const payload = dispatch(releasePayment(payment.data))
      return {success: true, payload: payload.payload}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const settlePayments = (payment: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(
      `${apiServerUrl}/api/released-payments/settle`,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin}}

    )
    .then(payment => {
      const payload = dispatch(settlePayment(payment.data))
      return {success: true, payload: payload.payload}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const queuePayments = (payment: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(
      `${apiServerUrl}/api/rejected-payments/queue`,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin}}

    )
    .then(payment => {
      const payload = dispatch(queuePayment(payment.data))
      return {success: true, payload: payload.payload}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}


export const loadReceivers = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client.get(`${apiServerUrl}/api/payment-receivers/payment?id=${id}`)
    .then(response => {
    dispatch(getReceivers(response.data))
    return {success: true, payload:response.data}
  }).catch(e => {
    console.log(e)
    return {success: false, payload: e.response.data.error}
  })
}

export const loadSenders = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client.get(`${apiServerUrl}/api/payment-senders/payment?id=${id}`).then(senders => {
    return dispatch(getSenders(senders.data))
  }).catch(e => {
    console.log(e)
    return []
  })
}

export const changeFrontingOnPayment = (payment: any, status: boolean) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(
      `${apiServerUrl}/api/queued-payments/change-fronting`,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin},
    status: status}

    )
    .then(response => {
      payment.payment.fronting = status
      dispatch(changeFronting(payment))
      return {success: true, payload: response.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const rejectPayment = (receiver: ReceiverParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(
      `${apiServerUrl}/api/payment-receivers/reject`,
      {
        paymentId: receiver.paymentId,
        lenderId: receiver.lenderId,
      }

    )
    .then(response => {
      dispatch(rejectReceiver({rejected:response.data, editedId:receiver.paymentId}))
      return {success: true, payload: response.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}


export const cancelPayment = (payment: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  const url = payment.status === 'Queued'? `${apiServerUrl}/api/queued-payments/cancel` : `${apiServerUrl}/api/released-payments/cancel`
  return client
    .post(url,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin}}
    )
    .then(response => {
      dispatch(lockPayment(payment))
      return {success: true, payload: response.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const changeReceivedLenders = (payment: PaymentParams, paymentReceived:PaymentReceived[]) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(
      `${apiServerUrl}/api/payment-senders/payment-received`,
      {paymentId: {id: payment.id, admin: payment.payment.agencyAdmin},
      paymentReceived: paymentReceived}
    )
    .then(response => {
      dispatch(changeReceivedPayments({payment, paymentReceived}))
      return {success: true, payload: response.data}
    }).catch(e => {
      console.log(e, 'error')
      return {success: false, payload: e.response.data.error}
    })
}