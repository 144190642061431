import * as types from './types'

export const getIndexRateOptions = (payload: any) => ({
  type: types.GET_INDEX_RATE_OPTIONS,
  payload,
})

export const getIndexRateOption = (payload: any) => ({
  type: types.GET_INDEX_RATE_OPTION,
  payload,
})

export const addIndexRateOption = (payload: any) => ({
  type: types.ADD_INDEX_RATE_OPTIONS,
  payload,
})

export const editIndexRateOption = (payload: any) => ({
  type: types.EDIT_INDEX_RATE_OPTIONS,
  payload,
})

export const deleteIndexRateOptions = (payload: any) => ({
  type: types.DELETE_INDEX_RATE_OPTIONS,
  payload,
})

export const activeIndexRateOption = (payload: any) => ({
  type: types.ACTIVE_INDEX_RATE_OPTION,
  payload,
})

export const inactiveIndexRateOption = (payload: any) => ({
  type: types.INACTIVE_INDEX_RATE_OPTION,
  payload,
})
