import { useEffect, useState } from 'react'
import { EntityParams } from 'app/models/entity-params'
import { entityCols } from './entities-table-columnDefs'
import { Stack } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { useNavigate } from 'react-router-dom'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './entity-kpi-data'

export function ManageEntities() {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const entities: EntityParams[] = useSelector(getEntities)
  const [filteredEntities, setFilteredEntities] = useState<EntityParams[]>(useSelector(getEntities))

  const kpiData = getKPIData(entities, setFilteredEntities)

  useEffect(() => {
    dispatch(loadEntities())
  }, [])

  useEffect(() => {
    setFilteredEntities(entities)
  }, [entities])

  return (
    <Stack>
      <KPI_Section kpiData={kpiData} />
      <TableColumnClickExclusion
        tableName='Entities Table'
        csvExportEnabled={true}
        columnDefs={entityCols}
        data={filteredEntities.sort((a, b) => a.entityName.localeCompare(b.entityName)) ?? []}
        setRow={(e: any, cellName: string) => {
          if (cellName != 'id') {
            navigate('/entitymanagement/entity/' + e.id + '/' + e.status)
          }
        }}
      />
    </Stack>
  )
}
