import { WireInstructionParams } from 'app/models/wire-instruction-params'

export const getWireInstructions = (state: {
  wireInstructions: { wireInstructions: WireInstructionParams[] }
}) => {
  return state.wireInstructions.wireInstructions
}

export const getWireInstruction = (state: {
  wireInstructions: { wireInstruction: WireInstructionParams }
}) => {
  return state.wireInstructions.wireInstruction
}
