import {
  Accordion,
  Anchor,
  Breadcrumbs,
  Group,
  Stack,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { DealDetailsAccordion } from '../../deals/deal-details-accordion'
import { AttachmentsAccordion } from 'app/views/components/Accordion/attachments-accordion/attachments-accordion'
import { NotesAccordion } from 'app/views/components/Accordion/notes-accordion'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFacilities,
  getFacility,
} from 'app/state/ducks/facilities/selectors'
import {
  loadAllFacilities,
  setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import FacilityAccordion from './main-accordion'
import RelatedFacilitiesAccordion from './related-accordion'
import { loadAllLoans, loadLockedLoans } from 'app/state/ducks/loans/thunks'
import { loadAllDeals, loadOneDeal } from 'app/state/ducks/deals/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { DealParams } from 'app/models/deal-params'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import StatusPill from '../../common/pill-status'
import { ContractType } from 'app/models/common-types'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { loadAllLetterOfCredits } from 'app/state/ducks/letters-of-credits/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadAllLoanRollovers } from 'app/state/ducks/loan-rollovers/thunks'
import { loadAllLoanIncreases } from 'app/state/ducks/loan-increases/thunks'
import SegmentControl from 'app/views/components/segments/segment-control'
import { tabs, activeTabs, lockedTabs } from './single-facility-segment-data'
import { loadAllLoanConversions } from 'app/state/ducks/loan-conversions/thunks'
import FacilityHeader from './facility-header'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'

export default function FacilityContentPage(): JSX.Element {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { facilityId, status } = useParams()


  const [deal, setDeal] = useState<DealParams | undefined>(undefined)


  const facility = useSelector(getFacility)
  const deals = useSelector(getDeals)
  const currentDeal = useSelector(getDeal)

  const allFacilities: FacilityParams[] = useSelector(getFacilities)
  const facilityAmendments: FacilityParams[] = useSelector(
    getFacilityAmendments
  )

  useEffect(() => {
    if (!facilityId) {
      return
    }
    if (allFacilities) {
      const selectedFacility = allFacilities.find(
        facility => facility.id === facilityId
      )
      if (selectedFacility) {
        selectedFacility.amendment = false
        dispatch(setOneFacility(selectedFacility))
      }
    }
    if (facilityAmendments) {
      const amended_facility = facilityAmendments.find(
        amendment => amendment.id === facilityId
      )
      if (amended_facility) {
        amended_facility.amendment = true
        dispatch(setOneFacility(amended_facility))
      }
    }
  }, [facilityId, facilityAmendments, allFacilities])

  useEffect(() => {
    if (!facilityId) {
      return
    }
    dispatch(loadAllLoans(facilityId))
    dispatch(loadLockedLoans(facilityId))
    dispatch(loadAllLetterOfCredits(facilityId))
    dispatch(loadLendersPositionsByFacility(facilityId))
    dispatch(loadEntities())
    dispatch(loadAllFacilities())
    dispatch(loadWireInstructions())
    dispatch(loadAllDeals())
    dispatch(loadAllFacilityAmendments())
    dispatch(loadIndexRateOptions())
    dispatch(loadAllLoanRollovers())
    dispatch(loadAllLoanIncreases())
    dispatch(loadAllLoanConversions())
    dispatch(loadNoteByContract(facilityId, ContractType.Facilities))
  }, [facilityId])

  useEffect(() => {
    if (!facility || !deals) {
      return
    }
    const deal = deals.find(deal => deal.id === facility.dealId.id)
    if (deal) {
      setDeal(deal)
    } else {
      dispatch(loadOneDeal(facility.dealId.id, 'Terminated'))
    }
  }, [facility, deals])

  useEffect(() => {
    if (!currentDeal) {
      return
    }
    setDeal(currentDeal)
  }, [currentDeal])

  const items = [
    { title: 'Deal Management', href: '/dealmanagement' },
    { title: 'Facilities', href: '/dealmanagement/facilities' },
  ].map((item, index) => (
    <Anchor
      onClick={() => navigate(item.href)}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  return (
    <Stack>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      {facility ? <FacilityHeader facility={facility} /> : null}
      <Group h="100%" noWrap>
        <StatusPill
          state={facility?.status ?? 'Draft'}
          additionalState={facility?.amendmentType ?? ''}
        />
        <SegmentControl tabs={tabs} activeTabs={activeTabs} lockedTabs={lockedTabs} id={facilityId} status={status} />

      </Group>
      <Group noWrap position="apart" align="flex-start" h="100vh">
        <Stack className="data-header-part">
          <Stack w="100%">
            <Accordion
              defaultValue={['facility accordion', 'related Facilities']}
              multiple
              bg="#32429A"
              variant="contained"
              styles={{
                control: {
                  fontFamily: 'Plus Jakarta Sans',
                  '&[data-active]': {
                    backgroundColor: '#32429A',
                  },
                  '&:hover': {
                    backgroundColor: '#32429A',
                  },
                },
                chevron: {
                  color: 'white',
                },
              }}
            >
              <FacilityAccordion />
              <RelatedFacilitiesAccordion />
            </Accordion>
          </Stack>
        </Stack>

        <Stack className="accordions-header-part">
          <Stack h="100%" bg="#F9F8F8" w="100%" p="md">
            <Accordion
              multiple
              defaultValue={['DealDeatils', 'pdfs', 'notes']}
              variant="contained"
              bg="white"
              styles={{
                control: {
                  fontFamily: 'Plus Jakarta Sans',
                  '&[data-active]': {
                    backgroundColor: '#A4CAFE',
                  },
                },
              }}
            >
              <DealDetailsAccordion deal={deal} />
              <AttachmentsAccordion
                id={facilityId ?? ''}
                type={ContractType.Facilities}
              />
              <NotesAccordion
                contractId={facility?.id ?? ''}
                contractAdmin={facility?.accountManagementAdmin ?? ''}
                type={ContractType.Facilities}
              />
            </Accordion>
          </Stack>
        </Stack>
      </Group>
    </Stack>
  )
}
