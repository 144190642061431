import { FacilityParams } from 'app/models/facility-params'
import {
  submitForApproval,
  rejectSubmittedFacility,
  approveSubmittedFacility,
  deleteDraftFacility,
  loadAllFacilities,
} from 'app/state/ducks/facilities/thunks'
import { Dispatch } from 'redux'
import {
  approveSubmittedFacilityAmendment,
  deleteDraftFacilityAmendment,
  loadAllFacilityAmendments,
  rejectSubmittedFacilityAmendment,
  saveFacilityTermination,
  submitForApprovalAmendment,
} from 'app/state/ducks/facility-amendments/thunks'
import { TerminationParams } from 'app/models/termination-params'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'

export async function submitFacilityAmendment(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApprovalAmendment(facility))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Facility has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload || 'Submission Failed',
    })
    return false
  }
  dispatch(loadAllFacilityAmendments())
  return true
}

export async function rejectFacilityAmendment(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    rejectSubmittedFacilityAmendment(facility)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Facility has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload.error.message,
    })
    return false
  }
  dispatch(loadAllFacilityAmendments())
  return true
}

export async function approveFacilityAmendment(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    approveSubmittedFacilityAmendment(facility)
  )
  if (response.success) {
    await dispatch(loadAllFacilities())
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Facility has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload || 'Approval Failed',
    })
    return false
  }
  dispatch(loadAllFacilityAmendments())
  return true
}

export async function deleteFacilityAmendment(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(
    deleteDraftFacilityAmendment([facility.id])
  )
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
    return false
  }
  SuccessNotification({
    title: 'Successful Deletion',
    message: 'Facility has been Deleted',
  })

  dispatch(loadAllFacilityAmendments())
  return true
}

export async function submitFacility(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(submitForApproval(facility))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Submission',
      message: 'Facility has been Submitted for Approval',
    })
  } else {
    ErrorNotification({
      title: 'Submission Failed',
      message: response.payload || 'Submission Failed',
    })
    return false
  }
  return true
}

export async function rejectFacility(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(rejectSubmittedFacility(facility))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Rejection',
      message: 'Facility has been moved back to a Draft',
    })
  } else {
    ErrorNotification({
      title: 'Rejection Failed',
      message: response.payload || 'Rejection Failed',
    })
    return false
  }
  return true
}

export async function approveFacility(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(approveSubmittedFacility(facility))
  if (response.success) {
    SuccessNotification({
      title: 'Successful Approval',
      message: 'Facility has been Approved',
    })
  } else {
    ErrorNotification({
      title: 'Approval Failed',
      message: response.payload || 'Approval Failed',
    })
    return false
  }
  return true
}

export async function deleteFacility(
  facility: FacilityParams,
  dispatch: Dispatch<any>
) {
  const response: any = await dispatch(deleteDraftFacility([facility.id]))
  if (response.payload.error) {
    ErrorNotification({
      title: 'Delete Failed',
      message: response.payload.error.message,
    })
    return false
  }
  SuccessNotification({
    title: 'Successful Deletion',
    message: 'Facility has been Deleted',
  })

  return true
}

export async function createDraftTermination(
  terminationForm: TerminationParams,
  dispatch: Dispatch<any>,
  facility?: FacilityParams
) {
  const response: any = await dispatch(
    saveFacilityTermination(terminationForm, facility)
  )
  if (response.success) {
    SuccessNotification({
      title: 'Successful Termination request created',
      message: 'Draft termination has created',
    })
  } else {
    ErrorNotification({
      title: 'Termination request creation failed',
      message: response.payload,
    })
    return
  }
  dispatch(loadAllFacilityAmendments())
  return response.payload
}
