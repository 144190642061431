import { LendersPosition } from 'app/models/lenders-position-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'


export interface LendersPositionState {
  facilityLendersPositions: {data: LendersPosition[], isLoading: boolean}
  loanLendersPositions: {data: LendersPosition[], isLoading: boolean}
}

const initialState:LendersPositionState = { facilityLendersPositions: {data:[],isLoading: false}, loanLendersPositions: {data:[],isLoading: false} }

const lendersPositionsReducer = createReducer(initialState)({
  [types.GET_LENDERS_POSITIONS_BY_FACILITY]: (state: any, payload: any) => {
    return { ...state, facilityLendersPositions: {data: payload.payload, isLoading: false}}
  },
  [types.CHANGE_LENDERS_FOR_FACILITY]: (state: any, payload: any) => {
    return {
      ...state,
      facilityLendersPositions: {data:payload.payload,isLoading: false}
    }
  },
  [types.CHANGE_LENDERS_FOR_LOAN]: (state: any, payload: any) => {
    return {
      ...state,
      loanLendersPositions: {data:payload.payload,isLoading: false}
    }
  },
  [types.EDIT_LENDERS_POSITIONS]: (state: any, payload: any) => {
    const facilityIndex = state.facilityLendersPositions.data.findIndex(
      (lendersPosition: any) =>
        lendersPosition.lender.id === payload.payload.lender.id
    )
    if (facilityIndex !== -1) {
      const newArray = [...state.facilityLendersPositions.data]
      newArray[facilityIndex] = payload.payload
      return {
        ...state,
        facilityLendersPositions: {data: newArray, isLoading: false}
      }
    }

    const loanIndex = state.loanLendersPositions.data.findIndex(
      (lendersPosition: any) =>
        lendersPosition.lender.id === payload.payload.lender.id
    )
    if (loanIndex !== -1) {
      const newArray = [...state.loanLendersPositions]
      newArray[loanIndex] = payload.payload
      return {
        ...state,
        loanLendersPositions: {data:newArray, isLoading: false}
      }
    }
  },
  [types.RESET_FACILITY_LENDERS_POSITIONS]: (state: any) => {
    return { ...state, facilityLendersPositions: {data:[], isLoading: false} }
  },
  [types.GET_LENDERS_POSITIONS_BY_LOAN]: (state: any, payload: any) => {
    return { ...state, loanLendersPositions: {data:payload.payload, isLoading: false} }
  },
  [types.START_LOADING_FACILITY_LENDERS_POSITIONS]: (state: any) => {
    return { ...state, facilityLendersPositions: {...state.facilityLendersPositions, isLoading: true} }
  },
  [types.START_LOADING_LOAN_LENDERS_POSITIONS]: (state: any) => {
    return { ...state, loanLendersPositions: {...state.loanLendersPositions, isLoading: true} }
  },
  [types.STOP_LOADING_FACILITY_LENDERS_POSITIONS]: (state: any) => {
    return { ...state, facilityLendersPositions: {...state.facilityLendersPositions, isLoading: false} }
  },
  [types.STOP_LOADING_LOAN_LENDERS_POSITIONS]: (state: any) => {
    return { ...state, loanLendersPositions: {...state.loanLendersPositions, isLoading: false} }
  },
})

export default lendersPositionsReducer
