import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: never[] = []

const assignmentsReducer = createReducer(initialState)({
  [types.GET_ASSIGNMENTS]: (state: any, payload: any) => {
    return { ...state, assignments: payload.payload }
  },
  [types.GET_ASSIGNMENT]: (state: any, payload: any) => {
    return { ...state, assignment: payload.payload }
  },
  [types.ADD_ASSIGNMENTS]: (state: any, payload: any) => {
    return {
      ...state,
      assignments: [
        { ...payload.payload, status: 'Draft' },
        ...state.assignments,
      ],
    }
  },
  [types.EDIT_ASSIGNMENTS]: (state: any, payload: any) => {
    const index = state.assignments.findIndex(
      (assignment: { id: any }) => assignment.id === payload.payload.id
    )
    const newArray = [...state.assignments]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    return {
      ...state,
      assignments: newArray,
    }
  },
})

export default assignmentsReducer
