import { MyWorkDataParams } from 'app/models/mywork-data-params'
import { Dispatch } from 'redux'

export async function submitServicing(
  servicing: MyWorkDataParams,
  dispatch: Dispatch
) {
  console.log('servicing', servicing)
  console.log('dispatch', dispatch)
  return
}

export async function rejectServicing(
  servicing: MyWorkDataParams,
  dispatch: Dispatch
) {
  console.log('servicing', servicing)
  console.log('dispatch', dispatch)
  return
}

export async function approveServicing(
  servicing: MyWorkDataParams,
  dispatch: Dispatch
) {
  console.log('servicing', servicing)
  console.log('dispatch', dispatch)
  return
}

export async function deleteServicing(
  servicing: MyWorkDataParams,
  dispatch: Dispatch
) {
  console.log('servicing', servicing)
  console.log('dispatch', dispatch)
  return
}
