import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { columnDefs } from '../entities/single-entity-page/entity-position-columnDefs'
import { Stack } from '@mantine/core'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { positionsForLender } from 'app/state/ducks/lenders-positions/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { EntityParams } from 'app/models/entity-params'
import { useListState } from '@mantine/hooks'
import { FacilityParams } from 'app/models/facility-params'
import { LendersPosition } from 'app/models/lenders-position-params'
import { DealParams } from 'app/models/deal-params'
import { formatNumberToCurrency } from 'app/utils/util-functions'

interface Props {
  entity: EntityParams
}

type EntityPositions = {
  lenderAllocation: number;
  type: string;
  facilityId: string;
}

export default function ManageEntityDeals({ entity }: Props) {
  const dispatch = useDispatch()
  const [lenderPositions, lenderPositionsHandler] = useListState<EntityPositions>([])
  const [borrowerPositions, borrowerPositionsHandler] = useListState<EntityPositions>([])
  const [entityPositions, entityPositionsHandler] = useListState<EntityPositions>([])

  const facilities = useSelector(getFacilities)
  const deals = useSelector(getDeals)

  const getLenderPositions = async (entityId: string) => {
    const lendersPositions = await positionsForLender(entityId)
    if (lendersPositions) {
      const entityPositions = lendersPositions.map((lenderPosition: LendersPosition) => {
        if (!lenderPosition || !lenderPosition.proRatas || lenderPosition.proRatas.length === 0) {
          return null
        }
        return {
          lenderAllocation: Number(lenderPosition.proRatas[lenderPosition.proRatas?.length - 1]?.amount ?? '0'),
          type: 'lender',
          facilityId: lenderPosition.contractId?.id ?? '',
        }
      })
      lenderPositionsHandler.setState(entityPositions)
    }
  }

  const getBorrowerPositions = async (entityId: string, facilities: FacilityParams[]) => {
    const entityPositions = facilities
      .filter((facility) => facility.borrowers.some(borrower => borrower.id === entityId))
      .map((facility: FacilityParams) => {
        return {
          lenderAllocation: Number(facility.amount ?? '0'),
          type: 'borrower',
          facilityId: facility.id,
        }
      })
    borrowerPositionsHandler.setState(entityPositions)
  }

  const isEntityBorrower = (entity: EntityParams) => {
    return entity.entityProfile.some((profile: any) => {
      return ['BorrowerLending', 'BorrowerTrading'].includes(profile)
    })
  }

  const isEntityLender = (entity: EntityParams) => {
    return entity.entityProfile.some((profile: any) => {
      return ['LenderAgency', 'LenderCounterParty', 'LenderCounterSub', 'LenderCreditBenficiary'].includes(profile)
    })
  }

  useEffect(() => {
    dispatch(loadAllFacilities())
    dispatch(loadAllDeals())
    dispatch(loadWireInstructions())
  }, [])

  useEffect(() => {
    if (entity && entity.id && isEntityLender(entity)) {
      getLenderPositions(entity.id ?? '')
    }
  }, [entity])

  useEffect(() => {
    if (facilities && facilities.length > 0 && entity && entity.id && isEntityBorrower(entity)) {
      getBorrowerPositions(entity.id ?? '', facilities)
    }

  }, [facilities, entity])

  useEffect(() => {
    entityPositionsHandler.setState([...lenderPositions, ...borrowerPositions])
  }, [lenderPositions, borrowerPositions])

  const cleanData = (entityPositions: EntityPositions[], deals: DealParams[], facilities: FacilityParams[]) => {
    return entityPositions.map((entityPosition: EntityPositions) => {
      const facility = facilities.find(facility => facility.id === entityPosition.facilityId)

      const deal = deals.find(deal => deal.id === facility?.dealId.id)
      return {
        id: entityPosition.facilityId ?? '',
        deal: deal?.dealName ?? '',
        facility: facility?.name ?? '',
        amount: formatNumberToCurrency(entityPosition.lenderAllocation, facility?.baseCurrency ?? 'USD'),
        type: entityPosition.type,
      }
    })
  }

  if (!entity?.id) {
    return <></>
  }

  return (
    <Stack w="100%">
      <TableColumnClickExclusion
        tableName='Entity_Positions Table'
        data={cleanData(entityPositions, deals, facilities)}
        columnDefs={columnDefs}
        setRow={(e: any, cellName: string) => {
          if (cellName != 'id') {
            console.log('row', e)
          }
        }}
      />

    </Stack>
  )
}