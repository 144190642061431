import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getDeals,
  addDeal,
  editDeal,
  getDeal,
  submitDeal,
  rejectDeal,
  approveDeal,
  deleteDeal,
  getDealHistory,
  getLockedDeals,
} from 'app/state/ducks/deals/actions'
import { DraftDealParams, DealParams } from 'app/models/deal-params'

export const saveDeal =
  (savedDeal: DraftDealParams, deal?: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    savedDeal.fees.map((fee: any) => {
      if (fee.dayBasis === '') fee.dayBasis = null
    })
    const updated_savedDeal: DraftDealParams = {
      ...savedDeal,
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    if (deal) {
      return client
        .put(`${apiServerUrl}/api/draft-deals`, updated_savedDeal)
        .then(deal => {
          dispatch(editDeal(deal.data))
          return { success: true, payload: deal.data }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/draft-deals`, updated_savedDeal)
        .then(deal => {
          dispatch(addDeal(deal.data))
          return { success: true, payload: deal.data }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
  }

const loadApprovedDeals = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/approved-deals/`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadDraftDeals = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/draft-deals`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadSubmittedDeals = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/submitted-deals`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllDeals = () => async (dispatch: Dispatch) => {
  const draftDeals = await loadDraftDeals()
  const submittedDeals = await loadSubmittedDeals()
  const approvedDeals = await loadApprovedDeals()

  const subDeals = submittedDeals.map((a: { deal: any }) => ({
    ...a.deal,
    status: 'Submitted',
  }))

  const allDeals = draftDeals
    .map((deal: any) => ({ ...deal, status: 'Draft' }))
    .concat(subDeals)
    .concat(approvedDeals.map((deal: any) => ({ ...deal, status: 'Approved' })))

  return dispatch(getDeals(allDeals))
}

export const loadOneDeal =
  (dealId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower =
      status.toLowerCase() == 'terminated' ? 'locked' : status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-deals/one?id=${dealId}`)
      .then(deals => {
        if (statusLower === 'submitted' || statusLower === 'locked') {
          return dispatch(getDeal({ ...deals.data.deal, status: status }))
        }
        return dispatch(getDeal({ ...deals.data, status: status }))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const setOneDeal = (deal: any) => async (dispatch: Dispatch) => {
  dispatch(getDeal({ ...deal }))
}

export const submitForApproval =
  (deal: DealParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-deals/submit`, { id: deal.id })
      .then(response => {
        dispatch(submitDeal(response.data))
        return { success: true, data: response.data, deal: deal, payload: deal }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSubmittedDeal =
  (deal: DealParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-deals/reject`, { id: deal.id })
      .then(response => {
        dispatch(rejectDeal(response.data))
        return { success: true, data: response.data, deal: deal }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSubmittedDeal =
  (deal: DealParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-deals/approve`, { id: deal.id })
      .then(response => {
        dispatch(approveDeal(response.data))
        return { success: true, data: response.data, deal: deal, payload: deal }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteDraftDeal =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/draft-deals?ids=${ids}`)
      .then(response => {
        return dispatch(deleteDeal(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }

export const loadDealHistory =
  (dealId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/approved-deals/history?id=${dealId}`)
      .then(response => {
        return dispatch(getDealHistory(response.data))
      })
      .catch(error => {
        console.log('THUNK ERROR: ', error)
      })
  }

export const loadLockedDeals = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/locked-deals`)
    .then(response => {
      const lockedDeals = response.data.map(
        (a: { deal: any; locked: string }) => ({
          ...a.deal,
          status: 'Terminated',
          terminationDate: a.locked,
        }),
      )

      return dispatch(getLockedDeals(lockedDeals))
    })
    .catch(error => {
      console.log('THUNK ERROR: ', error)
    })
}
