import { IndexRateOptionParams } from 'app/models/index-rate-options-params'

export const getIndexRateOptions = (state: {
  indexRateOptions: { indexRateOptions: IndexRateOptionParams[] }
}) => {
  return state.indexRateOptions.indexRateOptions
}

export const getIndexRateOption = (state: {
  indexRateOptions: { indexRateOption: IndexRateOptionParams }
}) => {
  return state.indexRateOptions.indexRateOption
}
