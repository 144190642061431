import VerticalStepper from 'app/views/components/stepper-component'
import UpdatedDealsModal, {
  ChildDealRef,
} from 'app/views/pages/deals-management/updated-deal-form'
import UpdatedFacilitiesModal, {
  ChildFacilityRef,
} from 'app/views/pages/deals-management/updated-facilities-form'
import { useEffect, useRef, useState } from 'react'
import AmortizationSchedule, {
  ChildAmortizationScheduleRef,
} from '../amortization-scheduler/amortization-schedule'
import ManageFees, { ChildManageFeesRef } from '../Fees/fees'
import ManageLenders, { ChildManageLendersRef } from '../lenders/manage-lenders'
import ManageIro, {
  ChildManageIroRef,
} from '../interest-rate-options/manage-iro'
import { DealParams } from 'app/models/deal-params'
import { DraftFacilityParams } from 'app/models/facility-params'
import PrimaryButton from '../buttons/PrimaryButton'
import Sublimits, { ChildSublimitsRef } from '../sublimits/sublimits'
import DealManageFees, { ChildDealManageFeesRef } from '../Fees/dealFees'
import ManagePikScheduler, { ChildPikSchedulerRef } from '../pik-scheduler/manage-pik-scheduler'
import DealPricingGridComponent, { ChildDealManagePricingGridRef } from 'app/views/components/deal-pricing-grid/deal-pricing-grid'
import ManageFacilityPricingGrid, { ChildManageFacilityPricingGridRef } from '../facility-pricing-grid/manage-facility-pricing-grid'
import { useDispatch } from 'react-redux'
import { resetFacilityLendersPositions } from 'app/state/ducks/lenders-positions/actions'
import { resetFacilityFees } from 'app/state/ducks/fees/actions'
import { resetFacilitySchedulerAmortization } from 'app/state/ducks/amortization/actions'


interface NewComponentProps {
  onClose: () => void
}

const CreateNewComponent: React.FC<NewComponentProps> = ({ onClose }) => {
  interface CustomStepContentProps {
    onNext: () => void
    onPrevious: () => void
  }

  const [disabledSteps, setDisabledSteps] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  const [deal, setDeal] = useState<DealParams | undefined>(undefined)
  const [facility, setFacility] = useState<DraftFacilityParams | undefined>(
    undefined
  )



  const showPikScheduler = (f: any) => {
    return f?.pikOption === 'PIK_Scheduled'
  }

  const showAmortizationSchedule = (facility: DraftFacilityParams | undefined) => {
    if (
      facility?.facilityType === 'TL' ||
      facility?.facilityType === 'TLA' ||
      facility?.facilityType === 'TLB' ||
      facility?.facilityType === 'TLC' ||
      facility?.facilityType === 'DDTL'
    ) {
      return true
    } else {
      return false
    }
  }

  const DealContent: React.FC<CustomStepContentProps> = ({ onNext }) => {
    const childRef = useRef<ChildDealRef>(null)
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
      }
    }

    const handleSaveDealAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveDealAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    return (
      <div className="create-new">
        <UpdatedDealsModal
          deal={deal}
          setAmendment={setDeal}
          ref={childRef}
          setSaveButtonDisabled={setIsSaveButtonDisabled}
          setDisabledSteps={setDisabledSteps}
          setDeal={setDeal}
        />{' '}
        <div
          className="btn-group"
          style={{ marginTop: 15, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveDealAndExit()}
            className="form-button"
            w="33%"
            disabled={isSaveButtonDisabled}
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveDealAndProceed()}
            className="form-button"
            w="33%"
            disabled={isSaveButtonDisabled}
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="33%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const DealFeesContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildDealManageFeesRef>(null)
    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
        if (!deal?.isPricingGrid) {
          onNext()
        }
      }
    }

    const handleSaveDealAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveDealAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <DealManageFees
          deal={deal}
          setDeal={setDeal}
          setAmendment={setDeal}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveDealAndExit()}
            className="form-button"
            w="25%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveDealAndProceed()}
            className="form-button"
            w="25%"
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="25%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="25%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const DealPricingGridContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildDealManagePricingGridRef>(null)
    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
      }
    }

    const handleSaveDealAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveDealAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <DealPricingGridComponent
          deal={deal}
          setDeal={setDeal}
          setAmendment={setDeal}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveDealAndExit()}
            className="form-button"
            w="25%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveDealAndProceed()}
            className="form-button"
            w="25%"
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="25%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="25%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const FacilityContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildFacilityRef>(null)
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
    const dispatch = useDispatch()

    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    useEffect(() => {
      if (!facility) {
        dispatch(resetFacilityLendersPositions())
        dispatch(resetFacilityFees())
        dispatch(resetFacilitySchedulerAmortization())

      }
    }, [facility])

    return (
      <div className="create-new">
        <UpdatedFacilitiesModal
          deal={deal}
          facility={facility}
          ref={childRef}
          setSaveButtonDisabled={setIsSaveButtonDisabled}
          setDisabledSteps={setDisabledSteps}
          setFacility={setFacility}
          dealStepper={true}
        />
        <div
          className="btn-group"
          style={{ marginTop: 50, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w="25%"
            disabled={isSaveButtonDisabled}
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveFacilityAndProceed()}
            className="form-button"
            w="25%"
            disabled={isSaveButtonDisabled}
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="25%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="25%"
          >
            Exit
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const LenderAllocationContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildManageLendersRef>(null)

    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
        if (facility?.isSublimit === false) {
          onNext()
        }
      }
    }

    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <ManageLenders
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w="25%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveFacilityAndProceed()}
            className="form-button"
            w="25%"
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="25%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="25%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const SublimitsContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildSublimitsRef>(null)

    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
      }
    }

    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }
    return (
      <div className="create-new">
        <Sublimits
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w="25%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveFacilityAndProceed()}
            className="form-button"
            w="25%"
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="25%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="25%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const IroContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildManageIroRef>(null)

    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
      }
    }

    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
        if (facility?.isSublimit === false) {
          onPrevious()
        }
      }
    }

    return (
      <div className="create-new">
        <ManageIro
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w="25%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveFacilityAndProceed()}
            className="form-button"
            w="25%"
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="25%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="25%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const FeesContent: React.FC<CustomStepContentProps> = ({
    onNext,
    onPrevious,
  }) => {
    const childRef = useRef<ChildManageFeesRef>(null)

    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onNext()
        if (!deal?.isPricingGrid) {
          onNext()
          if (!showAmortizationSchedule(facility)) {
            onNext()
          }
        }
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <ManageFees
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w={showAmortizationSchedule(facility) || showPikScheduler(facility) || deal?.isPricingGrid ? '25%' : '33%'}
          >
            Save and Exit
          </PrimaryButton>
          {showAmortizationSchedule(facility) || showPikScheduler(facility) || deal?.isPricingGrid ? (
            <PrimaryButton
              onClick={() => handleSaveFacilityAndProceed()}
              className="form-button"
              w="25%"
            >
              Save and Proceed
            </PrimaryButton>
          ) : null}
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w={showAmortizationSchedule(facility) || showPikScheduler(facility) || deal?.isPricingGrid ? '25%' : '33%'}
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w={showAmortizationSchedule(facility) || showPikScheduler(facility) || deal?.isPricingGrid ? '25%' : '33%'}
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const FacilityPricingGrid: React.FC<CustomStepContentProps> = ({
    onPrevious,
    onNext,
  }) => {
    const childRef = useRef<ChildManageFacilityPricingGridRef>(null)

    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
        if (!showAmortizationSchedule(facility)) {
          onNext()
        }
      }
    }


    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }


    const handlePrevious = () => {
      if (typeof onPrevious === 'function') {
        onPrevious()
      }
    }

    return (
      <div className="create-new">
        <ManageFacilityPricingGrid
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w="33%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleSaveFacilityAndProceed()}
            className="form-button"
            w="25%"
          >
            Save and Proceed
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="33%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="33%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const AmortizationScheduleContent: React.FC<CustomStepContentProps> = ({
    onPrevious,
    onNext,
  }) => {
    const childRef = useRef<ChildAmortizationScheduleRef>(null)
    const handleNext = () => {
      if (typeof onNext === 'function') {
        onNext()
      }
    }
    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handleSaveFacilityAndProceed = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        handleNext()
      }
    }

    const handlePrevious = () => {
      if (typeof onNext === 'function') {
        onPrevious()
        if (!deal?.isPricingGrid) {
          onPrevious()
        }
      }
    }


    return (
      <div className="create-new">
        <AmortizationSchedule
          deal={deal}
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w={showPikScheduler(facility) ? "25%" : "33%"}
          >
            Save and Exit
          </PrimaryButton>
          {showPikScheduler(facility) ? (
            <PrimaryButton
              onClick={() => handleSaveFacilityAndProceed()}
              className="form-button"
              w="25%"
            >
              Save and Proceed
            </PrimaryButton>
          ) : null}
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w={showPikScheduler(facility) ? "25%" : "33%"}
          >
            Previous
          </PrimaryButton>

          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w={showPikScheduler(facility) ? "25%" : "33%"}
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const PikContent: React.FC<CustomStepContentProps> = ({
    onPrevious,
  }) => {
    const childRef = useRef<ChildPikSchedulerRef>(null)


    const handleSaveFacilityAndExit = async () => {
      if (childRef.current) {
        await childRef.current.handleClick()
        onClose()
      }
    }

    const handlePrevious = () => {
      if (typeof onPrevious === 'function') {
        onPrevious()
        if (!showAmortizationSchedule(facility)) {
          onPrevious()
          if (!deal?.isPricingGrid) {
            onPrevious()
          }
        }
      }
    }

    return (
      <div className="create-new">
        <ManagePikScheduler
          facility={facility}
          setFacility={setFacility}
          ref={childRef}
        />
        <div
          className="btn-group"
          style={{ marginTop: 10, display: 'flex', gap: '10px' }}
        >
          <PrimaryButton
            onClick={() => handleSaveFacilityAndExit()}
            className="form-button"
            w="33%"
          >
            Save and Exit
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handlePrevious()}
            className="form-button"
            w="33%"
          >
            Previous
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onClose()}
            className="form-button"
            w="33%"
          >
            Close
          </PrimaryButton>
        </div>
      </div>
    )
  }

  const steps = [
    {
      label: '1. Deal',
      content: (
        <DealContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'i. Fees',
      content: (
        <DealFeesContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'ii. Pricing Grid',
      content: (
        <DealPricingGridContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: '2. Facility',
      content: (
        <FacilityContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'i. Lender Allocation',
      content: (
        <LenderAllocationContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'ii. Sublimits',
      content: (
        <SublimitsContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'iii. Interest Rate Options',
      content: (
        <IroContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'iv. Fees',
      content: (
        <FeesContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'v. Pricing Grid',
      content: (
        <FacilityPricingGrid
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'vi. Amortization Schedule',
      content: (
        <AmortizationScheduleContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
    {
      label: 'vii. PIK Scheduler',
      content: (
        <PikContent
          onNext={function (): void {
            throw new Error('Function not implemented.')
          }}
          onPrevious={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      ),
    },
  ]
  return (
    <div className="create-new-form">
      <VerticalStepper steps={steps} disabledSteps={disabledSteps} />
    </div>
  )
}

export default CreateNewComponent
