import { Group, SegmentedControl, Stack, SegmentedControlItem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

type TabItem = {
  route: string;
  label: string;
};

type Props = {
  tabs: {
    todo?: TabItem;
    payments?: TabItem;
    unscheduled?: TabItem;
    scheduled?: TabItem;
    entityBase?: TabItem;
    entityContacts?: TabItem;
    entityWI?: TabItem;
    entityPositions?: TabItem;
  };
  lockedTabs: {
    active?: string;
    terminated?: string;
  };
  activeTabs: {
    tab: { route: string, label: string }; // Updated to expect a string since now we are dealing with routes
    locked?: string;
  };
  id?: string;
  status?: string;
  contractType?: string;
};

export default function SegmentControlUpdated({ tabs, lockedTabs, activeTabs }: Props) {
  const navigate = useNavigate();

  // Prepare the data for the SegmentedControl
  const tabData: SegmentedControlItem[] = Object.entries(tabs).map(([, value]) => ({
    value: value.route,
    label: value.label,
  }));

  const lockedTabsData: SegmentedControlItem[] = Object.entries(lockedTabs).map(([key, value]) => ({
    value: value ?? '',
    label: key.charAt(0).toUpperCase() + key.slice(1),
  }));

  // Determine the currently active tab URL
  const activeTabRoute = activeTabs.tab.route;

  function changeTab(e: string): void {
    navigate(e + (activeTabs.locked || ''));
  }

  function changeLockedTab(e: string): void {
    navigate(activeTabs.tab + e);
  }

  return (
    <Stack align="stretch" w="100%">
      <Group noWrap w="100%">
        <SegmentedControl
          size="lg"
          onChange={changeTab}
          value={activeTabRoute}
          data={tabData}
          classNames={{
            root: 'segmented-root',
            label: 'segmented-label',
            indicator: 'segmented-indicator',
            controlActive: 'segmented-control-active',
            control: 'segmented-control',
          }}
        />

        {/* These styles probably have to be updated
            We may want to separact active/deactive segment control too */}
        <SegmentedControl
          className='segmented-control-updated'
          size="lg"
          onChange={changeLockedTab}
          value={activeTabs.locked}
          data={lockedTabsData}
          classNames={{
            root: 'segmented-root',
            label: 'segmented-label',
            indicator: 'segmented-indicator',
            controlActive: 'segmented-control-active',
            control: 'segmented-control',
          }}
        />
      </Group>
    </Stack>
  );
}