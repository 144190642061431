import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Badge, Tooltip, Avatar } from '@mantine/core'
import { ReactNode } from 'react'
import ServicingActions from './servicing-actions'
import getInitials from 'app/views/components/functions/getInitials'
import { ServicingParams, ServicingTypes } from 'app/models/servicing-params'
import LoanRolloverActions from '../deals-management/rollovers/rollover-actions'
import LoanIncreaseActions from '../deals-management/increases/increase-actions'
import LoanActions from '../deals-management/loans/loan-actions'
import LoanRateSettingActions from '../deals-management/rate-settings/rate-setting-actions'
import LoanPrincipalPaymentActions from '../deals-management/principal-payment/principal-payment-actions'
import AddRateSettingActions from '../deals-management/rate-settings/add-rate-setting-actions'
import { FacilityAmortizationServicingActions } from './facility-amortization-servicing-actions'
import PIKActions from '../deals-management/payment-in-kind/pik-actions'
import { FeeFacilityServicingActions } from './fee-facility-servicing-actions'
import FeePaymentPaymentActions from './fee-payment-actions'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import LoanConversionActions from '../deals-management/conversion/conversion-actions'
import FacilityActions from '../deals-management/facilities-page/facility-actions'
import DealActions from '../deals-management/deals/deal-actions'

export enum pillState {
  'PendingReview' = 'Pending Review',
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'PendingSubmit' = 'Pending Submit',
  'PendingApproval' = 'Pending Approval',
  'Approved' = 'Approved',
}

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function getActionFromType(type: ServicingTypes, data: any) {
  switch (type) {
    case ServicingTypes.loanRollover:
      return <LoanRolloverActions row={data} />
    case ServicingTypes.loanIncrease:
      return <LoanIncreaseActions row={data} />
    case ServicingTypes.loanConversion:
      return <LoanConversionActions row={data} />
    case ServicingTypes.loanRateSetting:
      return <LoanRateSettingActions row={data} />
    case ServicingTypes.loanAddRates:
      return <AddRateSettingActions row={data} />
    case ServicingTypes.maturingLoan:
    case ServicingTypes.newBorrowing:
      return <LoanActions item={data} fromOutside={false} />
    case ServicingTypes.principalPayment:
      return <LoanPrincipalPaymentActions row={data} />
    case ServicingTypes.amortizationSchedule:
      return <FacilityAmortizationServicingActions row={data} />
    case ServicingTypes.fee:
      return <FeeFacilityServicingActions row={data} />
    case ServicingTypes.feePayment:
      return <FeePaymentPaymentActions row={data} />
    case ServicingTypes.pik:
      return <PIKActions row={data} />
    case ServicingTypes.maturingFacility:
      return <FacilityActions row={data} />
    case ServicingTypes.maturingDeal:
      return <DealActions row={data} />
    case ServicingTypes.base:
      return <ServicingActions row={data} />
  }
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export const ServicingCols: MRT_ColumnDef<ServicingParams>[] = [
  {
    size: 80,
    accessorKey: 'effectiveDate',
    header: 'Event Date',
    Header: <TableHead header={'Event Date'} />,
    accessorFn: row => {
      ;
      const effectiveDate = row.effectiveDate instanceof Date ? row.effectiveDate : stringToDate(row.effectiveDate);
      return effectiveDate;
    },
    mantineFilterTextInputProps: {
      placeholder: 'Date',
    },
    filterFn: 'lessThanOrEqualTo',
    filterVariant: 'date',
    sortingFn: 'datetime',
    Cell: ({ cell }) => (
      <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
    ), //render Date as a string

  },
  {
    size: 100,
    accessorKey: 'facilityName',
    header: 'Facility Name',
    Header: <TableHead header={'Facility Name'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 80,
    accessorKey: 'stage',
    header: 'Stage',
    Header: <TableHead header={'Stage'} />,
    Cell: ({ cell }) => {
      const state = cell.getValue<string>()
      let styles = {}
      if (state === pillState.PendingReview || state === pillState.Draft) {
        styles = {
          backgroundColor: '#FF5A1F',
        }
      }

      if (state === pillState.PendingSubmit || state === pillState.Submitted) {
        styles = {
          backgroundColor: '#E8006E',
        }
      }
      if (state === pillState.PendingApproval) {
        styles = {
          backgroundColor: '#7E3AF2',
        }
      }
      if (state === pillState.Approved) {
        styles = {
          backgroundColor: '#32439A',
        }
      }

      return (
        <Badge
          styles={{
            root: {
              fontFamily: 'Plus Jakarta Sans',
              color: 'white',
              borderRadius: '4px',
              padding: '1.5em',
              ...styles,
            },
          }}
        >
          <TableRowText text={cell.getValue<string>()} />
        </Badge>
      )
    },
  },
  {
    size: 50,
    accessorKey: 'transaction',
    header: 'Transaction',
    Header: <TableHead header={'Transaction'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 50,
    accessorKey: 'status',
    header: 'Status',
    Header: <TableHead header={'Status'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 100,
    accessorKey: 'amount',
    header: 'Amount',
    Header: <TableHead header={'Amount'} />,
    filterVariant: 'range',
    Cell: ({ cell }) => {
      if (cell.row.original.original.paymentType === 'InterestPayment') {
        return (
          <TableRowText
            text={formatNumberToCurrency(cell.row.original.original.interestAmount, cell.row.original.currency ? cell.row.original.currency : 'USD')}
          />
        )
      } else {
        return (
          <TableRowText
            text={formatNumberToCurrency(cell.row.original.amount, cell.row.original.currency ? cell.row.original.currency : 'USD')}
          />
        )
      }

    },
  },
  {
    size: 50,
    accessorKey: 'currency',
    header: 'Currency',
    Header: <TableHead header={'Currency'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 70,
    accessorFn: row => new Date(row.serviceTime), //convert to Date for sorting and filtering
    accessorKey: 'serviceTime',
    header: 'Deadline',
    Header: <TableHead header={'Deadline'} />,
    filterFn: 'lessThanOrEqualTo',
    filterVariant: 'date',
    sortingFn: 'datetime',
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }) ?? 'N/A'
      }</Text>
    ), //render Date as a string

  },
  {
    size: 70,
    accessorKey: 'notices',
    header: 'Notices',
    Header: <TableHead header={'Notices'} />,
    Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
  },
  {
    size: 50,
    accessorKey: 'admin',
    header: 'Admin',
    Header: <TableHead header={'Admin'} />,
    Cell: ({ cell }) => {
      return (
        <Tooltip label={cell.getValue<string>()}>
          <Avatar radius="xl" color="cyan" size="lg">
            {getInitials(cell.getValue<string>() as string)}
          </Avatar>
        </Tooltip>
      )
    },
  },
  {
    size: 40,
    accessorKey: 'id',
    header: 'Actions',
    Header: <TableHead header={'Actions'} />,
    enableColumnActions: false,
    enableColumnFilter: false,
    Cell: cell =>
      getActionFromType(cell.row.original.type, cell.row.original.original),
  },
]