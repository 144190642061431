import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: { indexRateOptions: any[]; indexRateOption: any } = {
  indexRateOptions: [],
  indexRateOption: null,
}

const indexRateOptionsReducer = createReducer(initialState)({
  [types.GET_INDEX_RATE_OPTIONS]: (state: any, payload: any) => {
    return { ...state, indexRateOptions: payload.payload }
  },
  [types.GET_INDEX_RATE_OPTION]: (state: any, payload: any) => {
    return { ...state, indexRateOption: payload.payload }
  },
  [types.ADD_INDEX_RATE_OPTIONS]: (state: any, payload: any) => {
    return {
      ...state,
      indexRateOptions: [payload.payload.data, ...state.indexRateOptions],
    }
  },
  [types.EDIT_INDEX_RATE_OPTIONS]: (state: any, payload: any) => {
    const index = state.indexRateOptions.findIndex(
      (indexRateOption: { id: any }) =>
        indexRateOption.id === payload.payload.data.id
    )
    const newArray = [...state.indexRateOptions]
    newArray[index] = { ...payload.payload.data, status: 'Inactive' }
    return {
      ...state,
      indexRateOptions: newArray,
    }
  },
  [types.ACTIVE_INDEX_RATE_OPTION]: (state: any, payload: any) => {
    const index = state.indexRateOptions.findIndex(
      (indexRateOption: { id: any }) =>
        indexRateOption.id === payload.payload.id
    )
    const newArray = [...state.indexRateOptions]
    newArray[index] = { ...newArray[index], status: 'Active' }
    if (payload.payload.id === state.indexRateOption?.id) {
      return {
        ...state,
        indexRateOption: { ...state.indexRateOption, status: 'Active' },
        indexRateOptions: newArray,
      }
    }
    return {
      ...state,
      indexRateOptions: newArray,
    }
  },
  [types.INACTIVE_INDEX_RATE_OPTION]: (state: any, payload: any) => {
    const index = state.indexRateOptions.findIndex(
      (indexRateOption: { id: any }) =>
        indexRateOption.id === payload.payload.id
    )
    const newArray = [...state.indexRateOptions]
    newArray[index] = { ...newArray[index], status: 'Inactive' }
    if (payload.payload.id === state.indexRateOption?.id) {
      return {
        ...state,
        indexRateOption: { ...state.indexRateOption, status: 'Inactive' },
        indexRateOptions: newArray,
      }
    }
    return {
      ...state,
      indexRateOptions: newArray,
    }
  },
  [types.DELETE_INDEX_RATE_OPTIONS]: (state: any, payload: any) => {
    const filteredInterestRateOptions = state.indexRateOptions.filter(
      (indexRateOption: any) => {
        return indexRateOption.id !== payload.payload[0].id
      }
    )

    return {
      ...state,
      indexRateOptions: filteredInterestRateOptions,
    }
  },
})

export default indexRateOptionsReducer
